import React, { useState, useGlobal } from 'reactn';
import "./PriceCardModal.css";
import dragArrows from "../../assets/img/DragArrows.png";
import descriptionPlaceholder1 from "../../assets/img/descriptionplaceholder1.png";
import descriptionPlaceholder2 from "../../assets/img/descriptionplaceholder2.png";
import descriptionPlaceholder3 from "../../assets/img/descriptionplaceholder3.png";
import freePlanLogo from "../../assets/img/freeplanlogo.png";
import { format, addMonths } from "date-fns";
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';

const PriceCardModal = (props) => {
  const { 
    id,
    DropdownMenu,
    type,
    inactive,
    plan_category,
    billing_cycle,
    timing,
    firstDay,
    lastDay,
    day_slot,
    description,
    days_per_week,
    separateForChildren,
    autoEmail,
    trialPeriod,
    target,
    enrollments,
    applications,
    dateSelection,
    plan_price,
    deposit,
    taxInfo,
    installments,
    installments_breakdown,
    enableInstallments,
    isProrated,
    enableProration,
    plan_cap,
    addOn,
    addOnItems,
    frequency,
    quantity,
    onEditButtonClick,
    enableSpecificDates,
    linkedSemesterPlan,
    planPremium
  } = props;

  const checkTimingValid = (timing) => {
    return (timing[0] !== -1 && timing[1] !== -1);
  }
  return(
    <>
      <div id={id} draggable="true" className={`price-card ${inactive ? "inactive-card" : ""}`}>
        <div className="price-card-header">
          {DropdownMenu}
          <h5 className="price-card-title">{plan_category}</h5>
          <img className={`drag-arrows ${inactive ? "inactive-card" : ""}`} draggable="false" src={dragArrows}/>
        </div>
        <div className="price-card-content-container">
          <div className="price-card-top">
            <div className="price">
              {
                parseFloat(plan_price) < 0.1 
                  ? type
                  : `$${Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2, 
                        maximumFractionDigits: 2
                      }).format(parseFloat(plan_price))}`
              }
            </div>
            {type !== "Single Sessions" && !(type === "Free" && plan_category === "Recurring") &&
              <div className={`plan-timing ${!type && "recurring-plans"}`}>
              { 
              billing_cycle 
                ? `/ ${billing_cycle}`
                :(`${format(firstDay, "MMM dd, YYY")} - ${format(lastDay, "MMM, dd, YYY")}`)
              }
              </div>
            }
            {type === "Semester"
                ? <img className="plan-logo" src={descriptionPlaceholder2} draggable="false"/>
                : type === "Single Sessions"
                  ? <img className="plan-logo" src={descriptionPlaceholder1} draggable="false"/>
                  : type === "Free"
                    ? <img className={`plan-logo ${plan_category}-${type}-logo`} src={freePlanLogo} draggable="false"/>
                    : <img className="plan-logo" src={descriptionPlaceholder3} draggable="false"/>
            }
            {description &&
              (type 
                ? <div className="plan-description">{description}</div>
                : !checkTimingValid(timing) && description && <div className="plan-description">{description}</div>)
            }

          </div>
          <div className={`price-card-middle ${!description && "no-description-plan"}`}>
            <ul>
              { !type || (type && type !== "Free" && type !== "Free Trial") && 
                <li>
                  Tax Plan: {taxInfo && taxInfo.length > 0 ? `${taxInfo[0].percentage}% Tax` : "None"}
                </li>
              }
              { !type && 
                (<>
                  <li>{days_per_week} day{days_per_week == 1 ? "" : "s"} / week</li>
                  <li>Day Slot: {day_slot}</li>
                </>)
              }
              {
                !type && checkTimingValid(timing) && <li>Time: {timing[0]} - {timing[1]}</li>
              }
              { deposit && <li>Deposit: ${deposit}</li> }
              { type && type === "Semester" &&
                (
                <>
                  <li>
                    Installments: {installments ? `${installments_breakdown}x` : "None"}
                  </li>
                  <li>
                    Plan Cap: {plan_cap || "None"}
                  </li>
                  <li>
                    Prorated: {isProrated ? "Yes" : "No"}
                  </li>
                  <li>
                    Add-ons: {
                      addOn === true
                        ? (<ul className="addOnSublist">
                            {addOnItems.map((addOnItem) => {
                              return (
                                <li>
                                  {addOnItem.text} | ${parseFloat(addOnItem.value).toFixed(2)}
                                </li>
                              );
                            })}
                          </ul>)
                        : "None"
                    }
                  </li>
                </>)
              }
              { type === "Drop-In" &&
                <>
                  <li>
                    Specific Drop-In Dates: {enableSpecificDates ? "Enabled" : "Disabled"}
                  </li>
                  <li>
                    Linked Semester: {linkedSemesterPlan ? "Yes" : "No"}
                  </li>
                  <li>
                    Premium: {planPremium ? `${planPremium}%` : "N/A"}
                  </li>
                </>
              }
              { (type === "Free" || type === "Free Trial" || type === "Drop-In")&& 
                <li>
                  Plan Cap: {plan_cap || "None"}
                </li> 
              }
              { type === "Free Trial" &&
                <>
                  <li>
                    Target: {target == 0 ? "New to my Org" : target == 1 ? "New to this program" : "Inactive clients"}
                  </li>
                  <li>
                    Eligible Users: {separateForChildren ? "Child Based" : "Parent Based"}
                  </li>
                  <li>
                    Trial Period: {trialPeriod} {trialPeriod == 1 ? "class" : "classes"}
                  </li>
                  <li>
                    Allow Automatic Follow-Ups: {autoEmail ? "Yes" : "No"}
                  </li>
                </>
              }
              { type === "Single Sessions" &&
                <>
                  <li>
                    Frequency: {frequency}
                  </li>
                  <li>
                    Quantity: {quantity}
                  </li>
                </>}
            </ul>
            <div className="date-selection">
              { dateSelection ? "Date Selection:" : ""} { dateSelection }
            </div>
            <div className="proration-selection">
              { enableProration ? "Prorated:" : ""} { enableProration }
            </div>
            <div className="installments-selection">
              { enableInstallments ? " Installments:" : ""} { enableInstallments }
            </div>
          </div>
          <div className="price-card-bottom">
            <div className="plan-enrollment-info">
              <div>Enrolled: {enrollments} {parseInt(applications) > 0 && `| Applied: ${applications}`}</div>
            </div>
            <button 
              className={`edit-button 
                ${parseInt(enrollments) > 0 
                  ? "disabled-button" 
                  : ""}`
                } 
              disabled={parseInt(enrollments) > 0}
              onClick={onEditButtonClick}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PriceCardModal;