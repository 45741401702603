import React, { Component } from "reactn";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK, STRING_INPUT, DROPDOWN } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { format } from "date-fns";

class CopyExistingMilestonesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyMilestoneType: "",
      milestoneType: "",
      copyProgram: "",
      program: "",
      programList: [],
      onlineList: [],
      buttonPressed: false
    };
  }

  async componentDidMount() {
    await this.fetchInitialData();
  }

  async fetchInitialData() {
    // this.setGlobal({ loading: true });

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API}/partners/programs/list`
      );
      if (resp.data.success) {
        this.setState({ programList: resp.data.data });
      } else {
        console.error("error getting programs -----");
      }
    } catch (err) {
      console.error(err);
    }

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API}/partners/virtual/list`
      );
      if (resp.data.success) {
        this.setState({ onlineList: resp.data.data });
      } else {
        console.error("error getting online -----");
      }
    } catch (err) {
      console.error(err);
    }

    // this.setGlobal({ loading: false });
  }

  async copyExistingMilestone() {
    const programId = parseInt(this.state.copyProgram);
    this.setGlobal({ loading: true });
    this.setState({ buttonPressed: true });

    if (this.state.copyMilestoneType === "") {
      toast.error("Please select the type of milestone for copying");
      return;
    }

    if (this.state.copyProgram === "") {
      toast.error("Please select the program to copy the milestones from");
      return;
    }

    if (this.state.copyMilestoneType === "Program") {
      const ep = `${process.env.REACT_APP_API}/partners/milestones/get/${programId}`;
      const results = await axios.get(ep);

      if (results.data.success && results.data.data.length < 1) {
        toast.error("The selected program does not contain any milestones");
        return;
      }

      const ep2 = `${process.env.REACT_APP_API}/partners/milestones/copy`;
      const results2 = await axios.post(ep2, {
        copyMilestones: results.data.data,
        milestoneType: this.props.milestoneType,
        milestoneId: parseInt(this.props.milestoneId)
      });
    } else {
      const ep = `${process.env.REACT_APP_API}/partners/milestones/get_online/${programId}`;
      const results = await axios.get(ep);

      if (results.data.success && results.data.data.length < 1) {
        toast.error("The selected program does not contain any milestones");
        return;
      }

      const ep2 = `${process.env.REACT_APP_API}/partners/milestones/copy`;
      const results2 = await axios.post(ep2, {
        copyMilestones: results.data.data,
        milestoneType: this.props.milestoneType,
        milestoneId: parseInt(this.props.milestoneId)
      });
    }

    toast.success("Successfully Copied Milestone!");
    this.props.onClose();
    this.setGlobal({ loading: false });
  }

  getCopyListChoices = () => {
    if (this.state.copyMilestoneType === "") {
      return [];
    }
    if (this.state.copyMilestoneType === "Program") {
      return (
        this.state.programList
          ?.map(program => {
            if (program.id === parseInt(this.props.milestoneId)) {
              return [];
            }
            return {
              value: program.id,
              text: program.program_name
            };
          })
          .filter(choice => !Array.isArray(choice)) || []
      );
    } else {
      return (
        this.state.onlineList
          ?.map(online => {
            if (online.id === parseInt(this.state.copyProgram)) {
              return [];
            }
            return {
              value: online.id,
              text: online.program_name
            };
          })
          .filter(choice => !Array.isArray(choice)) || []
      );
    }
  };

  getListChoices = () => {
    if (this.state.milestoneType === "") {
      return [];
    }
    if (this.state.milestoneType === "Program") {
      const programChoices =
        this.state.programList
          ?.map(program => {
            if (program.id === parseInt(this.state.copyProgram)) {
              return [];
            }
            return {
              value: program.id,
              text: program.program_name
            };
          })
          .filter(choice => !Array.isArray(choice)) || [];

      return programChoices;
    } else {
      const onlineChoices =
        this.state.onlineList
          ?.map(online => {
            if (online.id === parseInt(this.state.copyProgram)) {
              return [];
            }
            return {
              value: online.id,
              text: online.program_name
            };
          })
          .filter(choice => !Array.isArray(choice)) || [];

      return onlineChoices;
    }
  };

  getBaseModalFields() {
    const fieldArr = [];

    const copyFromType = {
      type: DROPDOWN,
      data: {
        name: "Copy From Type",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            copyMilestoneType: e.target.value,
            copyProgram: ""
          });
        },
        choices: [
          { value: "Program", text: "Program" },
          { value: "Online", text: "Online" }
        ]
      }
    };

    const copyFromProgram = {
      type: DROPDOWN,
      data: {
        name: "Copy From Program",
        placeholder: "Choose here",
        value: this.state.copyProgram,
        required: true,
        choices: this.getCopyListChoices(),
        handleChange: e => this.setState({ copyProgram: e.target.value })
      }
    };

    const copyToType = {
      type: DROPDOWN,
      data: {
        name: "Milestone Type",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            milestoneType: e.target.value,
            program: ""
          });
        },
        choices: [
          { value: "Program", text: "Program" },
          { value: "Online", text: "Online" }
        ]
      }
    };

    const copyToProgram = {
      type: DROPDOWN,
      data: {
        name: "Program",
        placeholder: "Choose here",
        value: this.state.program,
        required: true,
        choices: this.getListChoices(),
        handleChange: e => this.setState({ program: e.target.value })
      }
    };

    fieldArr.push(copyFromType);
    fieldArr.push(copyFromProgram);
    // fieldArr.push(copyToType);
    // fieldArr.push(copyToProgram);

    return fieldArr;
  }

  getBaseModalButtons = () => {
    const copyButton = {
      name: "Copy",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      isDisabled: this.state.buttonPressed,
      handleClick: e => this.copyExistingMilestone(e)
    };

    return [copyButton];
  };

  getBaseModalProps = () => {
    console.log("STATE", this.state, this.props);
    return {
      title: "Copy Milestone",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default CopyExistingMilestonesModal;
