import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton, Switch } from "@material-ui/core";
import { formTypes } from "../UI/form";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH, DROPDOWN, TITLE } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddEventTicketModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ticket_name: "",
      ticket_price: 0,
      ticket_quantity: 1,
      ticket_status: this.props.ticketType,
      isTaxEnabled: false,
      taxInfo: [],
      selectedMembership: "",
      published_memberships: []
    };
  }

  async componentDidMount() {
    console.log("OCCURED")
    if (this.state.ticket_status === "Membership") {
      const published_memberships = await this.getPublishedMemberships()

      this.setState({
        ...this.state,
        published_memberships: published_memberships
      })
    }
  }

  async getPublishedMemberships() {
    const ep = `${process.env.REACT_APP_API}/partners/memberships/list`;
    const result = await axios.get(ep);

    // console.log("HERE", );
    return result.data.data.filter(membership => membership.published && !membership.isArchived).map((e, i) => {
      return {
        value: e.id,
        text: e.membership_name
      }
    })
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async savePlan() {
    if (
      this.state.ticket_status !== "Free" &&
      this.state.ticket_price !== 0 &&
      !this.global.organizationInfo.stripeId
    ) {
      toast.error("Denied. Please connect your Bank Account first");
      return;
    }

    const obj = { ...this.state, id: this.global.event.tickets.length };

    this.setGlobal({
      event: {
        ...this.global.event,
        ["tickets"]: [...this.global.event.tickets, obj]
      }
    });
    await this.dispatch.saveEvent();
    this.props.onClose();
  }

  /// ================== NEW MODAL ========================== ///

  taxesOptions = () => {
    //const global_organization = [JSON.parse(this.global.organizationInfo.stripeTaxId)]
    const global_organization = this.global.organizationInfo.stripeTaxId;
    console.log("global_organization", global_organization);
    const DATA = [];
    for (let i = 0; i < global_organization.length; i++) {
      DATA.push(JSON.parse(global_organization[i]));
    }
    console.log("DATA", DATA);

    return [
      ...DATA.map((e, i) => ({
        value: i,
        text: e.display_name + " " + e.jurisdiction + " " + e.percentage + "%"
      }))
    ];
  };

  getBaseModalFields() {
    const ticketName = {
      type: STRING_INPUT,
      data: {
        name: "Ticket Name",
        required: true,
        handleChange: e => {
          this.setState({
            ticket_name: e.target.value
          });
        },
        value: this.state.ticket_name
      },
      validators: { validateSubmit: () => this.state.ticket_name !== "" }
    };

    const pricingTicket = {
      type: STRING_INPUT,
      data: {
        name: "Pricing for Ticket",
        required: true,
        isDisabled: this.state.ticket_status === "Free",
        type: "number",
        handleChange: e => {
          if (e.target.value === "") {
            this.setState({
              ticket_price: ""
            });
            
            return;
          }

          let price = parseFloat(e.target.value || 0);
          let decimalValue = e.target.value.split(".")[1];
          console.log("Price 1: ", price);
          if (decimalValue && decimalValue.length > 2) {
            return;
          } else if (!e.target.value.endsWith("0")) {
            price = Math.round(price * 100) / 100;
          } else {
            price = e.target.value;
          }
          console.log("Price 2: ", price);
          this.setState({
            ticket_price: price.toString()
          });
        },
        value: this.state.ticket_price
      },
      validators: {
        validateSubmit: () =>
          this.state.ticket_price >= 0 && this.state.ticket_price !== ""
      }
    };

    const ticketQuantity = {
      type: STRING_INPUT,
      data: {
        name: "Ticket Quantity",
        required: false,
        type: "number",
        handleChange: e => {
          this.setState({
            ticket_quantity: e.target.value
          });
        },
        value: this.state.ticket_quantity
      }
    };

    const status = {
      type: TITLE,
      data: { name: `Type: ${this.state.ticket_status}` }
    };

    const enableTaxes = {
      type: SWITCH,
      data: {
        name: "Enable Taxes",
        isDisabled: false,
        checked: this.state.isTaxEnabled,
        handleClick: e =>
          this.setState({
            isTaxEnabled: e.target.checked,
            taxInfo: []
          })
      }
    };

    const selectTaxes = {
      type: DROPDOWN,
      data: {
        name: "Tax Plan",
        required: true,
        placeholder: "Please Select" /* 
            value: this.state.taxInfo, */,
        handleChange: e => {
          const item = JSON.parse(
            this.global.organizationInfo.stripeTaxId[e.target.value]
          );
          this.setState({
            taxInfo: [
              {
                id: item.id,
                percentage: item.percentage,
                jurisdiction: item.jurisdiction,
                display_name: item.display_name,
                description: item.description
              }
            ]
          });
        },
        choices: this.taxesOptions()
      },
      validators: { validateSubmit: () => this.state.taxInfo.length > 0}
    };
    
    const selectMembership = {
      type: DROPDOWN,
      data: {
        name: "Select Membership",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            selectedMembership: e.target.value
          });
        },
        choices: this.state.published_memberships
      },
      validators: { validateSubmit: () => this.state.selectedMembership !== "" }
    };

    const fields = [];
    fields.push(ticketName);
    fields.push(pricingTicket);
    fields.push(ticketQuantity);
    fields.push(status);

    if (this.state.ticket_status === "Membership") {
      fields.push(selectMembership);
    }

    if (this.state.ticket_status !== "Free") {
      fields.push(enableTaxes);
    }
    if (this.state.isTaxEnabled) {
      fields.push(selectTaxes);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.savePlan()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Pricing Plan",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    console.log("this.state.ticket_status", this.state.ticket_status);
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div style={{ display: "block" }}>
            <img
              src={ModalBanner}
              alt="Modal Banner"
              style={{
                height: "40px",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px",
                marginBottom: ".5rem"
              }}
            >
              <h3>Pricing Plan</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  marginBottom: "1rem"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="ticket_name"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Ticket Name *
                </label>
                <input
                  className="form-control"
                  style={{ width: "70%" }}
                  type="text"
                  name="ticket_name"
                  value={this.state.ticket_name}
                  onChange={e => this.setState({ ticket_name: e.target.value })}
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="price_per_term"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Pricing for Ticket *
                </label>
                <input
                  className="form-control"
                  style={{ width: "70%" }}
                  type="number"
                  name="ticket_price"
                  value={this.state.ticket_price}
                  onChange={e =>
                    this.setState({ ticket_price: e.target.value })
                  }
                  disabled={this.state.ticket_status === "Free"}
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="ticket_quantity"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Ticket Quantity
                </label>
                <input
                  className="form-control"
                  style={{ width: "70%" }}
                  type="number"
                  name="ticket_quantity"
                  value={this.state.ticket_quantity}
                  onChange={e =>
                    this.setState({ ticket_quantity: e.target.value })
                  }
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="ticket_status"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Ticket Status
                </label>
                <span
                  style={{ width: "70%", fontSize: "16px" }}
                  name="ticket_status"
                >
                  {this.state.ticket_status}
                </span>
              </div>
              {this.state.ticket_status !== "Free" && (
                <>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      Enable Taxes
                    </label>
                    <div style={{ width: "70%", textAlign: "left" }}>
                      <Switch
                        checked={this.state.isTaxEnabled}
                        onChange={e =>
                          this.setState({
                            isTaxEnabled: e.target.checked,
                            taxInfo: []
                          })
                        }
                        color="primary"
                      />
                    </div>
                  </div>

                  {this.state.isTaxEnabled && (
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: 0
                      }}
                    >
                      <label
                        htmlFor="taxInfo"
                        style={{
                          width: "25%",
                          textAlign: "left",
                          marginRight: "22px"
                        }}
                      >
                        Tax Plan
                      </label>
                      <select
                        name="taxInfo"
                        id="taxInfo"
                        type="number"
                        className="form-control"
                        style={{ width: "75%" }}
                        min={1}
                        onChange={e => {
                          const item = JSON.parse(
                            this.global.organizationInfo.stripeTaxId[
                              e.target.value
                            ]
                          );
                          this.setState({
                            [e.target.name]: [
                              {
                                id: item.id,
                                percentage: item.percentage,
                                jurisdiction: item.jurisdiction,
                                display_name: item.display_name,
                                description: item.description
                              }
                            ]
                          });
                        }}
                      >
                        <option value={[]}>Please Select:</option>
                        {this.global.organizationInfo.stripeTaxId.map(
                          (ele, i) => {
                            const e =
                              typeof ele === "string" ? JSON.parse(ele) : ele;
                            return (
                              <>
                                <option value={i}>
                                  {e.display_name} {e.jurisdiction}{" "}
                                  {e.percentage}%
                                </option>
                              </>
                            );
                          }
                        )}
                      </select>
                    </div>
                  )}
                </>
              )}
            </form>
            <div className="modal-footer">
              <button
                type="submit"
                onClick={_ => this.savePlan()}
                className="btn profile-btn"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddEventTicketModal);
