import React, { Component } from "reactn";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK, STRING_INPUT, DROPDOWN, TITLE } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { format } from "date-fns";

class CopyExistingMembershipModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_membership_name: "",
      copyMembership: "",
      membershipList: [],
      canUserCreate: false,
      buttonPressed: false
    };
  }

  async componentDidMount() {
    
    await this.fetchInitialData();
    console.log("global", this.global);
    console.log("state", this.state);
    console.log("props", this.props);
  }

  async fetchInitialData() {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API}/partners/memberships/list`
      );
      if (resp.data.success) {
        const currentOrder = resp.data.data
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .sort((a, b) => a.display_order - b.display_order);
        this.setState({ membershipList: currentOrder });
      } else {
        console.error("error getting membership list -----");
      }

      const result = await axios.get(`${process.env.REACT_APP_API}/partners/permissions/membership/can_user_create_membership`);
      if (result.data.success) {
        this.setState({
          canUserCreate: true
        })
      }
      
    } catch (err) {
      console.error(err);
    }
  }

  copyExistingMembership = async () => {
    const copyFromMembership= { ...this.state.membershipList.find(membership => parseInt(membership.id) === parseInt(this.state.copyMembership)) }

    if (copyFromMembership.membership_name === this.state.new_membership_name) {
      toast.error("You cannot choose the same name");
      return;
    }
    this.setGlobal({loading: true});
    this.setState({ buttonPressed: true });

    let product_stripe = null;
    const copyOfPrices = [...this.global.membership.prices];
    this.global.membership.prices = [];
    try {
      for (const price of copyOfPrices) {
        const parse_price = JSON.parse(price);
        console.log("parse_price", parse_price);
          const ep = `${process.env.REACT_APP_API}/partners/stripe/add_products`;
          const res = await axios.post(ep, {
            mem: parse_price,
            parent: "Membership",
            type: parse_price.auto_renew ? "Recurring" : "One-time",
            name: this.global.membership.membership_name,
            product: product_stripe
          });
          product_stripe = res.data.data.product;
          this.setGlobal({
            membership: {
              ...this.global.membership,
              product_stripe: product_stripe,
              ["prices"]: [...this.global.membership.prices, JSON.stringify({
                ...parse_price,
                id: this.global.membership.prices.length,
                price_stripe: res.data.data.id})]
            }
          });
      }
      await this.dispatch.saveMembership();
    } catch (err) {
      console.error(err)
    }
    this.setGlobal({loading: false});
    this.props.onClose();
  }

  getListChoices = () => {
    const membershipChoices = this.state.membershipList.map(membership => {
      return {
        value: membership.id,
        text: membership.membership_name
      };
    })
    return membershipChoices;
  };

  getBaseModalFields() {
    const fieldArr = [];

    const copyFromMembership = {
      type: DROPDOWN,
      data: {
        name: "Copy From Membership",
        placeholder: "Choose here",
        value: this.state.copyMembership,
        required: true,
        choices: this.getListChoices(),
        handleChange: e => {
          this.setState({ copyMembership: e.target.value })
          let copyFromMembership = { ...this.state.membershipList.find(membership => parseInt(membership.id) === parseInt(e.target.value)) }
          copyFromMembership.id = null;
          copyFromMembership.membership_name = "";
          copyFromMembership.display_order = 0;
          copyFromMembership.published = false;
          copyFromMembership.isArchived = false;

          delete copyFromMembership.timezone;
          delete copyFromMembership.createdAt;
          delete copyFromMembership.updatedAt;
          delete copyFromMembership.product_stripe;
          this.setGlobal({
            membership: {
              ...copyFromMembership
            }
          });
        }
      },
      validators: { validateSubmit: () => this.state.copyMembership != "" }
    };

    const newMembershipName = {
      type: STRING_INPUT,
      data: {
        name: "New Membership Name",
        placeholder: "Enter new name here",
        required: true,
        value: this.state.new_membership_name,
        handleChange: e => {
          this.setState({
            new_membership_name: e.target.value
          })
          this.setGlobal({
            membership: {
              ...this.global.membership,
              membership_name: e.target.value
            }
          })
        },
      },
      validators: { validateSubmit: () => this.state.new_membership_name != "" }
    }

    fieldArr.push(copyFromMembership);

    if (this.state.copyMembership) {
      fieldArr.push(newMembershipName);
    }

    return fieldArr;
  }

  getLimitReachedModal = () => {
    const props = {
      title: "Copy Membership",
      fields: [
        { type: TITLE, data: { name: "You have reached your membership limit." } }
      ],
      handleClose: this.props.onClose
    };
    return <BaseModal {...props} />;
  };

  getBaseModalButtons = () => {
    const copyButton = {
      name: "Copy",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      isDisabled: this.state.buttonPressed,
      handleClick: e => this.copyExistingMembership(e)
    };

    return [copyButton];
  };

  getBaseModalProps = () => {
    console.log("STATE", this.state, this.props);
    return {
      title: "Copy Membership",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    if (!this.state.canUserCreate) {
      return this.getLimitReachedModal()
    }

    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default CopyExistingMembershipModal;
