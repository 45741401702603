import React, { Component } from "reactn";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK, STRING_INPUT, DROPDOWN, TITLE } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { format } from "date-fns";

class CopyExistingPartyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_party_title: "",
      copyParty: "",
      partyList: [],
      canUserCreate: false,
      buttonPressed: false
    };
  }

  async componentDidMount() {
    
    await this.fetchInitialData();
    console.log("global", this.global);
    console.log("state", this.state);
    console.log("props", this.props);
  }

  async fetchInitialData() {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API}/partners/parties/list`
      );
      if (resp.data.success) {
        const currentOrder = resp.data.data
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .sort((a, b) => a.display_order - b.display_order);
        this.setState({ partyList: currentOrder });
      } else {
        console.error("error getting party list -----");
      }

      const result = await axios.get(`${process.env.REACT_APP_API}/partners/permissions/parties/can_user_create_party`);
      if (result.data.success) {
        this.setState({
          canUserCreate: true
        })
      }
      
    } catch (err) {
      console.error(err);
    }
  }

  copyExistingParty = async () => {
    const copyFromParty= { ...this.state.partyList.find(party => parseInt(party.id) === parseInt(this.state.copyParty)) }

    if (copyFromParty.party_title === this.state.new_party_title) {
      toast.error("You cannot choose the same name");
      return;
    }
    this.setGlobal({loading: true});
    this.setState({ buttonPressed: true });

    let product_stripe = null;
    const copyOfTickets = [...this.global.party.tickets];
    this.global.party.tickets = [];
    try {
      for (const ticket of copyOfTickets) {
        const parse_ticket = JSON.parse(ticket);
        console.log("parse_ticket", parse_ticket);
        if (parse_ticket.ticket_status === "Free") {
          this.setGlobal({
            party: {
              ...this.global.party,
              product_stripe: product_stripe,
              ["tickets"]: [...this.global.party.tickets, JSON.stringify({
                ...parse_ticket,
                id: this.global.party.tickets.length})]
            }
          });
        } else {
          const ep = `${process.env.REACT_APP_API}/partners/stripe/add_products`;
          const res = await axios.post(ep, {
            ticket: parse_ticket,
            parent: "Party",
            name: this.global.party.party_title,
            product: product_stripe
          });
          product_stripe = res.data.data.product;
          this.setGlobal({
            party: {
              ...this.global.party,
              product_stripe: product_stripe,
              ["tickets"]: [...this.global.party.tickets, JSON.stringify({
                ...parse_ticket,
                id: this.global.party.tickets.length,
                ticket_stripe: res.data.data.id})]
            }
          });
        }
      }
      await this.dispatch.saveParty();
    } catch (err) {
      console.error(err)
    }
    this.setGlobal({loading: false});
    this.props.onClose();
  }

  getListChoices = () => {
    const partyChoices = this.state.partyList.map(party => {
      return {
        value: party.id,
        text: party.party_title
      };
    })
    return partyChoices;
  };

  getBaseModalFields() {
    const fieldArr = [];

    const copyFromParty = {
      type: DROPDOWN,
      data: {
        name: "Copy From Party",
        placeholder: "Choose here",
        value: this.state.copyParty,
        required: true,
        choices: this.getListChoices(),
        handleChange: e => {
          this.setState({ copyParty: e.target.value })
          let copyFromParty = { ...this.state.partyList.find(party => parseInt(party.id) === parseInt(e.target.value)) }
          copyFromParty.id = null;
          copyFromParty.party_title = "";
          copyFromParty.display_order = 0;
          copyFromParty.published = false;
          copyFromParty.isArchived = false;

          delete copyFromParty.timezone;
          delete copyFromParty.createdAt;
          delete copyFromParty.updatedAt;
          delete copyFromParty.product_stripe;
          this.setGlobal({
            party: {
              ...copyFromParty
            }
          });
        }
      },
      validators: { validateSubmit: () => this.state.copyParty != "" }
    };

    const newPartyName = {
      type: STRING_INPUT,
      data: {
        name: "New Party Name",
        placeholder: "Enter new name here",
        required: true,
        value: this.state.new_party_title,
        handleChange: e => {
          this.setState({
            new_party_title: e.target.value
          })
          this.setGlobal({
            party: {
              ...this.global.party,
              party_title: e.target.value
            }
          })
        },
      },
      validators: { validateSubmit: () => this.state.new_party_title != "" }
    }

    fieldArr.push(copyFromParty);

    if (this.state.copyParty) {
      fieldArr.push(newPartyName);
    }

    return fieldArr;
  }

  getLimitReachedModal = () => {
    const props = {
      title: "Copy Party",
      fields: [
        { type: TITLE, data: { name: "You have reached your party limit." } }
      ],
      handleClose: this.props.onClose
    };
    return <BaseModal {...props} />;
  };

  getBaseModalButtons = () => {
    const copyButton = {
      name: "Copy",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      isDisabled: this.state.buttonPressed,
      handleClick: e => this.copyExistingParty(e)
    };

    return [copyButton];
  };

  getBaseModalProps = () => {
    console.log("STATE", this.state, this.props);
    return {
      title: "Copy Party",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    if (!this.state.canUserCreate) {
      return this.getLimitReachedModal()
    }

    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default CopyExistingPartyModal;
