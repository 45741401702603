import React, { useState } from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalTemplate } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { BLUE_BUTTON, RED_BUTTON } from "./baseModal/colours";
import { STRING_INPUT, TITLE } from "./baseModal/FieldTypes";

/**
 * Modal can be used as an waitlist confirmation modal.
 *
 * @callback onSubmit Function executed upon user confirmation
 * @callback onClose Function executed when modal is closed.
 *
 * @param {Boolean} toArchive true means archival, false means unarchival.
 * @param {Boolean} confirmHeader Optional custom modal header.
 * @param {String | JSX} modalBody Optional custom modal body.
 */

const ConfirmWaitlistModal = ({
  onSubmit,
  onClose,
  modalBody
}) => {
  const [confirmed, setConfirmed] = useState(false);
  
  const confirmationHandler = async e => {
    const successMsg = "Successfully waitlisted listing"
    const errorMsg = "Error occured while waitlisting listing";
    try {
      e.preventDefault();
      await onSubmit();
      onClose();
      toast.success(successMsg);
    } catch (error) {
      toast.error(errorMsg);
    }
  };

  const getConfirmWaitlistModalFields = () => {
    const titleField = {
      type: TITLE,
      data: {
        name:
          modalBody
      }
    };

    const confirmField = {
      type: STRING_INPUT,
      data: {
        name: "Type 'confirm' to enable waitlist for this listing and waitlist all pending applications.",
        required: true,
        placeholder: "Type 'confirm'",
        handleChange: e => {
          if (e.target.value.toLowerCase() === "confirm") {
            setConfirmed(true);
          } else {
            setConfirmed(false);
          }
        }
      }
    }

    const fields = [];

    fields.push(titleField);
    fields.push(confirmField);
    return fields;
  };

  const getConfirmWaitlistModalButtons = () => {
    const createButton = {
      name: "Confirm",
      buttonColour: BLUE_BUTTON,
      textColour: "#fff",
      isDisabled: !confirmed,
      handleClick: confirmationHandler
    };
    return [createButton];
  };

  const getConfirmWaitlistModalProps = () => {
    return {
      title: "Confirmation",
      fields: getConfirmWaitlistModalFields(),
      buttons: getConfirmWaitlistModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: onClose
    };
  };


  return (
    <BaseModal {...getConfirmWaitlistModalProps()} />
  );
};

export default withRouter(ConfirmWaitlistModal);
