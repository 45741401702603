import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Button, Switch } from "@material-ui/core";
import Select from "react-dropdown-select";
import "../../assets/css/componentSpecificCss/addEnrollmentsModal.css";
import DateSel from "react-datepicker";
import moment from "moment";
import { addDays, format, differenceInMinutes } from "date-fns";
import { formTypes } from "../UI/form";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import DateTimePickerModal from "./DateTimePickerModal";
import ReactTooltip from "react-tooltip";
import { fetchConnectionsData } from "../../Caching";

const PERCENT = "Percentage";
const FIXED_AMOUNT = "Fixed Amount";
class AddInstructor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      singleSessionQuantity: 1,
      isSelectDates: false,
      singleSessionSelectedDates: [],
      email: "",
      addNew: false,
      addExisting: false,
      fillApp: false,
      selectedPlan: "",
      data: { Active: [], Pending: [] },
      loadingComplete: false,
      loadingCompleteChild: false,
      selectedUser: null,
      selectedChild: null,
      amount: null,
      ticket: null,
      newLeads: [],
      selectedLead: null,
      promotion: [],
      hasPromotion: false,
      promoPlan: [],
      isPromoPlanSelected: false,
      isAdvanced: false,
      isSkipForm: false,
      isWaiveDeposit: false,
      displayDate: null,
      startDate: null,
      recurringCustomerSelectedDays: [],
      toRemoveDays: [],
      showWaiveDeposit: false,
      isStartSet: false,
      message: "",
      fieldIsInvalid: {
        //named this after the "is-invalid" css class that indicates an error!
        name: false,
        email: false,
        selectedPlan: false
      },
      autoRegister: false,
      child: []
    };
    this.required = [
      //"name" and "email" input boxes only apply to new clients (addExisting=false)
      {
        name: "name",
        validation: e =>
          this.state.addExisting
            ? false
            : !(this.state.selectedLead || this.state.selectedUser || e)
      },
      {
        name: "email",
        validation: e =>
          this.state.addExisting
            ? false
            : !(this.state.selectedLead || this.state.selectedUser || e)
      },
      //"selectedPlan" applies to both, new and existing clients.
      // { name: "selectedPlan", validation: e => !e },
      //"selectedUser" select dropdown only applies to existing clients (addExisting=true)
      {
        name: "selectedUser",
        validation: e => (this.state.addExisting ? !e : false)
      }
    ];
  }

  requestTour = async e => {
    e.preventDefault();
  };

  getPlan = () => {
    if (!this.state.selectedPlan) return null;
    const plan = JSON.parse(
      this.props.program.plans.filter(
        e => JSON.parse(e).id == this.state.selectedPlan
      )[0]
    );
    console.log("plan", plan);
    return plan;
  };

  // type is "New" or "Existing"
  async submitForm(e, type) {
    e.preventDefault();

    let cancel = false;
    let fieldIsInvalid = {};
    for (let requiredField of this.required) {
      const isNotValid = requiredField.validation(
        this.state[requiredField.name]
      );
      cancel = cancel || isNotValid;
      fieldIsInvalid[requiredField.name] = isNotValid;
    }
    if (cancel) {
      this.setState({ fieldIsInvalid });
      return;
    }

    // 2022-07-27 this.state.fillApp is always false
    if (this.state.fillApp) {
      this.dispatch.addParentProfile({
        displayName: this.state.name,
        email: this.state.email,
        plan: this.state.selectedPlan.split(" ")[0],
        modifier: this.state.selectedPlan.split(" ")[1],
        type: this.props.type,
        children: [],
        guardians: [],
        emergency: [],
        physicians: [],
        program: this.props.program.id,
        program_name: this.props.program.program_name
      });
      this.props.history.push("/partners/complete");
      return;
    }
    const endp = this.state.isPromoPlanSelected
      ? `${process.env.REACT_APP_API}/partners/users/promo/invite_parent`
      : `${process.env.REACT_APP_API}/partners/users/invite_parent`;
    console.log(
      "AddEnrollmentModal.jsx 104: amount passed in request",
      this.state.amount
    );
    let result;

    if (type === "Existing" && this.state.autoRegister) {
      let ep;

      if (this.props.table === "partner_programs") {
        ep = `${process.env.REACT_APP_API}/partners/users/program/auto_register`;
      } else if (this.props.table === "partner_online") {
        ep = `${process.env.REACT_APP_API}/partners/users/online/auto_register`;
      } else if (this.props.table === "partner_event") {
        ep = `${process.env.REACT_APP_API}/partners/users/event/auto_register`;
      }

      const data = {
        plan: null,
        autoRegister: true,
        dsUserId: this.state.selectedUser,
        partner: this.props.program.partnerId,
        type: "Free",
        installments: this.props.installments,
        program: this.props.program,
        dropins: this.state.dropIns,
        selectedDays: this.state.selected_days,
        child: this.state.selectedChild,
        application_required: false,
        isEnableWaitlist: false,
        formData: this.state.formData,
        startDate: this.state.startDate,
        formId: null,
        recurringCustomerSelectedDays: this.state.recurringCustomerSelectedDays,
        promoCode: null,
        applyGiftCard: 0,
        itemAmount: 0,
        depositAmount: 0,
        taxAmount: 0,
        promotionAmount: 0,
        couponAmount: 0,
        creditCardFeeAmount: this.state.creditCardFeeAmount,
        totalAmount: 0,
        addOnSemesterItemAmount: this.state.addOnSemesterItemAmount,
        // userSelectedQuanity: userQuantity,
        sessionDateTimes: this.state.sessionDateTimes,
        classSessionDailySelectedDays: this.state.classSessionDailySelectedDays,
        classSessionDailyUnselectedDatesValidation:
          this.state.classSessionDailyUnselectedDatesValidation,
        classSessionWeeklyUnselectedDatesValidation:
          this.state.classSessionWeeklyUnselectedDatesValidation,
        classSessionWeeklyDate: this.state.classSessionWeeklyDate,
        sessionsCustomerSelectedDays: this.state.sessionWeeklySelectedDays,
        isWaiveDeposit: this.state.isWaiveDeposit
      };

      result = await axios.post(ep, data);
    } else {
      let inactive = false;

      if (this.state.selectedPlan) {
        const plans =
          this.props.program.plans ||
          this.props.program.tickets ||
          this.props.program.prices;
        const plan = plans.filter(
          e => parseInt(JSON.parse(e).id) === parseInt(this.state.selectedPlan)
        )[0];
        if (JSON.parse(plan).active === false) {
          inactive = true;
        }
      }
      console.log(this.state)
      if (type === "New") {
        result = await axios.post(endp, {
          name:
            this.state.selectedLead !== null || this.state.selectedUser !== null
              ? this.state.selectedLead !== null
                ? this.state.newLeads.filter(
                  f => f.id == this.state.selectedLead
                )[0]["displayName"]
                : this.state.newLeads.filter(
                  f => f.dsUserId == this.state.selectedUser
                )[0]["displayName"]
              : this.state.name,
          email:
            this.state.selectedLead !== null || this.state.selectedUser !== null
              ? this.state.selectedLead !== null
                ? this.state.newLeads.filter(
                  f => f.id == this.state.selectedLead
                )[0]["email"]
                : this.state.newLeads.filter(
                  f => f.dsUserId == this.state.selectedUser
                )[0]["email"]
              : this.state.email,
          program: this.props.program.id,
          program_name:
            this.props.program.program_name ||
            this.props.program.event_title ||
            this.props.program.membership_name ||
            this.props.program.party_title,
          plan: this.state.selectedPlan.split(" ")[0],
          modifier: this.state.selectedPlan.split(" ")[1],
          selectedUser: this.state.selectedUser,
          type: this.props.type,
          isParent: this.props.program.addChild,
          amount: this.state.amount,
          ticket: this.state.ticket,
          product_table: this.props.table,
          promotion: this.state.isPromoPlanSelected
            ? this.state.promotion[0]
            : null,
          startDate: this.state.startDate,
          recurringCustomerSelectedDays:
            this.state.recurringCustomerSelectedDays,
          isSkipForm: this.state.isSkipForm,
          isWaiveDeposit: this.state.isWaiveDeposit,
          isStartSet: this.state.isStartSet,
          message: this.state.message !== "" ? this.state.message : null,
          inactive: inactive,
          singleSessionQuantity: this.state.singleSessionQuantity,
          singleSessionSelectedDates: this.state.singleSessionSelectedDates
        });
      } else {
        result = await axios.post(endp, {
          name:
            this.state.selectedLead !== null
              ? this.state.newLeads.filter(
                f => f.id == this.state.selectedLead
              )[0]["displayName"]
              : this.state.name,
          email:
            this.state.selectedLead !== null
              ? this.state.newLeads.filter(
                f => f.id == this.state.selectedLead
              )[0]["email"]
              : this.state.email,
          program: this.props.program.id,
          program_name:
            this.props.program.program_name ||
            this.props.program.event_title ||
            this.props.program.membership_name ||
            this.props.program.party_title,
          plan: this.state.selectedPlan.split(" ")[0],
          modifier: this.state.selectedPlan.split(" ")[1],
          selectedUser: this.state.selectedUser,
          type: this.props.type,
          isParent: this.props.program.addChild,
          amount: this.state.amount,
          ticket: this.state.ticket,
          product_table: this.props.table,
          promotion: this.state.isPromoPlanSelected
            ? this.state.promotion[0]
            : null,
          startDate: this.state.startDate,
          recurringCustomerSelectedDays:
            this.state.recurringCustomerSelectedDays,
          isSkipForm: this.state.isSkipForm,
          isWaiveDeposit: this.state.isWaiveDeposit,
          isStartSet: this.state.isStartSet,
          message: this.state.message !== "" ? this.state.message : null,
          inactive: inactive,
          singleSessionQuantity: this.state.singleSessionQuantity,
          singleSessionSelectedDates: this.state.singleSessionSelectedDates
        });
      }
    }

    if (result.data.success) {
      console.log("result", result);
      if (this.state.autoRegister) {
        toast.success("User Registered Successfully!");
      } else {
        toast.success("User Invite Successful!");
      }
      this.props.onClose();
    } else {
      toast.error(`User already exists!`);
    }
  }
  async componentDidMount() {
    const ep2 = `${process.env.REACT_APP_API}/partners/leads/all/${this.global.organizationInfo.partnerId}`;
    const results2 = await axios.get(ep2);

    const ep3 = `${process.env.REACT_APP_API}/find/partners/promotions/${this.props.promotion_type}/${this.props.program.id}`;
    const results3 = await axios.get(ep3);

    const parsedPlans =
      this.props.type === "events" || this.props.type === "parties"
        ? this.props.program.tickets.map(e => JSON.parse(e))
        : this.props.type === "memberships"
          ? this.props.program.prices.map(e => JSON.parse(e))
          : this.props.program.plans.map(e => JSON.parse(e));

    const promoPlan = {};
    if (results3.data.data.length > 0) {
      results3.data.data.map((promo, i) => {
        promo.plan_price_ticketId.map((ticketId) => {
          if (!promoPlan[parsedPlans[ticketId].id]) {
            promoPlan[parsedPlans[ticketId].id] = [];
          }
          console.log("parsedPlans: ", parsedPlans);
          console.log("ticketID: ", ticketId);
          promoPlan[parsedPlans[ticketId].id].push({...parsedPlans[ticketId], promoId: i , isPromo: true});
        });
      });
    }

    let excludedDays;
    if (this.props.type !== "events" && this.props.type !== "parties" && this.props.type !== "memberships") {
      const days_of_week = Object.keys(this.props.program.days_of_week);
      excludedDays = this.getExcludedDays(days_of_week);
    }

    // TESTING HERE
    const connections = await fetchConnectionsData(this.global.dsUser.id);
    const active = [];
    const pending = [];

    connections
      .filter(f => !f.isArchived)
      .sort((a, b) => {
        if (a.classification < b.classification) {
          return -1;
        }
        if (a.classification > b.classification) {
          return 1;
        }
        return 0;
      })
      .map(e => {
        let childNames = [];
        const allChild = connections
          .filter(f => !f.isArchived)
          .filter(h => h.dsUserId === e.dsUserId)
          .map(
            k =>
              !childNames.filter(f => f === k.childName)[0] &&
              k.childName &&
              childNames.push(k.childName)
          );
        !active.filter(f => f.email === e.email)[0] &&
          e.classification === "active" &&
          active.push({
            ...e,
            childNames,
            childNamesString: childNames.join(", ")
          });
      });

    connections
      .filter(f => !f.isArchived)
      .sort((a, b) => {
        if (a.classification < b.classification) {
          return -1;
        }
        if (a.classification > b.classification) {
          return 1;
        }
        return 0;
      })
      .map(e => {
        let childNames = [];
        const allChild = connections
          .filter(h => h.dsUserId === e.dsUserId)
          .map(
            k =>
              !childNames.filter(f => f === k.childName)[0] &&
              k.childName &&
              childNames.push(k.childName)
          );
        !active.filter(f => f.email === e.email)[0] &&
          !pending.filter(f => f.email === e.email)[0] &&
          pending.push({ ...e, childNames });
      });

    const compare_CaseAndAccentInsensitive = (a, b) =>
      a.displayName?.localeCompare(b.displayName, "en", {
        sensitivity: "base"
      });
    this.setState({
      data: {
        Active: active
          .filter(f => !f.isArchived)
          //.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
          .sort(compare_CaseAndAccentInsensitive),
        Pending: pending
          .filter(f => !f.isArchived)
          .sort(compare_CaseAndAccentInsensitive)
      }
    });

    // END TESING HERE

    this.setState({
      loadingComplete: true,
      newLeads: this.state.data.Pending,
      promotion: results3.data.data,
      hasPromotion: results3.data.data.length !== 0,
      promoPlan: promoPlan,
      toRemoveDays: excludedDays
    });
  }

  /**
   * Returned the days excluded to the componentDidMount function
   * @param {Array} days_of_week
   */
  getExcludedDays = days_of_week => {
    let excludedDays = [];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];

    const mappedDays = days.map(e => {
      if (e === days_of_week.filter(f => f === e)[0]) {
        return e;
      } else {
        return excludedDays.push({ name: e, value: e });
      }
    });

    return excludedDays;
  };

  parseProgramPlanFromStringToJSON = e => {
    return typeof e === "string" ? JSON.parse(e) : e;
  };

  isPlanPartOfPromotion = (promotion, plan) => {
    return promotion.plan_price_ticketId.indexOf(plan.id) !== -1;
  };

  addnewmodal = () => {
    const plans =
      this.props.program.plans ||
      this.props.program.tickets ||
      this.props.program.prices;
    console.log("this.state.newLeads", this.state.newLeads);
    const classIfInvalid = fieldName =>
      this.state.fieldIsInvalid[fieldName] ? "is-invalid" : "";
    return (
      this.state.addNew && (
        <div>
          <div>
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Contacts
            </label>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <div
              style={{
                height: "40px",
                width: "100%",
                fontSize: "0.93rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979"
              }}
            >
              <Select
                options={this.state.newLeads}
                // values={this.state.selectedLead}
                name={"selectedLead"}
                placeholder={"Select a Connection"}
                style={{
                  height: "40px",
                  width: "100%",
                  fontSize: "0.93rem",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: "400",
                  color: "#797979",
                  borderRadius: "4px"
                }}
                onChange={e => {
                  if (!e || !e[0]) {
                    this.setState({
                      selectedUser: null,
                      selectedLead: null
                    });
                  } else if (e[0].table !== "partner_leads") {
                    this.setState({
                      selectedUser: e.length === 0 ? null : e[0].dsUserId,
                      selectedLead: null
                    });
                  } else {
                    this.setState({
                      selectedLead: e.length === 0 ? null : e[0].id,
                      selectedUser: null
                    });
                  }
                }}
                labelField={"displayName"}
                valueField={"id"}
                closeOnSelect={true}
                searchable={true}
                keepSelectedInList={true}
                dropdownHandle={true}
                clearable={true}
                searchBy={"displayName"}
                loading={!this.state.loadingComplete}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262",
                marginTop: "22px"
              }}
            >
              Name <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
            </label>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <input
              name="name"
              id="displayName"
              type="text"
              /* className="form-control" */
              placeholder="Client's name..."
              className={[classIfInvalid("name")]}
              style={{
                height: "40px",
                width: "100%",
                fontSize: "0.93rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979",
                border: "1px solid #c5c5c5",
                borderRadius: "4px",
                paddingLeft: "14px"
              }}
              disabled={!!(this.state.selectedLead || this.state.selectedUser)}
              value={
                this.state.selectedLead !== null ||
                  this.state.selectedUser !== null
                  ? this.state.selectedLead !== null
                    ? this.state.newLeads.filter(
                      f => f.id === this.state.selectedLead
                    )[0]["displayName"]
                    : this.state.newLeads.filter(
                      f => f.dsUserId === this.state.selectedUser
                    )[0]["displayName"]
                  : this.state.name
              }
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
            />
          </div>
          <div>
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262",
                marginTop: "22px"
              }}
            >
              Email <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
            </label>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <input
              name="email"
              type="email"
              //className="form-control"
              className={[classIfInvalid("email")]}
              placeholder="Client's email..."
              style={{
                height: "40px",
                width: "100%",
                fontSize: "0.93rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979",
                border: "1px solid #c5c5c5",
                borderRadius: "4px",
                paddingLeft: "14px"
              }}
              disabled={!!(this.state.selectedLead || this.state.selectedUser)}
              value={
                this.state.selectedLead !== null ||
                  this.state.selectedUser !== null
                  ? this.state.selectedLead !== null
                    ? this.state.newLeads.filter(
                      f => f.id === this.state.selectedLead
                    )[0]["email"]
                    : this.state.newLeads.filter(
                      f => f.dsUserId === this.state.selectedUser
                    )[0]["email"]
                  : this.state.email
              }
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value.toLowerCase()
                });
              }}
            />
          </div>

          <div>
            <label
              htmlFor="displayName"
              style={{
                width: "32%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262",
                marginTop: "22px"
              }}
            >
              Pricing Plan
            </label>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <select
              id="weirdSelect"
              className={[classIfInvalid("selectedPlan")]}
              style={{
                height: "40px",
                width: "100%",
                fontSize: "0.93rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979",
                border: "1px solid #c5c5c5",
                borderRadius: "4px",
                paddingLeft: "14px"
              }}
              name="selectedPlan"
              onChange={e => {
                console.log("target.value", e.target.value);
                const isPromo = e.target.value.includes("Installment") ? false : e.target.value.match(/[a-z]/g);
                console.log("isPromo right after initialization", isPromo);
                const selectedOption = e.target.value.replace(/[a-z]/g, "");

                let ticket = null;
                let amount = null;
                let showWaiveDeposit = false;
                if (this.props.type === "events") {
                  const parsedPlan = plans
                    .map(e => JSON.parse(e))
                    .filter(e => e.id === parseInt(selectedOption.trim()))[0];

                  if (!parsedPlan) {
                    console.log(
                      "error in finding the plan in AddEnrollmentModal.jsx 607"
                    );
                  }
                  ticket =
                    parsedPlan.ticket_status === "Free"
                      ? "Free"
                      : parsedPlan.ticket_price;
                } else if (this.props.type === "memberships") {
                  const parsedPlan = plans
                    .map(e => JSON.parse(e))
                    .filter(e => e.id === parseInt(selectedOption.trim()))[0];

                  if (!parsedPlan) {
                    console.log(
                      "error in finding the plan in AddEnrollmentModal.jsx 621"
                    );
                  }
                  amount = parsedPlan.price_per_term;
                } else if (this.props.type === "parties") {
                  const parsedPlan = this.props.program.tickets
                  .map(e => JSON.parse(e))
                  .filter(e => e.id === parseInt(selectedOption.trim()))[0];
                
                  if (!parsedPlan) {
                    console.error("Error in finding the party plan for new client in AddEnrollmentModal.jsx");
                  }

                  ticket = parsedPlan.ticket_status === "Free"
                    ? "Free"
                    : parsedPlan.ticket_price;
                } else {
                  console.log(
                    "AddEnrollmentModal.jsx 1069: plans",
                    plans.map(e => JSON.parse(e))
                  );
                  console.log("AddEnrollmentModal.jsx 1070: selectedOption", {
                    selectedOption: selectedOption.trim()
                  });

                  const parsedPlan = plans
                    .map(e => JSON.parse(e))
                    .filter(e => e.id === parseInt(selectedOption.trim()))[0];

                  if (!parsedPlan) {
                    console.log(
                      "error in finding the plan in AddEnrollmentModal.jsx 475"
                    );
                  }

                  if (parsedPlan.type) {
                    if (!parsedPlan.installments && !parsedPlan.dropIns) {
                      amount = parseFloat(parsedPlan.total_price);
                    } else {
                      amount = parseFloat(parsedPlan.total_price);
                    }

                    if (e.target.value.includes("Installments")) {
                      amount = parseFloat(
                        parseFloat(parsedPlan).total_price *
                        (1 + parseInt(parsedPlan.installments_interest) / 100)
                      ).toFixed(2);
                    }
                    if (e.target.value.includes("Drop-In")) {
                      console.log(parsedPlan);
                      amount = parseFloat(
                        (parseFloat(parsedPlan.total_price) / parseInt(parsedPlan.dayCount)) *
                        (1 + parseInt(parsedPlan.dropin_interest) / 100)
                      ).toFixed(2);
                    }
                  } else {
                    // Recurring
                    amount = parsedPlan.tuition_rate;
                    showWaiveDeposit = !!parsedPlan.deposit;
                  }
                }
                console.log("isPromo", isPromo);
                console.log("AddEnrollmentModal.jsx 492: amount", amount);
                this.setState({
                  [e.target.name]: selectedOption.trim(),
                  amount: amount,
                  ticket: ticket,
                  isPromoPlanSelected: isPromo,
                  showWaiveDeposit: showWaiveDeposit
                });
              }}
            >
              <option hidden selected disabled value="">
                Please choose
              </option>
              {plans
                .filter(f => {
                  let plan = JSON.parse(f);
                  if (!plan.type && plan.active === false) {
                    return false;
                  }
                  return !JSON.parse(f).archived;
                })
                .map((ele, i) => {
                  const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                  if (!this.props.type) {
                    if (e.type) {
                      const options = [];
                      if (e.type === "Semester" || e.type === "Free") {
                        options.push({
                          value: e.id || i,
                          text:
                            e.active === false
                              ? `Semester - $${parseFloat(e.total_price).toFixed(
                                2
                              )} (Inactive)`
                              : `Semester - $${parseFloat(e.total_price).toFixed(
                                2
                              )}`,
                          type: ""
                        });
                        
                        if (e.installments) {
                          options.push({
                            value: e.id || i,
                            onClick: _ => this.setState({ installments: true }),
                            text: `Semester Installments - $${parseFloat(
                              e.total_price *
                              (1 + parseInt(e.installments_interest) / 100)
                            ).toFixed(2)}`,
                            type: ""
                          });
                        }
                      } else if (e.type === "Drop-In") {
                        options.push({
                          value: e.id || i,
                          onClick: _ => this.setState({ dropIns: true }),
                          text: `Drop-In - $${parseFloat(
                            e.total_price
                          ).toFixed(2)} per day.`,
                          type: ""
                        });
                      } else if (e.type === "Free Trial") {
                        options.push({
                          value: e.id || i,
                          text: "Free Trial",
                          type: ""
                        });
                      }
                      return options.map(option => {
                        if (option.onClick)
                          return (
                            <option
                              value={option.value + " " + option.type}
                              onClick={option.onClick}
                              key={i + option.type}
                            >
                              {option.text}
                            </option>
                          );
                        else
                          return (
                            <option
                              value={option.value + " " + option.type}
                              key={i + option.type}
                            >
                              {option.text}
                            </option>
                          );
                      });
                    } else {
                      return (
                        <option value={e.id || i} key={i}>
                          Recurring -{" "}
                          {`$${parseFloat(e.tuition_rate).toFixed(2)} - ${e.days_per_week
                            } days per week.`}{" "}
                          {e.active === false ? " (Inactive)" : ""}
                          {e.isHidden === true ? " (Hidden) ": ""}
                        </option>
                      );
                    }
                  } else if (this.props.type === "events") {
                    return (
                      <option value={e.id || i} key={i}>
                        {e.active === false
                          ? `${e.ticket_status} Ticket - $${e.ticket_price} (Inactive)`
                          : `${e.ticket_status} Ticket - $${e.ticket_price}`}
                      </option>
                    );
                  } else if (this.props.type === "memberships") {
                    return (
                      <option value={e.id || i} key={i}>
                        {e.active === false
                          ? `${e.membership_term}-Month Membership - $${e.price_per_term} (Inactive)`
                          : `${e.membership_term}-Month Membership - $${e.price_per_term}`}
                      </option>
                    );
                  } else if (this.props.type === "parties") {
                    return (
                      <option value={e.id || i} key={i}>
                        {e.active === false 
                          ? `${e.ticket_status} Ticket x ${e.ticket_quantity} - $${e.ticket_price} (Inactive)`
                          : `${e.ticket_status} Ticket x ${e.ticket_quantity} - $${e.ticket_price}`
                        }
                      </option>
                    );
                  }
                })}
              {/* Promo Plans */}
              {Object.keys(this.state.promoPlan).map((planId, i) => {
                const plan = this.state.promoPlan[planId];
                console.log("Plan in loop: ", plan);
                const promoOptions = plan.map(ele => {
                  const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                  if (!this.props.type) {
                    if (e.type) {
                      const options = [];
                      options.push({
                        value: `${e.id || i}promo`,
                        text: `Semester - $${parseFloat(e.total_price).toFixed(
                          2
                        )} | ${this.state.promotion[e.promoId].discount_type === PERCENT
                          ? `${this.state.promotion[e.promoId].discount_percent}% Off`
                          : ""
                          }
                      ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                            ? `$${Math.max(
                              0,
                              this.state.promotion[e.promoId].discount_fixed / 100
                            ).toFixed(2)} Off`
                            : ""
                          }`,
                        type: ""
                      });
                      /*
                      if (e.installments)
                        options.push({
                          value: `${e.id || i}promo`,
                          onClick: _ => this.setState({ installments: true }),
                          text: `Semester Installments - $${parseFloat(
                            e.total_price *
                            (1 + parseInt(e.installments_interest) / 100)
                          ).toFixed(2)} | ${this.state.promotion[e.promoId].discount_type === PERCENT
                            ? `${this.state.promotion[e.promoId].discount_percent}% Off`
                            : ""
                            }
                          ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                              ? `$${Math.max(
                                0,
                                this.state.promotion[e.promoId].discount_fixed / 100
                              ).toFixed(2)} Off`
                              : ""
                            }`,
                          type: "Installments"
                        });
                      */
                      if (e.dropIns)
                        options.push({
                          value: `${e.id || i}promo`,
                          onClick: _ => this.setState({ dropIns: true }),
                          text: `Semester Drop-In - $${parseFloat(
                            e.total_price
                          ).toFixed(2)} per day. | ${this.state.promotion[e.promoId].discount_type === PERCENT
                            ? `${this.state.promotion[e.promoId].discount_percent}% Off`
                            : ""
                            }
                          ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                              ? `$${Math.max(
                                0,
                                this.state.promotion[e.promoId].discount_fixed / 100
                              ).toFixed(2)} Off`
                              : ""
                            }`,
                          type: "Drop-In"
                        });
                      return options.map(option => {
                        if (option.onClick)
                          return (
                            <option
                              value={option.value + " " + option.type}
                              onClick={option.onClick}
                              key={i + option.type}
                            >
                              {option.text}
                            </option>
                          );
                        else
                          return (
                            <option
                              value={option.value + " " + option.type}
                              key={i + option.type}
                            >
                              {option.text}
                            </option>
                          );
                      });
                    } else {
                      return (
                        <option value={`${e.id || i}promo`} key={i}>
                          Recurring -{" "}
                          {`$${parseFloat(e.tuition_rate).toFixed(2)} - ${e.days_per_week
                            } days per week. | ${this.state.promotion[e.promoId].discount_type === PERCENT
                              ? `${this.state.promotion[e.promoId].discount_percent}% Off`
                              : ""
                            }
                          ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                              ? `$${Math.max(
                                0,
                                this.state.promotion[e.promoId].discount_fixed / 100
                              ).toFixed(2)} Off`
                              : ""
                            }`}
                        </option>
                      );
                    }
                  } else if (this.props.type === "events") {
                    return (
                      <option value={`${e.id || i}promo`} key={i}>
                        {`${e.ticket_status} Ticket - $${e.ticket_price} |
                         ${this.state.promotion[e.promoId].discount_type === PERCENT
                          ? `${this.state.promotion[e.promoId].discount_percent} % Off`
                          : ""
                          }
                        ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                            ? `$${Math.max(
                              0,
                              this.state.promotion[e.promoId].discount_fixed / 100
                            ).toFixed(2)} Off`
                            : ""
                          }`}
                      </option>
                    );
                  } else if (this.props.type === "memberships") {
                    return (
                      <option value={`${e.id || i}promo`} key={i}>
                        {`${e.membership_term}-Month Membership - $${e.price_per_term} |
                          ${this.state.promotion[e.promoId].discount_type === PERCENT
                            ? `${this.state.promtion[e.promoId].discount_percent}% Off`
                            : ""
                          }
                        ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                            ? `$${Math.max(
                              0,
                              this.state.promotion[e.promoId].discount_fixed / 100
                            ).toFixed(2)} Off`
                            : ""
                          }`}
                      </option>
                    );
                  } else if (this.props.type === "parties") {
                    return (
                      <option value={`${e.id || i}promo`} key={{i}}>
                        {`${e.ticket_status} Ticket x ${e.ticket_quantity} - $${e.ticket_price} |
                          ${this.state.promotion[e.promoId].discount_type === PERCENT 
                              ? `${this.state.promotion[e.promoId].discount_percent}% Off`
                              : ""
                            }
                          ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                              ? `$${Math.max(0, this.state.promotion[e.promoId].discount_fixed / 100).toFixed(2)} Off`
                              : ""
                          }`}
                      </option>
                    );
                  }
                }
                )
                return promoOptions;
              })
              };
            </select>
          </div>
          {(this.props.program.program_details === "Lessons / Sessions" ||
            this.props.program.program_details === "Classes") && (
              <>
                <div>
                  <label
                    htmlFor="singleSessionQuantity"
                    style={{
                      width: "32%",
                      textAlign: "left",
                      fontSize: "1.15rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "500",
                      color: "#626262",
                      marginTop: "22px"
                    }}
                  >
                    Quantity <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
                  </label>
                </div>
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <select
                    id="weirdSelect"
                    style={{
                      height: "40px",
                      width: "100%",
                      fontSize: "0.93rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "400",
                      color: "#797979",
                      border: "1px solid #c5c5c5",
                      borderRadius: "4px",
                      paddingLeft: "14px"
                    }}
                    name="singleSessionQuantity"
                    onChange={e => {
                      this.setState({
                        singleSessionQuantity: parseInt(e.target.value)
                      });
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </>
            )}

          <div>
            <label
              htmlFor="displayName"
              style={{
                width: "50%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262",
                marginTop: "22px"
              }}
            >
              Message (optional)
            </label>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <textarea
              name="message"
              placeholder="Enter a message..."
              style={{
                height: "100px",
                width: "100%",
                fontSize: "0.93rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979",
                border: "1px solid #c5c5c5",
                borderRadius: "4px",
                paddingLeft: "14px",
                paddingTop: "10px"
              }}
              value={this.state.message}
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
            />
          </div>
          <div>
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262",
                marginTop: "22px"
              }}
            >
              Advanced
            </label>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <div style={{ marginLeft: "0px" }}>
              <Switch
                checked={this.state.isAdvanced}
                onChange={e =>
                  this.setState({
                    isAdvanced: e.target.checked
                  })
                }
                color="primary"
              />
            </div>
          </div>

          {this.state.isAdvanced && (
            <>
              <div>
                <label
                  htmlFor="displayName"
                  style={{
                    width: "49%",
                    textAlign: "left",
                    fontSize: "1.15rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "500",
                    color: "#626262",
                    marginTop: "22px"
                  }}
                >
                  Skip Form / Application
                </label>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <div style={{ marginLeft: "0px" }}>
                  <Switch
                    checked={this.state.isSkipForm}
                    onChange={e =>
                      this.setState({
                        isSkipForm: e.target.checked
                      })
                    }
                    color="primary"
                  />
                </div>
              </div>
              {(this.props.program.program_details === "Lessons / Sessions" ||
                this.props.program.program_details === "Classes") && (
                  <div>
                    <div>
                      <label
                        htmlFor="displayName"
                        style={{
                          width: "49%",
                          textAlign: "left",
                          fontSize: "1.15rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "500",
                          color: "#626262",
                          marginTop: "22px"
                        }}
                      >
                        Select Session Dates
                      </label>
                    </div>
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: 0
                      }}
                    >
                      <div style={{ marginLeft: "0px" }}>
                        <p
                          className="field-component-small-text"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {this.state.singleSessionSelectedDates?.map(
                            (date, index) => {
                              return (
                                <span key={index}>
                                  {this.props.program.program_details ===
                                    "Classes"
                                    ? format(new Date(date), "MMMM d, yyyy")
                                    : format(
                                      new Date(date),
                                      "MMMM d, yyyy h:mm aa"
                                    )}
                                  <br />
                                </span>
                              );
                            }
                          )}
                        </p>
                        <button
                          className="btn btn-primary"
                          name="Select Dates"
                          onClick={_ =>
                            this.setState({
                              isSelectDates: true
                            })
                          }
                          disabled={
                            !this.getPlan()?.isTimeEnabled &&
                            !this.getPlan()?.isDateSelectionEnabled
                          }
                        >
                          Open Selections
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              {this.state.showWaiveDeposit && (
                <div>
                  <div>
                    <label
                      htmlFor="displayName"
                      style={{
                        width: "49%",
                        textAlign: "left",
                        fontSize: "1.15rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262",
                        marginTop: "22px"
                      }}
                    >
                      Waive Deposit
                    </label>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <div style={{ marginLeft: "0px" }}>
                      <Switch
                        checked={this.state.isWaiveDeposit}
                        onChange={e =>
                          this.setState({
                            isWaiveDeposit: e.target.checked
                          })
                        }
                        color="primary"
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.props.program.number_of_weeks === 0 && (
                <div>
                  <div>
                    <label
                      htmlFor="displayName"
                      style={{
                        width: "49%",
                        textAlign: "left",
                        fontSize: "1.15rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262",
                        marginTop: "22px"
                      }}
                    >
                      Enable Start
                    </label>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <div style={{ marginLeft: "0px" }}>
                      <Switch
                        checked={this.state.isStartSet}
                        onChange={e =>
                          this.setState({
                            isStartSet: e.target.checked
                          })
                        }
                        color="primary"
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.state.isStartSet &&
                JSON.parse(
                  this.props.program.plans.filter(
                    e => JSON.parse(e).id == this.state.selectedPlan
                  )[0]
                ).isStartDateEnabled && (
                  <div>
                    <div>
                      <label
                        htmlFor="displayName"
                        style={{
                          width: "49%",
                          textAlign: "left",
                          fontSize: "1.15rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "500",
                          color: "#626262",
                          marginTop: "22px"
                        }}
                      >
                        Select your start date
                      </label>
                    </div>
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: 0
                      }}
                    >
                      <div style={{ fontSize: "14px" }}>
                        <DateSel
                          className="dob-border date-picker-padding"
                          showYearDropdown
                          showMonthDropdown
                          dateFormat="MMMM d, yyyy"
                          name="startDate"
                          selected={this.state.displayDate}
                          onChange={e => {
                            const currentTime = moment().format("h:mm A");
                            const stringDate = moment(e).format("YYYY-MM-DD");

                            // Creates a dateTime combining the currentTime
                            // and selected Date.
                            const date = moment(
                              stringDate + " " + currentTime,
                              "YYYY-MM-DD h:mm A"
                            );
                            // Dates set to ISO so that Date() constructor will be able to understand it.
                            this.setState({
                              startDate: date.toISOString(),
                              displayDate: e
                            });
                          }}
                          minDate={addDays(new Date(), 1)}
                        />
                      </div>
                    </div>
                  </div>
                )}

              {this.state.isStartSet && (
                <div>
                  <div>
                    <label
                      htmlFor="displayName"
                      style={{
                        width: "32%",
                        textAlign: "left",
                        fontSize: "1.15rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262",
                        marginTop: "22px"
                      }}
                    >
                      Select Days
                    </label>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "baseline",
                      marginBottom: 0
                    }}
                  >
                    <div
                      className="row"
                      style={{
                        height: "40px",
                        width: "100%"
                      }}
                    >
                      <div
                        className="select col-12"
                        style={{
                          height: "40px",
                          width: "100%"
                        }}
                      >
                        <formTypes.DaysDropdownSelect
                          name="recurringCustomerSelectedDays"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "0.93rem",
                            fontFamily: "futura-pt, sans-serif",
                            fontWeight: "400",
                            color: "#797979",
                            border: "1px solid #c5c5c5",
                            borderRadius: "4px",
                            paddingLeft: "14px"
                          }}
                          value={this.state.recurringCustomerSelectedDays}
                          toremove={this.state.toRemoveDays}
                          onChange={(e, v) => {
                            this.setState({
                              [e]: v
                            });
                          }}
                          className="w-100"
                          range={"Monday - Sunday"}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: "5px",
                          marginLeft: "0px",
                          fontFamily: "futura-pt, sans-serif"
                        }}
                      >
                        <p>
                          {this.state.recurringCustomerSelectedDays.length >
                            JSON.parse(
                              this.props.program.plans.filter(
                                e => JSON.parse(e).id == this.state.selectedPlan
                              )[0]
                            ).days_per_week ? (
                            <span
                              style={{
                                width: "49%",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontFamily: "futura-pt, sans-serif",
                                fontWeight: "500",
                                color: "red",
                                marginLeft: "10px"
                              }}
                            >
                              Selected days cannot exceed number of plan days
                            </span>
                          ) : (
                            <></>
                          )}
                          {this.state.recurringCustomerSelectedDays.length <
                            JSON.parse(
                              this.props.program.plans.filter(
                                e => JSON.parse(e).id == this.state.selectedPlan
                              )[0]
                            ).days_per_week ? (
                            <span
                              style={{
                                fontSize: "13px",
                                fontFamily: "futura-pt, sans-serif",
                                marginLeft: "10px"
                              }}
                            >
                              {JSON.parse(
                                this.props.program.plans.filter(
                                  e =>
                                    JSON.parse(e).id == this.state.selectedPlan
                                )[0]
                              ).days_per_week -
                                this.state.recurringCustomerSelectedDays
                                  .length}{" "}
                              remaining
                            </span>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        /* </form> */
      )
    );
  };

  existingclientmodal = () => {
    console.log("this.state.data.Active", this.state.data.Active);
    const plans =
      this.props.program.plans ||
      this.props.program.tickets ||
      this.props.program.prices;
    const classIfInvalid = fieldName =>
      this.state.fieldIsInvalid[fieldName] ? "is-invalid" : "";
    return (
      this.state.addExisting && (
        /* <form onSubmit={e => this.submitForm(e)}></form> */
        <div>
          <div>
            <label
              htmlFor="displayName"
              style={{
                width: "32%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              Select Client <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
            </label>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <div
              style={{
                height: "40px",
                width: "100%",
                fontSize: "0.93rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979"
              }}
            >
              <Select
                options={this.state.data.Active}
                name={"selectedUser"}
                placeholder={"Please Select"}
                className={[classIfInvalid("selectedUser")]}
                style={{
                  height: "40px",
                  width: "100%",
                  fontSize: "0.93rem",
                  fontFamily: "futura-pt, sans-serif",
                  fontWeight: "400",
                  color: "#797979",
                  borderRadius: "4px"
                }}
                onChange={e => {
                  this.setState({
                    selectedUser: e.length === 0 ? null : e[0].dsUserId,
                    selectedChild: null
                  });

                  if (e.length > 0 && this.props.program.addChild) {
                    this.getUserChildren(e[0].dsUserId);
                  }
                }}
                labelField={"displayName"}
                valueField={"dsUserId"}
                closeOnSelect={true}
                searchable={true}
                keepSelectedInList={true}
                dropdownHandle={true}
                clearable={true}
                searchBy={"displayName"}
                loading={!this.state.loadingComplete}
              />
            </div>
          </div>
          {this.state.autoRegister && this.props.program.addChild && (
            <>
              <div>
                <label
                  htmlFor="displayChildName"
                  style={{
                    width: "32%",
                    textAlign: "left",
                    fontSize: "1.15rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "500",
                    color: "#626262",
                    marginTop: "22px"
                  }}
                >
                  Select Child{" "}
                  <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
                </label>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <div
                  style={{
                    height: "40px",
                    width: "100%",
                    fontSize: "0.93rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "400",
                    color: "#797979"
                  }}
                >
                  <Select
                    options={this.state.child}
                    name={"selectedChild"}
                    placeholder={"Please Select"}
                    className={[classIfInvalid("selectedChildUser")]}
                    style={{
                      height: "40px",
                      width: "100%",
                      fontSize: "0.93rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "400",
                      color: "#797979",
                      borderRadius: "4px"
                    }}
                    onChange={e => {
                      this.setState({
                        selectedChild: e.length === 0 ? null : e[0].id
                      });
                    }}
                    labelField={"fullName"}
                    valueField={"id"}
                    closeOnSelect={true}
                    searchable={true}
                    keepSelectedInList={true}
                    dropdownHandle={true}
                    clearable={true}
                    searchBy={"displayChildName"}
                    loading={!this.state.loadingCompleteChild}
                  />
                </div>
              </div>
            </>
          )}
          <div>
            <label
              htmlFor="displayName"
              style={{
                width: "32%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262",
                marginTop: "22px"
              }}
            >
              Pricing Plan
            </label>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <select
              id="weirdSelect"
              className={[classIfInvalid("selectedPlan")]}
              style={{
                height: "40px",
                width: "100%",
                fontSize: "0.93rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979",
                border: "1px solid #c5c5c5",
                borderRadius: "4px",
                paddingLeft: "14px"
              }}
              name="selectedPlan"
              onChange={e => {
                const isPromo = e.target.value.includes("Installment") ? false : e.target.value.match(/[a-z]/g);
                const selectedOption = e.target.value
                  ? e.target.value.replace(/[a-z]/g, "")
                  : null;

                let ticket = null;
                let amount = null;
                let showWaiveDeposit = false;
                if (this.state.autoRegister) {
                } else if (this.props.type === "events") {
                  const parsedPlan = this.props.program.tickets
                    .map(e => JSON.parse(e))
                    .filter(e => e.id === parseInt(selectedOption.trim()))[0];

                  if (!parsedPlan) {
                    console.log(
                      "error in finding the plan in AddEnrollmentModal.jsx 1102"
                    );
                  }

                  ticket =
                    parsedPlan.ticket_status === "Free"
                      ? "Free"
                      : parsedPlan.ticket_price;
                } else if (this.props.type === "memberships") {
                  const parsedPlan = plans
                    .map(e => JSON.parse(e))
                    .filter(e => e.id === parseInt(selectedOption.trim()))[0];

                  if (!parsedPlan) {
                    console.log(
                      "error in finding the plan in AddEnrollmentModal.jsx 621"
                    );
                  }
                  amount = parsedPlan.price_per_term;
                } else if (this.props.type === "parties") {
                  const parsedPlan = this.props.program.tickets
                    .map(e => JSON.parse(e))
                    .filter(e => e.id === parseInt(selectedOption.trim()))[0];
                  
                  if (!parsedPlan) {
                    console.error("Error in finding the party plan in AddEnrollmentModal.jsx");
                  }

                  ticket = parsedPlan.ticket_status === "Free"
                    ? "Free"
                    : parsedPlan.ticket_price;
                } else {
                  console.log(
                    "AddEnrollmentModal.jsx 1069: plans",
                    plans.map(e => JSON.parse(e))
                  );
                  console.log("AddEnrollmentModal.jsx 1070: selectedOption", {
                    selectedOption: selectedOption.trim()
                  });

                  const parsedPlan = plans
                    .map(e => JSON.parse(e))
                    .filter(e => e.id === parseInt(selectedOption.trim()))[0];

                  if (!parsedPlan) {
                    console.log(
                      "error in finding the plan in AddEnrollmentModal.jsx 1133"
                    );
                  }

                  if (parsedPlan.type) {
                    amount = parseFloat(parsedPlan.total_price);

                    if (parsedPlan.installments && e.target.value.includes("Installments")) {
                      amount = parseFloat(
                        parseFloat(parsedPlan.total_price) *
                        (1 + parseInt(parsedPlan.installments_interest) / 100)
                      ).toFixed(2);
                    }
                    if (parsedPlan.dropIns && e.target.value.includes("Drop-In")) {
                      amount = parseFloat(
                        (parseFloat(parsedPlan.total_price) / parseInt(parsedPlan.dayCount)) *
                        (1 + parseInt(parsedPlan.dropin_interest) / 100)
                      ).toFixed(2);
                    }
                  } else {
                    // Recurring
                    console.log("parsedPlan", parsedPlan);
                    amount = parsedPlan.tuition_rate;
                    showWaiveDeposit = !!parsedPlan.deposit;
                  }
                }

                console.log("AddEnrollmentModal.jsx 1096: amount", amount);
                if (this.state.autoRegister) {
                  this.setState({
                    [e.target.name]: "Auto Register",
                    amount: 0,
                    ticket: ticket,
                    isPromoPlanSelected: isPromo,
                    showWaiveDeposit: showWaiveDeposit
                  });
                } else {
                  this.setState({
                    [e.target.name]: selectedOption.trim(),
                    amount: amount,
                    ticket: ticket,
                    isPromoPlanSelected: isPromo,
                    showWaiveDeposit: showWaiveDeposit
                  });
                }
              }}
            >
              <option hidden selected disabled value="">
                Please choose
              </option>
              {!this.state.autoRegister &&
                plans
                .filter(f => {
                  let plan = JSON.parse(f);
                  if (!plan.type && plan.active === false) {
                    return false;
                  }
                  return !JSON.parse(f).archived;
                })
                .map((ele, i) => {
                    const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                    if (!this.props.type) {
                      let options = [];
                      if (e.type === "Single Sessions") {
                        options.push({
                          value: e.id || i,
                          text:
                            e.active === false
                              ? `Single Sessions - $${parseFloat(
                                e.total_price
                              ).toFixed(2)} (Inactive)`
                              : `Single Sessions - $${parseFloat(
                                e.total_price
                              ).toFixed(2)}`,
                          type: ""
                        });
                      } else if (e.type === "Drop-In") {
                        options.push({
                          value: e.id || i,
                          onClick: _ => this.setState({ dropIns: true }),
                          text: `Drop-In - $${parseFloat(
                            e.total_price
                          ).toFixed(2)} per day.`,
                          type: ""
                        });
                      } else if (e.type === "Free Trial") {
                        options.push({
                          value: e.id || i,
                          text: "Free Trial",
                          type: ""
                        });
                      } else if (e.type) {
                        options.push({
                          value: e.id || i,
                          text:
                            e.active === false
                              ? `Semester - $${parseFloat(
                                e.total_price
                              ).toFixed(2)} (Inactive)`
                              : `Semester - $${parseFloat(
                                e.total_price
                              ).toFixed(2)}`,
                          type: ""
                        });
                        if (e.installments)
                          options.push({
                            value: e.id || i,
                            onClick: _ => this.setState({ installments: true }),
                            text: `Semester Installments - $${parseFloat(
                              e.total_price *
                              (1 + parseInt(e.installments_interest) / 100)
                            ).toFixed(2)}`,
                            type: ""
                          });
                      } else {
                        return (
                          <option value={e.id || i} key={i}>
                            Recurring -{" "}
                            {`$${parseFloat(e.tuition_rate).toFixed(2)} - ${e.days_per_week
                              } days per week.`}{" "}
                            {e.active === false ? " (Inactive)" : ""}
                            {e.isHidden === true? "(Hidden)" : ""}
                          </option>
                        );
                      }
                      return options.map(option => {
                        if (option.onClick)
                          return (
                            <option
                              value={option.value + " " + option.type}
                              onClick={option.onClick}
                              key={i + option.type}
                            >
                              {option.text}
                            </option>
                          );
                        else
                          return (
                            <option
                              value={option.value + " " + option.type}
                              key={i + option.type}
                            >
                              {option.text}
                            </option>
                          );
                      });
                    } else if (this.props.type === "events") {
                      return (
                        <option value={e.id || i} key={i}>
                          {e.active === false
                            ? `${e.ticket_status} Ticket - $${e.ticket_price} (Inactive)`
                            : `${e.ticket_status} Ticket - $${e.ticket_price}`}
                        </option>
                      );
                    } else if (this.props.type === "memberships") {
                      return (
                        <option value={e.id || i} key={i}>
                          {e.active === false
                            ? `${e.membership_term}-Month Membership - $${e.price_per_term} (Inactive)`
                            : `${e.membership_term}-Month Membership - $${e.price_per_term}`}
                        </option>
                      );
                    } else if (this.props.type === "parties") {
                      return (
                        <option value={e.id || i} key={i}>
                          {e.active === false
                            ? `${e.ticket_status} Ticket x ${e.ticket_quantity} - $${e.ticket_price} (Inactive)`
                            : `${e.ticket_status} Ticket x ${e.ticket_quantity} - $${e.ticket_price}`
                          }
                        </option>
                      );
                    }
                  })}
              {/* Promo Plans */}
              {!this.state.autoRegister &&
                Object.keys(this.state.promoPlan).map((planId, i) => {
                  const plan = this.state.promoPlan[planId];
                  const promoOptions = plan.map(ele => {
                    const e = typeof ele === "string" ? JSON.parse(ele) : ele;
                    if (!this.props.type) {
                      if (e.type) {
                        const options = [];
                        options.push({
                          value: `${e.id || i}promo`,
                          text: `Semester - $${parseFloat(e.total_price).toFixed(
                            2
                          )} | ${this.state.promotion[e.promoId].discount_type === PERCENT
                            ? `${this.state.promotion[e.promoId].discount_percent}% Off`
                            : ""
                            }
                        ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                              ? `$${Math.max(
                                0,
                                this.state.promotion[e.promoId].discount_fixed / 100
                              ).toFixed(2)} Off`
                              : ""
                            }`,
                          type: ""
                        });
                        /*
                        if (e.installments)
                          options.push({
                            value: `${e.id || i}promo`,
                            onClick: _ => this.setState({ installments: true }),
                            text: `Semester Installments - $${parseFloat(
                              e.total_price *
                              (1 + parseInt(e.installments_interest) / 100)
                            ).toFixed(2)} | ${this.state.promotion[e.promoId].discount_type === PERCENT
                              ? `${this.state.promotion[e.promoId].discount_percent}% Off`
                              : ""
                              }
                          ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                                ? `$${Math.max(
                                  0,
                                  this.state.promotion[e.promoId].discount_fixed / 100
                                ).toFixed(2)} Off`
                                : ""
                              }`,
                            type: "Installments"
                          });
                        */
                        if (e.dropIns)
                          options.push({
                            value: `${e.id || i}promo`,
                            onClick: _ => this.setState({ dropIns: true }),
                            text: `Semester Drop-In - $${parseFloat(
                              (e.total_price / e.dayCount) *
                              (1 + parseInt(e.dropin_interest) / 100)
                            ).toFixed(2)} per day. | ${this.state.promotion[e.promoId].discount_type === PERCENT
                              ? `${this.state.promotion[e.promoId].discount_percent}% Off`
                              : ""
                              }
                          ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                                ? `$${Math.max(
                                  0,
                                  this.state.promotion[e.promoId].discount_fixed / 100
                                ).toFixed(2)} Off`
                                : ""
                              }`,
                            type: "Drop-In"
                          });
                        return options.map(option => {
                          if (option.onClick)
                            return (
                              <option
                                value={option.value + " " + option.type}
                                onClick={option.onClick}
                                key={i + option.type}
                              >
                                {option.text}
                              </option>
                            );
                          else
                            return (
                              <option
                                value={option.value + " " + option.type}
                                key={i + option.type}
                              >
                                {option.text}
                              </option>
                            );
                        });
                      } else {
                        return (
                          <option value={`${e.id || i}promo`} key={i}>
                            Recurring -{" "}
                            {`$${parseFloat(e.tuition_rate).toFixed(2)} - ${e.days_per_week
                              } days per week. | ${this.state.promotion[e.promoId].discount_type === PERCENT
                                ? `${this.state.promotion[e.promoId].discount_percent}% Off`
                                : ""
                              }
                          ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                                ? `$${Math.max(
                                  0,
                                  this.state.promotion[e.promoId].discount_fixed / 100
                                ).toFixed(2)} Off`
                                : ""
                              }`}
                          </option>
                        );
                      }
                    } else if (this.props.type === "events") {
                      return (
                        <option value={`${e.id || i}promo`} key={i}>
                          {`${e.ticket_status} Ticket - $${e.ticket_price} | ${this.state.promotion[e.promoId].discount_type === PERCENT
                            ? `${this.state.promotion[i].discount_percent}% Off`
                            : ""
                            }
                        ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                              ? `$${Math.max(
                                0,
                                this.state.promotion[e.promoId].discount_fixed / 100
                              ).toFixed(2)} Off`
                              : ""
                            }`}
                        </option>
                      );
                    } else if (this.props.type === "memberships") {
                      return (
                        <option value={`${e.id || i}promo`} key={i}>
                          {`${e.membership_term}-Month Membership - $${e.price_per_term
                            } | ${this.state.promotion[e.promoId].discount_type === PERCENT
                              ? `${this.state.promotion[e.promoId].discount_percent}% Off`
                              : ""
                            }
                        ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                              ? `$${Math.max(
                                0,
                                this.state.promotion[e.promoId].discount_fixed / 100
                              ).toFixed(2)} Off`
                              : ""
                            }`}
                        </option>
                      );
                    } else if (this.props.type === "parties") {
                      return (
                        <option value={`${e.id || i}promo`} key={{i}}>
                          {`${e.ticket_status} Ticket x ${e.ticket_quantity} - $${e.ticket_price} |
                            ${this.state.promotion[e.promoId].discount_type === PERCENT 
                                ? `${this.state.promotion[e.promoId].discount_percent}% Off`
                                : ""
                              }
                            ${this.state.promotion[e.promoId].discount_type === FIXED_AMOUNT
                                ? `$${Math.max(0, this.state.promotion[e.promoId].discount_fixed / 100).toFixed(2)} Off`
                                : ""
                            }`}
                        </option>
                      );
                    }
                  });
                  return promoOptions;
                })}
              {this.state.autoRegister && (
                <option value={"auto-register"}>Instant Add - $0 Free</option>
              )}
            </select>
          </div>

          {(this.props.program.program_details === "Lessons / Sessions" ||
            this.props.program.program_details === "Classes") && (
              <>
                <div>
                  <label
                    htmlFor="singleSessionQuantity"
                    style={{
                      width: "32%",
                      textAlign: "left",
                      fontSize: "1.15rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "500",
                      color: "#626262",
                      marginTop: "22px"
                    }}
                  >
                    Quantity <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
                  </label>
                </div>
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <select
                    id="weirdSelect"
                    style={{
                      height: "40px",
                      width: "100%",
                      fontSize: "0.93rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "400",
                      color: "#797979",
                      border: "1px solid #c5c5c5",
                      borderRadius: "4px",
                      paddingLeft: "14px"
                    }}
                    name="singleSessionQuantity"
                    onChange={e => {
                      this.setState({
                        singleSessionQuantity: parseInt(e.target.value)
                      });
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </>
            )}
          {!this.state.autoRegister && (
            <>
              <div>
                <label
                  htmlFor="message"
                  style={{
                    width: "50%",
                    textAlign: "left",
                    fontSize: "1.15rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "500",
                    color: "#626262",
                    marginTop: "22px"
                  }}
                >
                  Message (optional)
                </label>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <textarea
                  name="message"
                  placeholder="Enter a message..."
                  style={{
                    height: "100px",
                    width: "100%",
                    fontSize: "0.93rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "400",
                    color: "#797979",
                    border: "1px solid #c5c5c5",
                    borderRadius: "4px",
                    paddingLeft: "14px",
                    paddingTop: "10px"
                  }}
                  value={this.state.message}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
              </div>
            </>
          )}
          <div>
            <label
              htmlFor="displayName"
              style={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.15rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262",
                marginTop: "22px"
              }}
            >
              Advanced
            </label>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <div style={{ marginLeft: "0px" }}>
              <Switch
                checked={this.state.isAdvanced}
                onChange={e =>
                  this.setState({
                    isAdvanced: e.target.checked
                  })
                }
                color="primary"
              />
            </div>
          </div>

          {this.state.isAdvanced && (
            <>
              <div>
                <label
                  htmlFor="displayName"
                  style={{
                    width: "49%",
                    textAlign: "left",
                    fontSize: "1.15rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "500",
                    color: "#626262",
                    marginTop: "22px"
                  }}
                >
                  Skip Form / Application
                </label>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <div style={{ marginLeft: "0px" }}>
                  <Switch
                    checked={this.state.isSkipForm || this.state.autoRegister}
                    disabled={this.state.autoRegister}
                    onChange={e =>
                      this.setState({
                        isSkipForm: e.target.checked
                      })
                    }
                    color="primary"
                  />
                </div>
              </div>

              {this.state.showWaiveDeposit && (
                <div>
                  <div>
                    <label
                      htmlFor="displayName"
                      style={{
                        width: "49%",
                        textAlign: "left",
                        fontSize: "1.15rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262",
                        marginTop: "22px"
                      }}
                    >
                      Waive Deposit
                    </label>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <div style={{ marginLeft: "0px" }}>
                      <Switch
                        checked={this.state.isWaiveDeposit}
                        onChange={e =>
                          this.setState({
                            isWaiveDeposit: e.target.checked
                          })
                        }
                        color="primary"
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.props.program.number_of_weeks === 0 && (
                <div>
                  <div>
                    <label
                      htmlFor="displayName"
                      style={{
                        width: "25%",
                        textAlign: "left",
                        fontSize: "1.15rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262",
                        marginTop: "22px"
                      }}
                    >
                      Enable Start
                    </label>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                  >
                    <div style={{ marginLeft: "0px" }}>
                      <Switch
                        checked={this.state.isStartSet}
                        onChange={e =>
                          this.setState({
                            isStartSet: e.target.checked
                          })
                        }
                        color="primary"
                      />
                    </div>
                  </div>
                </div>
              )}

              {(this.props.program.program_details === "Lessons / Sessions" ||
                this.props.program.program_details === "Classes") && (
                  <div>
                    <div>
                      <label
                        htmlFor="displayName"
                        style={{
                          width: "49%",
                          textAlign: "left",
                          fontSize: "1.15rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "500",
                          color: "#626262",
                          marginTop: "22px"
                        }}
                      >
                        Select Session Dates
                      </label>
                    </div>
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: 0
                      }}
                    >
                      <div style={{ marginLeft: "0px" }}>
                        <p
                          className="field-component-small-text"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {this.state.singleSessionSelectedDates?.map(
                            (date, index) => {
                              return (
                                <span key={index}>
                                  {this.props.program.program_details ===
                                    "Classes"
                                    ? format(new Date(date), "MMMM d, yyyy")
                                    : format(
                                      new Date(date),
                                      "MMMM d, yyyy h:mm aa"
                                    )}
                                  <br />
                                </span>
                              );
                            }
                          )}
                        </p>
                        <button
                          className="btn btn-primary"
                          name="Select Dates"
                          onClick={_ =>
                            this.setState({
                              isSelectDates: true
                            })
                          }
                          disabled={
                            !this.getPlan()?.isTimeEnabled &&
                            !this.getPlan()?.isDateSelectionEnabled
                          }
                        >
                          Open Selections
                        </button>
                      </div>
                    </div>
                  </div>
                )}

              {this.state.isStartSet &&
                JSON.parse(
                  this.props.program.plans.filter(
                    e => JSON.parse(e).id == this.state.selectedPlan
                  )[0]
                ).isStartDateEnabled && (
                  <div>
                    <div>
                      <label
                        htmlFor="displayName"
                        style={{
                          width: "32%",
                          textAlign: "left",
                          fontSize: "1.15rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "500",
                          color: "#626262"
                        }}
                      >
                        Select your start date
                      </label>
                    </div>
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: 0
                      }}
                    >
                      <div style={{ fontSize: "14px" }}>
                        <DateSel
                          className="dob-border date-picker-padding"
                          showYearDropdown
                          showMonthDropdown
                          dateFormat="MMMM d, yyyy"
                          name="startDate"
                          selected={this.state.displayDate}
                          onChange={e => {
                            const currentTime = moment().format("h:mm A");
                            const stringDate = moment(e).format("YYYY-MM-DD");

                            // Creates a dateTime combining the currentTime
                            // and selected Date.
                            const date = moment(
                              stringDate + " " + currentTime,
                              "YYYY-MM-DD h:mm A"
                            );
                            // Dates set to ISO so that Date() constructor will be able to understand it.
                            this.setState({
                              startDate: date.toISOString(),
                              displayDate: e
                            });
                          }}
                          minDate={addDays(new Date(), 1)}
                        />
                      </div>
                    </div>
                  </div>
                )}

              {this.state.isStartSet && (
                <div>
                  <div>
                    <label
                      htmlFor="displayName"
                      style={{
                        width: "32%",
                        textAlign: "left",
                        fontSize: "1.15rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262",
                        marginTop: "22px"
                      }}
                    >
                      Select Days
                    </label>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "baseline",
                      marginBottom: 0
                    }}
                  >
                    <div
                      className="row"
                      style={{
                        height: "40px",
                        width: "100%"
                      }}
                    >
                      <div
                        className="select col-12"
                        style={{
                          height: "40px",
                          width: "100%"
                        }}
                      >
                        <formTypes.DaysDropdownSelect
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "0.93rem",
                            fontFamily: "futura-pt, sans-serif",
                            fontWeight: "400",
                            color: "#797979",
                            border: "1px solid #c5c5c5",
                            borderRadius: "4px",
                            paddingLeft: "14px"
                          }}
                          name="recurringCustomerSelectedDays"
                          value={this.state.recurringCustomerSelectedDays}
                          toremove={this.state.toRemoveDays}
                          onChange={(e, v) => {
                            this.setState({
                              [e]: v
                            });
                          }}
                          className="w-100"
                          range={"Monday - Sunday"}
                        />

                        <div
                          style={{
                            marginTop: "0px",
                            marginLeft: "0px",
                            fontFamily: "futura-pt, sans-serif"
                          }}
                        >
                          <p>
                            {this.state.recurringCustomerSelectedDays.length >
                              JSON.parse(
                                this.props.program.plans.filter(
                                  e => JSON.parse(e).id == this.state.selectedPlan
                                )[0]
                              ).days_per_week ? (
                              <span
                                style={{
                                  width: "49%",
                                  textAlign: "left",
                                  fontSize: "1rem",
                                  fontFamily: "futura-pt, sans-serif",
                                  fontWeight: "500",
                                  color: "red",
                                  marginLeft: "0px"
                                }}
                              >
                                Selected days cannot exceed number of plan days
                              </span>
                            ) : (
                              <></>
                            )}
                            {this.state.recurringCustomerSelectedDays.length <
                              JSON.parse(
                                this.props.program.plans.filter(
                                  e => JSON.parse(e).id == this.state.selectedPlan
                                )[0]
                              ).days_per_week ? (
                              <span
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "futura-pt, sans-serif"
                                }}
                              >
                                {JSON.parse(
                                  this.props.program.plans.filter(
                                    e =>
                                      JSON.parse(e).id ==
                                      this.state.selectedPlan
                                  )[0]
                                ).days_per_week -
                                  this.state.recurringCustomerSelectedDays
                                    .length}{" "}
                                remaining
                              </span>
                            ) : (
                              <></>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(this.props.program.program_details === "Semester" ||
                this.props.program.program_type === "1" ||
                this.props.table === "partner_event") && (
                  <>
                    <div>
                      <label
                        htmlFor="autoRegister"
                        style={{
                          width: "49%",
                          textAlign: "left",
                          fontSize: "1.15rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "500",
                          color: "#626262",
                          marginTop: "22px"
                        }}
                      >
                        Auto Register
                        <i
                          data-tip
                          data-for="auto-register"
                          className="fas fa-info-circle"
                          style={{ color: "#DCDCDC", marginLeft: "5px" }}
                        ></i>
                        <ReactTooltip
                          id="auto-register"
                          place="right"
                          effect="solid"
                          type="info"
                        >
                          Registers the user automatically for free
                        </ReactTooltip>
                      </label>
                    </div>
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: 0
                      }}
                    >
                      <div style={{ marginLeft: "0px" }}>
                        <Switch
                          checked={this.state.autoRegister}
                          onChange={e =>
                            this.setState({
                              autoRegister: e.target.checked
                            })
                          }
                          color="primary"
                        />
                      </div>
                    </div>
                  </>
                )}

              {this.state.autoRegister && <div></div>}
            </>
          )}
        </div>
      )
    );
  };

  async getUserChildren(dsUserId) {
    if (this.props.program.addChild) {
      this.setState({ loadingCompleteChild: false });
      const ep = `${process.env.REACT_APP_API}/partners/connections/par-child/${dsUserId}`;
      const results = await axios.get(ep);

      if (results.data.success) {
        this.setState({
          child: results.data.data.child.filter(child => !child.isArchived),
          loadingCompleteChild: true
        });
      }
    }
  }

  getBaseModalFields() {
    const newClient = {
      type: GET_HTML,
      data: {
        gethtml: this.addnewmodal
      }
    };

    const existingClient = {
      type: GET_HTML,
      data: {
        gethtml: this.existingclientmodal
      }
    };

    const fields = [];
    fields.push(newClient);
    fields.push(existingClient);

    return fields;
  }

  getBaseModalButtons = () => {
    const newclient = {
      name: "Invite New Client",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.setState({ addNew: true })
    };

    const existingclient = {
      name: "Invite Existing Client",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.setState({ addExisting: true })
    };

    const addnewButton = {
      name: "Add New",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.submitForm(e, "New")
    };

    const inviteButton = {
      name: "Invite",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.submitForm(e, "Existing")
    };

    const fieldsbutton = [];
    if (!this.state.addNew && !this.state.addExisting) {
      fieldsbutton.push(newclient);
      fieldsbutton.push(existingclient);
    }
    if (this.state.addNew) {
      fieldsbutton.push(addnewButton);
    }
    if (this.state.addExisting) {
      fieldsbutton.push(inviteButton);
    }
    return fieldsbutton;
  };

  getBaseModalProps = () => {
    return {
      title: `Add ${this.props.type === "events" ? "Attendee" : "Enrollment"}`,
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "calc( min(650px,65vh) - 63px - 87px)", //min(650px,65vh) = min( modal height of 65vh, or max-height:650px ).
      //minus 63px = title bar.
      //minus 87px = bottom modal button.
      handleClose: this.props.onClose
    };
  };

  render() {
    console.log("this.state", this.state);
    console.log("this.props", this.props);
    const plans =
      this.props.program.plans ||
      this.props.program.tickets ||
      this.props.program.prices;
    console.log("plans", plans);
    return (
      <>
        {this.state.isSelectDates && (
          <DateTimePickerModal
            onClose={() => this.setState({ isSelectDates: false })}
            selectedItem={this.props.program}
            dates={this.state.singleSessionSelectedDates}
            setDates={dates =>
              this.setState({ singleSessionSelectedDates: dates })
            }
            quantity={this.state.singleSessionQuantity}
            plan={JSON.parse(
              this.props.program.plans.filter(
                e => JSON.parse(e).id == this.state.selectedPlan
              )[0]
            )}
          />
        )}
        <BaseModal {...this.getBaseModalProps()} />
      </>
    );
  }
}

export default withRouter(AddInstructor);
