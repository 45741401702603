import React from "reactn";
import { LiveView1, LiveView2, LiveView3, LiveView4 } from "../../assets";
import "../../assets/css/componentSpecificCss/cssforProgram.css";
import GoogleMapReact from "google-map-react";
import { format, addWeeks, addDays, subDays } from "date-fns";
import Checkout from "./checkout";
import axios from "axios";
import { OrangePin } from "../../assets";
import { withStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import {
  constructTimeRangeFromDecimalHours,
  getFormattedTimeRange,
  getGroupedWeekdayRange,
  calculateProratedClasses
} from "./SpecificPageUtils/utils";
import { ReactBnbGallery } from "react-bnb-gallery";
import Radio from "@material-ui/core/Radio";
import SummarySubTotal from "../front/SpecificPageUtils/SummarySubTotal";

const styles = {
  plan: {
    transition: "all 300ms",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,.2)",
    "&:hover": {
      transform: "scale(1.02) translateY(-3px)",
      boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  },
  radio: {
    color: "#ccc",
    "&$checked": {
      color: "#FF6666"
    },
    "& .MuiButtonBase-root": {
      padding: "0px !important",
      marginRight: "0px !important"
    }
  },
  checkedRadio: {
    color: "#FF6666"
  }
};

const daysOfWeekConversion = day => {
  const table = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };
  return table[day];
};

const getFirstDay = (startingDate, daysOfWeek) => {
  // Code to compensate for the local timezone of the starting date
  startingDate.setTime(
    startingDate.getTime() + startingDate.getTimezoneOffset() * 60 * 1000
  );

  console.log("daysOfWeek...", daysOfWeek);
  if (Object.keys(daysOfWeek).length === 0) return;

  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  while (true) {
    if (convertedDaysOfWeek.includes(startingDate.getDay())) {
      return startingDate;
    }
    startingDate = addDays(startingDate, 1);
  }
};

const getLastDay = (lastDay, daysOfWeek) => {
  console.log("lastOfWeek...", daysOfWeek);
  if (Object.keys(daysOfWeek).length === 0) return;
  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  lastDay = addWeeks(lastDay, 1);
  lastDay = subDays(lastDay, 1);
  while (true) {
    if (convertedDaysOfWeek.includes(lastDay.getDay())) {
      return lastDay;
    }
    lastDay = subDays(lastDay, 1);
  }
};
class SpecificProgram extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      galleryShown: false,
      textShown: false,
      mapShown: false,
      gallery: [],
      program: {
        age_range: [],
        plans: [],
        age_groupings: [],
        timing: [0, 0],
        days_of_week: {},
        other_locations: []
      },
      customers: {
        Enrollments: []
      },
      selected: null,
      checkout: false,
      singleSessionQuantity: 1
    };
  }
  async componentDidMount() {
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/programs/${this.props.match.params.id}/true`;
    const results = await axios.get(ep);

    const gallery_ep = `${process.env.REACT_APP_API}/partners/organization_profile/preview`;
    const gallery = await axios.get(gallery_ep);

    const customers_ep = `${process.env.REACT_APP_API}/partners/programs/customers/${this.props.match.params.id}`;
    const customers = await axios.get(customers_ep);

    console.log("customers", customers);

    if (!results.data.success) {
      this.props.history.push("/");
      this.setGlobal({ loading: false });
      return;
    }
    if (results.data.success && results.data.data.isArchived) {
      this.props.history.push("/partners");
      this.setGlobal({ loading: false });
      return;
    }

    this.fetchEnrollmentCount(results.data.data.id);

    const isCountLargerThanSpots =
      results.data.data.total_spots &&
        (await this.fetchEnrollmentCount(results.data.data.id)) >=
        results.data.data.total_spots
        ? true
        : false;

    const isEnableWaitlist =
      results.data.data.enable_waitlist && isCountLargerThanSpots
        ? true
        : false;

    const isBookingFull =
      isCountLargerThanSpots && !isEnableWaitlist ? true : false;

    this.setState({
      program: results.data.data,
      customers: { Enrollments: customers.data.data },
      isEnableWaitlist: isEnableWaitlist,
      isBookingFull: isBookingFull,
      gallery: gallery.data?.data?.data?.gallery ? gallery.data?.data?.data?.gallery : []
    });
    this.setGlobal({ loading: false });
  }

  fetchEnrollmentCount = async programId => {
    const ep = `${process.env.REACT_APP_API}/partners/programs/enrollments/count/${programId}`;
    const result = await axios.get(ep);

    this.setState({
      enrollmentCount: parseInt(result.data.data.count)
    });

    return parseInt(result.data.data.count);
  };

  getLocation(locations) {
    const data = locations.filter(
      e => e.address === this.state.program.address1
    );
    return data.length ? (
      <div
        lat={data[0].lat}
        lng={data[0].lng}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    ) : (
      <div
        lat={this.state.program.main_lat}
        lng={this.state.program.main_long}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    );
  }

  showTotalSpotsLeft() {
    let spotsLeft;
    if (
      this.state.customers.Enrollments.length < this.state.program.total_spots
    ) {
      spotsLeft =
        this.state.program.total_spots -
        this.state.customers.Enrollments.length;
    } else if (
      this.state.customers.Enrollments.length >= this.state.program.total_spots
    ) {
      spotsLeft = 0;
    }
    return spotsLeft;
  }

  openGallery = () => {
    this.setState({
      galleryShown: !this.state.galleryShown
    });
  };

  render() {
    if (!this.global.organizationInfo) {
      return null;
    }

    console.log("this.state", this.state);
    let timing = this.state.program.timing;

    if (this.state.program.enableCustomTimes) {
      let timeRanges = [];
      for (const day in this.state.program.customTimes) {
        this.state.program.customTimes[day].map((e, index) => {
          if (index % 2 === 0) {
            timeRanges.push([
              parseFloat(e),
              parseFloat(this.state.program.customTimes[day][index + 1])
            ]);
            return e;
          } else {
            return e;
          }
        });
      }
      // Sort in decreasing order the length of the time ranges.
      timeRanges.sort((a, b) => b[1] - b[0] - (a[1] - a[0]));

      // For program single sessions with custom times, show the greatest time range.
      timing[0] = String(timeRanges[0][0]);
      timing[1] = String(timeRanges[0][1]);
    } else if (this.state.program.program_category === "Single Sessions") {
      let timeRanges = [];
      timing.map((e, index) => {
        if (index % 2 === 0) {
          timeRanges.push([parseFloat(e), parseFloat(timing[index + 1])]);
          return e;
        } else {
          return e;
        }
      });
      // Sort in decreasing order the length of the time ranges.
      timeRanges.sort(
        (a, b) =>
          parseFloat(b[1]) -
          parseFloat(b[0]) -
          (parseFloat(a[1]) - parseFloat(a[0]))
      );

      // For program single sessions, show the greatest time range.
      if (timeRanges[0][0] !== "-1" && timeRanges[0][1] !== "-1") {
        timing = timeRanges[0];
      }
    }

    /* No Timezones needed for the  */
    let { startTime, endTime } = constructTimeRangeFromDecimalHours(
      parseFloat(timing[0]),
      parseFloat(timing[1]),
      null
    );

    let formattedTimeRange = getFormattedTimeRange(
      startTime,
      endTime,
      " - ",
      true
    );

    if (timing[0] === "-1" || timing[1] === "-1") {
      formattedTimeRange = "<Error>";
    }

    let formattedOpenDays = getGroupedWeekdayRange(
      this.state.program.days_of_week
    );

    let noPlansAvailableMessage;

    const adjustNumWeeks = this.state.program.isRecurring ? 0 : 1;
    const plansAllowedForEnrollment = this.state.program.plans.filter(e => {
      const element = typeof e === "string" ? JSON.parse(e) : e;
      let planEndDate = new Date(
        getLastDay(
          addWeeks(
            getFirstDay(
              new Date(this.state.program.program_start),
              this.state.program.days_of_week
            ),
            this.state.program.number_of_weeks - adjustNumWeeks
          ),
          this.state.program.days_of_week
        )
      );

      let hours = 0;
      let minutes = 0;

      let endHours = 0;
      let endMinutes = 0;

      if (this.state.program.enableCustomTimes) {
        const options = { weekday: "long" };
        const dayOfWeek = planEndDate.toLocaleString("en-US", options);
        const times = this.state.program.customTimes[dayOfWeek]
          .filter((value, index) => value !== -1 && index % 2 == 0)
          .sort(function (a, b) {
            return parseFloat(a) - parseFloat(b);
          });

        const time = times.length === 0 ? 0 : parseFloat(times[0]); // decimal value representing hours and minutes

        const endTimes = this.state.program.customTimes[dayOfWeek]
          .filter((value, index) => value !== -1 && index % 2 == 1)
          .sort(function (a, b) {
            return parseFloat(a) - parseFloat(b);
          });
        const endTime = endTimes.length === 0 ? 24 : parseFloat(endTimes[0]);

        hours = Math.floor(time); // get the integer part (hours) using Math.floor()
        minutes = Math.round((time - hours) * 60); // get the decimal part (minutes) and convert to minutes
        endHours = Math.floor(endTime);
        endMinutes = Math.floor((endTime - endHours) * 60);
        console.log(
          `${hours} hours and ${minutes} minutes | ${endHours} hours and ${endMinutes} minutes`
        ); // logs "5 hours and 15 minutes"

        if (hours && hours !== -1) {
          planEndDate = new Date(planEndDate.setHours(hours));
          if (minutes) {
            planEndDate = new Date(planEndDate.setMinutes(minutes));
          }
        }
      } else {
        const decimalValue = this.state.program.timing
          .filter((value, index) => value !== "-1" && index % 2 === 0)
          .sort(function (a, b) {
            return parseFloat(a) - parseFloat(b);
          });

        const time =
          decimalValue.length === 0 ? 0 : parseFloat(decimalValue[0]); // decimal value representing hours and minutes

        const endDecimalValue = this.state.program.timing
          .filter((value, index) => value !== "-1" && index % 2 === 1)
          .sort(function (a, b) {
            return parseFloat(a) - parseFloat(b);
          });
        const endTime =
          endDecimalValue.length === 0 ? 24 : parseFloat(endDecimalValue[0]);

        hours = Math.floor(time); // get the integer part (hours) using Math.floor()
        minutes = Math.round((time - hours) * 60); // get the decimal part (minutes) and convert to minutes
        endHours = Math.floor(endTime);
        endMinutes = Math.round((endTime - endHours) * 60);
        console.log(
          `${hours} hours and ${minutes} minutes | ${endHours} hours and ${endMinutes} minutes`
        ); // logs "5 hours and 15 minutes"

        if (hours && hours !== -1) {
          planEndDate = new Date(planEndDate.setHours(hours));
          if (minutes) {
            planEndDate = new Date(planEndDate.setMinutes(minutes));
          }
        }

        if (this.state.program.continuous_registration) {
          if (endHours && endHours !== -1) {
            planEndDate = new Date(planEndDate.setHours(endHours));
            if (endMinutes) {
              planEndDate = new Date(planEndDate.setMinutes(endMinutes));
            }
          }
        }
      }

      const date = this.state.program.program_start.substring(0, 10);
      const dt = DateTime.fromISO(date, {
        zone: this.state.program.timezone
      }).toISO();
      const programStart = DateTime.fromISO(dt, {
        zone: this.state.program.timezone
      }).plus({ minutes: minutes, hour: hours });
      const rezonedProgramStart = programStart.setZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      const programEnd = DateTime.fromISO(dt, {
        zone: this.state.program.timezone
      }).plus({
        minutes: endMinutes,
        hour: endHours,
        week: this.state.program.number_of_weeks - adjustNumWeeks
      });
      const rezonedProgramEnd = programEnd.setZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      const currTime = DateTime.local();

      console.log(
        "DATE TIMING",
        dt,
        "|||",
        new Date(programStart),
        "|||",
        new Date(rezonedProgramStart),
        "|||",
        new Date(rezonedProgramEnd),
        "|||",
        new Date(currTime)
      );

      console.log("card", element);
      console.log("end date is", planEndDate);
      console.log("start date", new Date());
      console.log("Plan Ended", planEndDate < new Date());
      const programEndDatePassed = this.state.program.continuous_registration
        ? new Date(rezonedProgramEnd) < new Date(currTime) &&
        !this.state.program.isRecurring
        : new Date(rezonedProgramStart) < new Date(currTime) &&
        !this.state.program.isRecurring;
      if (programEndDatePassed) {
        console.log("end date passed");
        if (this.state.program.continuous_registration) {
          noPlansAvailableMessage = "- End date has passed";
        } else {
          noPlansAvailableMessage = "- Start date has passed";
        }
        return false;
      }

      return !element.archived && element.active !== false && element.isHidden !== true; //"active"/ "isHidden" can be undefined, null, or true! But not false!
    });

    return this.state.checkout && this.global.dsUser ? (
      <Checkout
        form={this.state.program.formId}
        program={this.state.program}
        selected={this.state.selected}
        organization={this.state.program.organization_title}
        installments={this.state.installments}
        dropins={this.state.dropIns}
        close={_ => this.setState({ checkout: false })}
        type={"Program"}
      />
    ) : (
      <div className="containerall">
        <ReactBnbGallery
          show={this.state.galleryShown}
          photos={this.state.gallery.map(
            e => `${process.env.REACT_APP_DS_FILES_S3 + "/" + e}`
          )}
          onClose={this.openGallery}
        />
        <div className="leftcontainerprogram">
          <div className="titledivision">
            <h1 className="maintitleprogram">
              {this.state.program.program_name}{" "}
              {this.state.isEnableWaitlist && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    On Waitlist
                  </span>
                  {")"}
                </span>
              )}
              {this.state.isBookingFull && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    FULL
                  </span>
                  {")"}
                </span>
              )}
            </h1>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h4
                style={{
                  margin: `0px 0px 10px 0px`,
                  color: `#999`,
                  fontFamily: `Montserrat`,
                  fontWeight: `600`,
                  textDecoration: `underline`,
                  cursor: "pointer",
                  fontSize: "1.2rem"
                }}
                onClick={_ => this.props.history.push(`/preview`)}
              >
                {this.state.program.organization_title}
              </h4>
              <div>
                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px", marginRight: "10px" }}
                  onClick={this.openGallery}
                >
                  Gallery
                </button>

                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px" }}
                >
                  Share
                </button>
              </div>
            </div>
          </div>
          <div className="maincontainerdiv">
            <img
              className="imageprogram"
              alt="program"
              src={`${process.env.REACT_APP_DS_FILES_S3}/${this.state.program.program_photo}`}
            />

            <div className="widgetdata">
              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView1} alt="icon" className="icon-img" />
                {console.log("TIME RANGE", formattedTimeRange)}
                <p className="icon-text">
                  {formattedOpenDays}{" "}
                  {formattedTimeRange !== "<Error>"
                    ? `- ${formattedTimeRange}`
                    : ""}
                </p>
              </div>
              {!this.state.program.all_ages &&
                this.state.program.age_groupings.length === 0 ? null : (
                <div
                  className="box"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                    marginBottom: "10px"
                  }}
                >
                  <img src={LiveView2} alt="icon" className="icon-img" />
                  <p className="icon-text">
                    {this.state.program.all_ages
                      ? "All Ages"
                      : this.state.program.age_groupings
                        .map(e => JSON.parse(e).name)
                        .join(", ")}
                  </p>
                </div>
              )}
              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView3} alt="icon" className="icon-img" />
                <p className="icon-text">
                  {this.state.program.program_category}
                </p>
              </div>

              {this.state.program.show_total_spots && (
                <div
                  className="box"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px"
                  }}
                >
                  <img src={LiveView4} alt="icon" className="icon-img" />
                  <p className="icon-text">
                    Total Spots:{" "}
                    {this.state.program.total_spots &&
                      this.state.program.total_spots}{" "}
                    {this.state.program.total_spots &&
                      `(remaining: ${this.showTotalSpotsLeft()})`}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="box-size">
            <h3 className="sub-title">Description</h3>

            <p
              style={{
                fontWeight: "400",
                fontFamily: `"Open sans", sans-serif`,
                whiteSpace: "pre-line"
              }}
            >
              {this.state.program.description}
            </p>
          </div>
          {this.state.program.additional_notes && (
            <div className="box-size">
              <h3 className="sub-title">Additional Details</h3>
              <p
                style={{
                  fontWeight: "400",
                  fontFamily: `"Open sans", sans-serif`,
                  whiteSpace: "pre-line"
                }}
              >
                {this.state.program.additional_notes}
              </p>
            </div>
          )}
          {this.state.program.show_cancellation_policy && (
            <div className="box-size">
              <h3 className="sub-title">Cancellation Policy</h3>
              <p
                style={{
                  fontWeight: "400",
                  fontFamily: `"Open sans", sans-serif`
                }}
              >
                {this.state.program.cancellation_policy}
              </p>
            </div>
          )}
          {this.state.program.show_location && (
            <div className="box-size">
              <h3 className="sub-title">Location</h3>
              <div className="map-size">
                <p>Address : {this.state.program.address1}</p>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyAVaN8qvELLoX3nYdqFC91GqbxXLcV-cig"
                  }}
                  defaultZoom={9}
                  defaultCenter={{
                    lat: parseFloat(this.global.organizationInfo.main_lat),
                    lng: parseFloat(this.global.organizationInfo.main_long)
                  }}
                  options={{ gestureHandling: "greedy" }}
                >
                  {this.getLocation(this.state.program.other_locations)}
                </GoogleMapReact>
              </div>
            </div>
          )}
        </div>

        <div className="rightcontainerprogram">
          <h2 className="right-cont-name">Booking Details</h2>
          {/* Sort by: Recurring: tuition_rate Semester: total_price */}
          <div style={{ width: "80%", margin: "0 auto" }}>
            {plansAllowedForEnrollment.length === 0 && (
              <div>
                <p>No plans available {noPlansAvailableMessage}</p>
              </div>
            )}
            {/* {plansAllowedForEnrollment.length > 0 &&
              this.state.program.plans.filter(e => {
                const element = typeof e === "string" ? JSON.parse(e) : e;
                console.log("card", element);
                console.log(
                  "end date is",
                  new Date(
                    getLastDay(
                      addWeeks(
                        getFirstDay(
                          new Date(this.state.program.program_start),
                          this.state.program.days_of_week
                        ),
                        this.state.program.number_of_weeks - 1
                      ),
                      this.state.program.days_of_week
                    )
                  ),
                  new Date(),
                  new Date(
                    getLastDay(
                      addWeeks(
                        getFirstDay(
                          new Date(this.state.program.program_start),
                          this.state.program.days_of_week
                        ),
                        this.state.program.number_of_weeks - 1
                      ),
                      this.state.program.days_of_week
                    )
                  ) < new Date()
                );
                if (
                  element.type === "Semester" &&
                  new Date(
                    getLastDay(
                      addWeeks(
                        getFirstDay(
                          new Date(this.state.program.program_start),
                          this.state.program.days_of_week
                        ),
                        this.state.program.number_of_weeks - 1
                      ),
                      this.state.program.days_of_week
                    )
                  ) < new Date()
                ) {
                  console.log("end date passed");
                  return false;
                }
                return true;
              }).length === 0 && (
                <div>
                  <p>No plans available</p>
                </div>
              )} */}
            {plansAllowedForEnrollment.length > 0 &&
              this.state.program.plans
              .sort((prev, next) => {
                let newPrev = prev;
                let newNext = next;
                if (typeof prev === "string") {
                  newPrev = JSON.parse(prev);
                }
          
                if (typeof next === "string") {
                  newNext = JSON.parse(next);
                }
          
                return newPrev.order - newNext.order;
              }).filter(e => {
                  const element = typeof e === "string" ? JSON.parse(e) : e;
                  console.log("card", element);
                  console.log(
                    "end date is",
                    new Date(
                      getLastDay(
                        addWeeks(
                          getFirstDay(
                            new Date(this.state.program.program_start),
                            this.state.program.days_of_week
                          ),
                          this.state.program.number_of_weeks - 1
                        ),
                        this.state.program.days_of_week
                      )
                    ),
                    new Date(),
                    new Date(
                      getLastDay(
                        addWeeks(
                          getFirstDay(
                            new Date(this.state.program.program_start),
                            this.state.program.days_of_week
                          ),
                          this.state.program.number_of_weeks - 1
                        ),
                        this.state.program.days_of_week
                      )
                    ) < new Date()
                  );
                  if (
                    element.type === "Semester" &&
                    plansAllowedForEnrollment.length === 0
                  ) {
                    console.log("end date passed");
                    return false;
                  }
                  return true;
                })
                // .sort((a, b) => {
                //   const elementA = typeof a === "string" ? JSON.parse(a) : a;
                //   const elementB = typeof b === "string" ? JSON.parse(b) : b;
                //   if (!elementA.type && !elementB.type) {
                //     if (
                //       parseFloat(elementA.tuition_rate) <
                //       parseFloat(elementB.tuition_rate)
                //     )
                //       return -1;
                //     if (
                //       parseFloat(elementA.tuition_rate) >
                //       parseFloat(elementB.tuition_rate)
                //     )
                //       return 1;
                //     return 0;
                //   } else {
                //     if (
                //       parseFloat(elementA.total_price) <
                //       parseFloat(elementB.total_price)
                //     )
                //       return -1;
                //     if (
                //       parseFloat(elementA.total_price) >
                //       parseFloat(elementB.total_price)
                //     )
                //       return 1;
                //     return 0;
                //   }
                // })
                .map((e, i) => {
                  const element = typeof e === "string" ? JSON.parse(e) : e;
                  if (element.archived || element.active === false || element.isHidden === true) {
                    return null;
                  }
                  let planEnrollment = 0;
                  this.state.customers.Enrollments.forEach(e => {
                    if (
                      e.status_stage === "Accepted_Paid" &&
                      element.id === e.plan_id
                    ) {
                      planEnrollment++;
                    }
                  });
                  if (!element.type)
                    return (
                      <div
                        className={
                          this.state.selected === i
                            ? this.props.classes.planSelected + " pricingcard"
                            : this.props.classes.plan + " pricingcard"
                        }
                        onClick={_ => this.setState({ selected: i })}
                        key={i}
                      >
                        <div className="radio-btn-positioning-container">
                          <Radio
                            checked={this.state.selected === i}
                            // onChange={handleChange}
                            value="d"
                            color="default"
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "D" }}
                            size="large"
                            classes={{
                              root: this.props.classes.radio,
                              checked: this.props.classes.checkedRadio
                            }}
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <p className="pricingcard-name">
                            Recurring ({element.billing_cycle})
                          </p>

                          <p className="pricingcard-days">
                            {element.days_per_week}{" "}
                            {
                              /*currently a string but will be integer in future*/ element.days_per_week ==
                                "1"
                                ? "day"
                                : "days"
                            }{" "}
                            / week
                            {!element.isReplaceTimeEnabled ? (
                              <span style={{ fontStyle: "italic" }}>
                                {" ("}
                                {element.timing[0]
                                  .replace(/\s/g, "")
                                  .toLowerCase()}{" "}
                                -{" "}
                                {element.timing[1]
                                  .replace(/\s/g, "")
                                  .toLowerCase()}
                                )
                              </span>
                            ) : (
                              <span style={{ fontStyle: "italic" }}>
                                {" ("}
                                {element.description}
                                {")"}
                              </span>
                            )}
                          </p>
                        </div>
                        <div style={{ width: "72px" }}>
                          <p className="pricingcard-amount">
                            ${element.tuition_rate} / {element.billing_cycle}
                          </p>
                        </div>
                      </div>
                    );
                  if (element.type === "Semester"){
                    let amountTemp;
                    let totalSessionsTemp;
                    let sessionsPassedTemp;
                    if (element.isProrated) {
                      const { totalSessions, sessionsPassed, amount } = calculateProratedClasses(this.state.program, element.total_price);
                      amountTemp = amount;
                      totalSessionsTemp = totalSessions;
                      sessionsPassedTemp = sessionsPassed;
                      if (!Number.isInteger(amountTemp)) {
                        amountTemp = amountTemp.toFixed(2);
                      }
                      this.setState({ prorationSubtotal: amountTemp });
                    }
                    return (
                      <>
                        <div
                          className={
                            this.state.selected === i &&
                              !this.state.dropIns &&
                              !this.state.installments
                              ? this.props.classes.planSelected + " pricingcard"
                              : this.props.classes.plan + " pricingcard"
                          }
                          onClick={_ =>
                            this.setState({
                              selected: i,
                              dropIns: false,
                              installments: false
                            })
                          }
                          key={i}
                        >
                          <div className="radio-btn-positioning-container">
                            <Radio
                              checked={this.state.selected === i}
                              // onChange={handleChange}
                              value="d"
                              color="default"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "D" }}
                              size="large"
                              classes={{
                                root: this.props.classes.radio,
                                checked: this.props.classes.checkedRadio
                              }}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <p
                              // style={{
                              //   textAlign: "justify",
                              //   marginBottom: 0,
                              //   fontWeight: "bold",
                              //   color: "#505050",
                              //   fontFamily: `Montserrat,sans-serif`,
                              //   fontSize: "1.2em"
                              // }}
                              className="pricingcard-name"
                            >
                              {element.description &&
                                element.description !== null ? (
                                <span style={{ fontStyle: "italic" }}>
                                  {element.description}
                                </span>
                              ) : (
                                "Semester"
                              )}
                            </p>
                            {element.isProrated && sessionsPassedTemp != 0 && (
                              <p className="pricingcard-days">
                                Prorated (original price ${element.total_price}) 
                              </p>
                            )}
                            <p
                              // style={{
                              //   textAlign: "justify",
                              //   color: "gray",
                              //   marginBottom: 0,
                              //   fontFamily: `Open Sans,sans-serif`,
                              //   fontSize: "0.9em"
                              // }}
                              className="pricingcard-days"
                            >
                              {format(
                                getFirstDay(
                                  new Date(this.state.program.program_start),
                                  this.state.program.days_of_week
                                ),
                                "LL/dd/yy"
                              )}{" "}
                              -{" "}
                              {format(
                                getLastDay(
                                  addWeeks(
                                    getFirstDay(
                                      new Date(
                                        this.state.program.program_start
                                      ),
                                      this.state.program.days_of_week
                                    ),
                                    this.state.program.number_of_weeks - 1
                                  ),
                                  this.state.program.days_of_week
                                ),
                                "LL/dd/yy"
                              )}
                            </p>
                            <span
                              style={{ fontWeight: "normal", fontSize: "12px" }}
                            ></span>
                            <p className="pricingcard-days">
                              {element.planCap && element.planCap != null
                                ? parseInt(element.planCap) <= planEnrollment
                                  ? `Spots ${element.planCap} (FULL)`
                                  : `Spots ${element.planCap}`
                                : ""}
                            </p>
                          </div>

                          <p
                            // style={{
                            //   color: "#75b2e0",
                            //   marginBottom: 0,
                            //   fontWeight: "bold",
                            //   fontSize: "1.3em"
                            // }}
                            className="pricingcard-amount"
                          >
                            ${element.isProrated? amountTemp : element.total_price}
                          </p>
                        </div>
                        {element.installments && (
                          <div
                            className={
                              this.state.selected === i &&
                                this.state.installments
                                ? this.props.classes.planSelected +
                                " pricingcard"
                                : this.props.classes.plan + " pricingcard"
                            }
                            onClick={_ =>
                              this.setState({
                                selected: i,
                                dropIns: false,
                                installments: true
                              })
                            }
                            key={i}
                          >
                            <div className="radio-btn-positioning-container">
                              <Radio
                                checked={this.state.selected === i}
                                // onChange={handleChange}
                                value="d"
                                color="default"
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "D" }}
                                size="large"
                                classes={{
                                  root: this.props.classes.radio,
                                  checked: this.props.classes.checkedRadio
                                }}
                              />
                            </div>
                            <div style={{ width: "100%" }}>
                              <p
                                // style={{
                                //   textAlign: "justify",
                                //   marginBottom: 0,
                                //   fontWeight: "bold",
                                //   color: "#505050",
                                //   fontFamily: `Montserrat,sans-serif`,
                                //   fontSize: "1.2em"
                                // }}
                                className="pricingcard-name"
                              >
                                {element.description &&
                                  element.description !== null ? (
                                  <span style={{ fontStyle: "italic" }}>
                                    {element.description}
                                  </span>
                                ) : (
                                  "Semester"
                                )} - Inst
                              </p>
                              <p
                                // style={{
                                //   textAlign: "justify",
                                //   color: "gray",
                                //   marginBottom: 0,
                                //   fontFamily: `Open Sans,sans-serif`,
                                //   fontSize: "0.9em"
                                // }}
                                className="pricingcard-days"
                              >
                                {format(
                                  new Date(this.state.program.program_start),
                                  "LL/dd/yy"
                                )}{" "}
                                -{" "}
                                {format(
                                  addWeeks(
                                    new Date(this.state.program.program_start),
                                    this.state.program.number_of_weeks
                                  ),
                                  "LL/dd/yy"
                                )}
                              </p>
                              <span
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "12px"
                                }}
                              ></span>
                            </div>

                            <p
                              // style={{
                              //   color: "#75b2e0",
                              //   marginBottom: 0,
                              //   fontWeight: "bold",
                              //   fontSize: "1.3em"
                              // }}
                              className="pricingcard-amount"
                            >
                              $
                              {parseFloat(
                                (element.total_price /
                                  element.installments_breakdown) *
                                (1 +
                                  parseInt(element.installments_interest) /
                                  100)
                              ).toFixed(0)}{" "}
                              / {element.installments_plan}
                            </p>
                          </div>
                        )}
                      </>
                    );
                  }
                  if (element.type === "Drop-In") 
                    return (
                      <>
                        <div
                          className={
                            this.state.selected === i &&
                              !this.state.dropIns &&
                              !this.state.installments
                              ? this.props.classes.planSelected + " pricingcard"
                              : this.props.classes.plan + " pricingcard"
                          }
                          onClick={_ =>
                            this.setState({
                              selected: i,
                              dropIns: false,
                              installments: false
                            })
                          }
                          key={i}
                        >
                          <div className="radio-btn-positioning-container">
                            <Radio
                              checked={this.state.selected === i}
                              value="d"
                              color="default"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "D" }}
                              size="large"
                              classes={{
                                root: this.props.classes.radio,
                                checked: this.props.classes.checkedRadio
                              }}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <p className="pricingcard-name">
                              {element.description || element.type}
                            </p>
                            <p
                              className="pricingcard-days"
                              style={{ paddingRight: "10px" }}
                            >
                              Times: {element.startTime}-{element.endTime}
                            </p>
                            <span
                              style={{ fontWeight: "normal", fontSize: "12px" }}
                            ></span>
                            <p className="pricingcard-days">
                              {element.planCap && element.planCap != null
                                ? parseInt(element.planCap) <= planEnrollment
                                  ? `Spots ${element.planCap} (FULL)`
                                  : `Spots ${element.planCap}`
                                : ""}
                            </p>
                          </div>

                          <p
                            className="pricingcard-amount"
                          >
                            ${(parseFloat(element.total_price) * (element.premium ? 1 + parseFloat(element.premium) / 100 : 1)).toFixed(2)}
                          </p>
                        </div>
                      </>
                    );

                  if (element.type === "Single Sessions")
                    return (
                      <div
                        className={
                          this.state.selected === i
                            ? this.props.classes.planSelected + " pricingcard"
                            : this.props.classes.plan + " pricingcard"
                        }
                        onClick={_ => this.setState({ selected: i })}
                        key={i}
                      >
                        <div className="radio-btn-positioning-container">
                          <Radio
                            checked={this.state.selected === i}
                            // onChange={handleChange}
                            value="d"
                            color="default"
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "D" }}
                            size="large"
                            classes={{
                              root: this.props.classes.radio,
                              checked: this.props.classes.checkedRadio
                            }}
                          />
                        </div>
                        <div style={{ width: "90%" }}>
                          <p className="pricingcard-name">
                            Single (
                            {element.duration_type === "Class"
                              ? "Classes"
                              : element.duration_type}
                            {element.duration ? ` ${element.duration}mins` : ""}
                            ) x {this.state.singleSessionQuantity}
                          </p>

                          <p
                            className="pricingcard-days"
                            style={{ textAlign: "left" }}
                          >
                            {this.state.program.number_of_weeks !== 0 &&
                              this.state.program.number_of_weeks !== -1
                              ? `${format(
                                getFirstDay(
                                  new Date(this.state.program.program_start),
                                  this.state.program.days_of_week
                                ),
                                "LL/dd/yy"
                              )} -
                              ${format(
                                getLastDay(
                                  addWeeks(
                                    getFirstDay(
                                      new Date(
                                        this.state.program.program_start
                                      ),
                                      this.state.program.days_of_week
                                    ),
                                    this.state.program.number_of_weeks - 1
                                  ),
                                  this.state.program.days_of_week
                                ),
                                "LL/dd/yy"
                              )}`
                              : ""}
                          </p>
                          <p
                            className="pricingcard-days"
                            style={{
                              textAlign: "left"
                            }}
                          >
                            {""}
                            {element.description}
                            {""}
                          </p>
                        </div>
                        <div style={{ width: "72px" }}>
                          <p className="pricingcard-amount">
                            ${element.total_price}{" "}
                            {element.frequency === "minutes"
                              ? ""
                              : `/ ${element.frequency}`}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                color: "#36b9cc",
                                marginLeft: "22px"
                              }}
                            >
                              <i
                                className="fas fa-minus-circle"
                                style={{ fontSize: "13px" }}
                                onClick={_ => {
                                  if (this.state.singleSessionQuantity === 1)
                                    return;
                                  this.setState({
                                    singleSessionQuantity:
                                      this.state.singleSessionQuantity - 1
                                  });
                                }}
                              ></i>
                              <i
                                className="fas fa-plus-circle"
                                style={{ fontSize: "13px" }}
                                onClick={_ => {
                                  if (
                                    this.state.singleSessionQuantity ===
                                    parseInt(element.quantity)
                                  )
                                    return;
                                  this.setState({
                                    singleSessionQuantity:
                                      this.state.singleSessionQuantity + 1
                                  });
                                }}
                              ></i>
                            </div>
                          </p>
                        </div>
                      </div>
                    );
                  
                  if (element.type === "Free Trial")
                    return (
                      <>
                        <div
                          className={
                            this.state.selected === i &&
                              !this.state.dropIns &&
                              !this.state.installments
                              ? this.props.classes.planSelected + " pricingcard"
                              : this.props.classes.plan + " pricingcard"
                          }
                          onClick={_ =>
                            this.setState({
                              selected: i,
                              dropIns: false,
                              installments: false
                            })
                          }
                          key={i}
                        >
                          <div className="radio-btn-positioning-container">
                            <Radio
                              checked={this.state.selected === i}
                              value="d"
                              color="default"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "D" }}
                              size="large"
                              classes={{
                                root: this.props.classes.radio,
                                checked: this.props.classes.checkedRadio
                              }}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <p className="pricingcard-name">
                              {element.description}
                            </p>
                            <p
                              className="pricingcard-days"
                              style={{ paddingRight: "10px" }}
                            >
                              Free Trial for {element.trialPeriod} {element.trialPeriod == 1 ? "class" : "classes"}
                            </p>
                            <span
                              style={{ fontWeight: "normal", fontSize: "12px" }}
                            ></span>
                            <p className="pricingcard-days">
                              {element.planCap && element.planCap != null
                                ? parseInt(element.planCap) <= planEnrollment
                                  ? `Spots ${element.planCap} (FULL)`
                                  : `Spots ${element.planCap}`
                                : ""}
                            </p>
                          </div>

                          <p
                            className="pricingcard-amount"
                          >
                            ${element.total_price}
                          </p>
                        </div>
                      </>
                    );
                  if (element.type === "Free")
                    return (
                      <>
                        <div
                          className={
                            this.state.selected === i &&
                              !this.state.dropIns &&
                              !this.state.installments
                              ? this.props.classes.planSelected + " pricingcard"
                              : this.props.classes.plan + " pricingcard"
                          }
                          onClick={_ =>
                            this.setState({
                              selected: i,
                              dropIns: false,
                              installments: false
                            })
                          }
                          key={i}
                        >
                          <div className="radio-btn-positioning-container">
                            <Radio
                              checked={this.state.selected === i}
                              // onChange={handleChange}
                              value="d"
                              color="default"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "D" }}
                              size="large"
                              classes={{
                                root: this.props.classes.radio,
                                checked: this.props.classes.checkedRadio
                              }}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <p className="pricingcard-name">
                              {element.description}
                            </p>
                            <p
                              className="pricingcard-days"
                              style={{ paddingRight: "10px" }}
                            >
                              {element.isBilledLater? "Billed Later (Invoice to be sent)" : "Free"}
                            </p>
                            <span
                              style={{ fontWeight: "normal", fontSize: "12px" }}
                            ></span>
                            <p className="pricingcard-days">
                              {element.planCap && element.planCap != null
                                ? parseInt(element.planCap) <= planEnrollment
                                  ? `Spots ${element.planCap} (FULL)`
                                  : `Spots ${element.planCap}`
                                : ""}
                            </p>
                          </div>

                          <p
                            // style={{
                            //   color: "#75b2e0",
                            //   marginBottom: 0,
                            //   fontWeight: "bold",
                            //   fontSize: "1.3em"
                            // }}
                            className="pricingcard-amount"
                          >
                            ${element.total_price}
                          </p>
                        </div>
                      </>
                    );
                })}

            {this.state.selected !== null && (
              <SummarySubTotal
                plans={this.state.program.plans}
                program={this.state.program}
                selected={this.state.selected}
                quantity={this.state.singleSessionQuantity}
              />
            )}

            {plansAllowedForEnrollment.length > 0 &&
              this.state.program.isCartDisabled ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    marginTop: "10px",
                    fontWeight: `bold`,
                    border: 0,
                    cursor: "not-allowed"
                  }}
                >
                  Enroll Here Now
                </button>
              </div>
            ) : (plansAllowedForEnrollment.length > 0 &&
              this.state.installments) ||
              this.state.program.auto_withdraw ||
              this.state.program.program_details === "Recurring" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    marginTop: "10px",
                    fontWeight: `bold`,
                    border: 0,
                    cursor: "not-allowed"
                  }}
                >
                  Enroll Here Now
                </button>
                <div>
                  <i className="bi bi-info-circle" /> Recurring programs must be
                  checked out individually.
                </div>
              </div>
            ) : (
              plansAllowedForEnrollment.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <button
                    style={{
                      width: `70%`,
                      padding: `10px`,
                      backgroundColor: `rgb(52, 63, 100)`,
                      color: `white`,
                      fontWeight: `bold`,
                      border: 0,
                      marginTop: "40px",
                      cursor: "not-allowed"
                    }}
                  >
                    Add to Cart
                  </button>
                  <span>-------- OR --------</span>
                  <button
                    style={{
                      width: `70%`,
                      padding: `10px`,
                      backgroundColor: `rgb(211,52,49)`,
                      color: `white`,
                      marginTop: "10px",
                      fontWeight: `bold`,
                      border: 0,
                      cursor: "not-allowed"
                    }}
                  >
                    Enroll Here Now
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(SpecificProgram);
