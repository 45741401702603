import React, { useGlobal } from "reactn";
import Select from "react-dropdown-select";
// import { format } from "date-fns";

const yesNoOptions = [
  { name: "Please Select", value: "" },
  { name: "Yes", value: "Yes" },
  { name: "No", value: "No" }
];

const genderOptions = [
  { name: "Please Select", value: "" },
  { name: "Male", value: "Male" },
  { name: "Female", value: "Female" },
  { name: "Other", value: "Other" },
  { name: "To-Be-Determined", value: "Other" }
];

const MultiProgramCats = [
  { name: "Academics", value: "Academics" },
  { name: "After School", value: "After School" },
  // { name: "Art", value: "Art" },
  { name: "Arts", value: "Arts" },
  { name: "Athletics", value: "Athletics" },
  { name: "Before & After School", value: "Before & After School" },
  { name: "Camps", value: "Camps" },
  { name: "Cooking", value: "Cooking" },
  { name: "Creative", value: "Creative" },
  { name: "Dance", value: "Dance" },
  // { name: "Daycare / Preschool (Home)", value: "Daycare / Preschool (Home)" },
  // { name: "Daycare / Preschool (Centre)", value: "Daycare / Preschool (Centre)" },
  { name: "Daycare/Preschool", value: "Daycare/Preschool" },
  { name: "Drama", value: "Drama" },
  { name: "Exercise", value: "Exercise" },
  { name: "Facilities (Rec Centre)", value: "Facilities (Rec Centre)" },
  { name: "Faith", value: "Faith" },
  { name: "Grants Program", value: "Grants Program" },
  // { name: "Language / Culture", value: "Language / Culture" },
  { name: "Language/Culture", value: "Language/Culture" },
  { name: "Leadership", value: "Leadership" },
  { name: "Life Skills", value: "Life Skills" },
  { name: "Mixed", value: "Mixed" },
  { name: "Music", value: "Music" },
  { name: "Outdoors", value: "Outdoors" },
  { name: "Playgrounds", value: "Playgrounds" },
  { name: "Service Volunteer", value: "Service Volunteer" },
  { name: "Single Sessions", value: "Single Sessions" },
  { name: "STEM", value: "STEM" },
  { name: "Special Needs", value: "Special Needs" },
  { name: "Theatre", value: "Theatre" },
  { name: "Tutoring", value: "Tutoring" }
];

const membershipTypes = [
  { name: "Please Select", value: "" },
  { name: "Children", value: "Children" },
  { name: "Family", value: "Family" },
  { name: "Youth", value: "Youth" },
  { name: "Adult", value: "Adult" },
  { name: "All", value: "All" }
];

const programTypeOptions = [
  { name: "Please Select", value: "" },
  {
    name: "Independent Program (Unlicensed)",
    value: "Independent Program Unlicensed"
  },
  {
    name: "Independent Program (Licensed)",
    value: "Independent Program Licensed"
  },
  { name: "Day Care Center", value: "Day Care" }
];
const programTypeOptionsMulti = [
  { name: "Please Select", value: "" },
  {
    name: "Preschool",
    value: "Preschool"
  },
  {
    name: "Summer Camp",
    value: "Summer Camp"
  },
  { name: "Virtual Classes", value: "Virtual Classes" },
  { name: "Kids Athletic", value: "Kids Athletic" },
  { name: "Academics", value: "Academics" },
  { name: "After School", value: "After School" },
  { name: "Athletics", value: "Athletics" },
  { name: "Music", value: "Music" },
  { name: "Mixed", value: "Mixed" },
  { name: "Outdoors", value: "Outdoors" },
  { name: "Leadership", value: "Leadership" },
  { name: "Before & After School", value: "Before & After School" },
  { name: "STEM", value: "STEM" },
  { name: "Theatre", value: "Theatre" },
  { name: "Arts", value: "Arts" },
  { name: "Playgrounds", value: "Playgrounds" },
  { name: "Creative", value: "Creative" },
  { name: "Drama", value: "Drama" },
  { name: "Dance", value: "Dance" }
];

const paymentAccountTypes = [
  { name: "Please Select", value: "" },
  { name: "Chequing", value: "Chequing" },
  { name: "Savings", value: "Savings" }
];

const ageGroups = [
  { name: "All ages", value: "All ages", order: 0 },
  { name: "Infant (0-2 yrs)", value: "Infant", order: 1 },
  { name: "Toddler (2-3 yrs)", value: "Toddler", order: 2 },
  { name: "Preschool (3-4 yrs)", value: "Preschool", order: 3 },
  { name: "Kindergarten (4-6 yrs)", value: "Kindergarten", order: 4 },
  { name: "Gradeschool (5-12 yrs)", value: "Gradeschool", order: 5 },
  { name: "Mixed Age", value: "Mixed Age", order: 6 },
  { name: "Youth (13+)", value: "Youth", order: 7 },
  { name: "Adult", value: "Adult", order: 8 }
  // { name: "School Age (6-9 yrs)", value: "School Age", order: 5 }
];

const centerAgeGroups = [
  { name: "Infant (0-18 mths)", value: "Infant", order: 1 },
  { name: "Toddler (18-30 mths)", value: "Toddler", order: 2 },
  { name: "Preschool (30 mths-6 yrs)", value: "Preschool", order: 3 },
  { name: "Kindergarten (44 mths-7 yrs)", value: "Kindergarten", order: 4 },
  { name: "Mixed Age (0-6)", value: "Mixed Age", order: 5 }
  // { name: "School Age (6-9 yrs)", value: "School Age", order: 5 }
];

const languages = [
  { name: "English", value: "English" },
  { name: "French", value: "French" },
  { name: "Mandarin", value: "Mandarin" },
  { name: "Cantonese", value: "Cantonese" },
  { name: "Punjabi", value: "Punjabi" },
  { name: "Spanish", value: "Spanish" },
  { name: "Tagalog / Filipino", value: "Tagalog / Filipino" },
  { name: "Arabic", value: "Arabic" },
  { name: "German", value: "German" },
  { name: "Italian", value: "Italian" },
  { name: "Portugese", value: "Portugese" },
  { name: "Persian (Farsi)", value: "Persian (Farsi)" },
  { name: "Urdu", value: "Urdu" },
  { name: "Russian", value: "Russian" },
  { name: "Polish", value: "Polish" },
  { name: "Vietnamese", value: "Vietnamese" },
  { name: "Korean", value: "Korean" },
  { name: "Tamil", value: "Tamil" },
  { name: "Hindi", value: "Hindi" },
  { name: "Gujarati", value: "Gujarati" },
  { name: "Greek", value: "Greek" },
  { name: "Ukrainian", value: "Ukrainian" },
  { name: "Dutch", value: "Dutch" },
  { name: "Romanian", value: "Romanian" },
  { name: "Bengali", value: "Bengali" },
  { name: "Creole", value: "Creole" },
  { name: "Hungarian", value: "Hungarian" },
  { name: "Serbian", value: "Serbian" },
  { name: "Croatian", value: "Croatian" },
  { name: "Japanese", value: "Japanese" },
  { name: "Inuktitut", value: "Inuktitut" },
  { name: "Somali", value: "Somali" },
  { name: "Armenian", value: "Armenian" },
  { name: "Turkish", value: "Turkish" },
  { name: "Albanian", value: "Albanian" },
  { name: "Czech", value: "Czech" },
  { name: "Cambodian / Khmer", value: "Cambodian / Khmer" },
  { name: "Bulgarian", value: "Bulgarian" },
  { name: "Hebrew", value: "Hebrew" },
  { name: "Slovak", value: "Slovak" },
  { name: "Finnish", value: "Finnish" },
  { name: "Macedonian", value: "Macedonian" },
  { name: "Danish", value: "Danish" },
  { name: "Lao", value: "Lao" },
  { name: "Pashto", value: "Pashto" },
  { name: "Bosnian", value: "Bosnian" },
  { name: "Sindhi", value: "Sindhi" },
  { name: "Dene", value: "Dene" },
  { name: "Oromo", value: "Oromo" },
  { name: "Malay", value: "Malay" },
  { name: "Slovenian", value: "Slovenian" },
  { name: "Swahili", value: "Swahili" },
  { name: "Kurdish", value: "Kurdish" },
  { name: "Taiwanese", value: "Taiwanese" },
  { name: "Telugu", value: "Telugu" },
  { name: "Afrikaans", value: "Afrikaans" },
  { name: "Nepali", value: "Nepali" },
  { name: "Thai", value: "Thai" },
  { name: "Swedish", value: "Swedish" },
  { name: "Lithuanian", value: "Lithuanian" },
  { name: "Estonian", value: "Estonian" },
  { name: "Maltese", value: "Maltese" },
  { name: "Latvian", value: "Latvian" },
  { name: "Norwegian", value: "Norwegian" }
];

const percentageAmounts = [
  { name: "0%", value: 0 },
  { name: "10%", value: 10 },
  { name: "25%", value: 25 },
  { name: "40%", value: 40 },
  { name: "50%", value: 50 }
];

const percentageHalves = [
  { name: "0%", value: 0 },
  { name: "50%", value: 50 },
  { name: "100%", value: 100 }
];

const DropdownSelect = props => {
  return (
    <div className={props.className ? props.className + " w-100" : "w-100"}>
      <Select
        multi={props.multi ? true : false}
        options={props.options}
        onDropdownOpen={props.open}
        values={
          props.value && Array === props.value.constructor
            ? props.value
            : [props.value]
        }
        name={props.name}
        onChange={values => {
          props.onChange(
            props.name,
            props.multi
              ? values
              : values[0][props.valueField ? props.valueField : "name"]
          );
        }}
        style={{ top: "0px" }}
        sortBy={
          props.sortBy
            ? props.sortBy
            : props.labelField
            ? props.labelField
            : null
        }
        labelField={props.labelField ? props.labelField : "name"}
        valueField={props.valueField ? props.valueField : "name"}
        disabled={props.isDisabled ? true : false}
        closeOnSelect={true}
        searchable={props.searchable ? true : false}   //true is default for react-dropdown-select as of v3.12.0 (current version according to package-lock.json)
        backspaceDelete={props.backspaceDelete === false ? false : true} // true is default
        // optionRenderer={props.optionRenderer ? props.optionRenderer : null}
      />
    </div>
  );
};

const YesNoDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: yesNoOptions,
          valueField: "value",
          value: yesNoOptions.find(o => o.value === props.value)
        }
      }}
    />
  );
};

const GenderDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: genderOptions,
          value: genderOptions.find(o => o.value === props.value) || ""
        }
      }}
    />
  );
};

const ChildMultiSelect = props => {
  const [useChildren] = useGlobal("pendingParent");
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: useChildren.children,
          labelField: "fullName",
          valueField: "id",
          multi: true,
          value: props.value
        }
      }}
    />
  );
};

const MultiProgramCategories = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: MultiProgramCats,
          valueField: "value",
          value: MultiProgramCats.find(o => o.value === props.value) || ""
        }
      }}
    />
  );
};

const MultipleSelectProgramCategories = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: MultiProgramCats,
          valueField: "value",
          multi: true,
          value: props.value
        }
      }}
    />
  );
};

const DateMultiSelect = props => {
  // onClick={_ => document.getElementById("datenumber2").click()}
  const [program] = useGlobal("program");

  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          valueField: "value",
          multi: true,
          value: props.value
        }
      }}
    />
  );
};

const ProgramTypeSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: programTypeOptions,
          value: programTypeOptions.find(o => o.value === props.value) || "",
          valueField: "value"
        }
      }}
    />
  );
};
const UserDropDown = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: props.options,
          value: props.value,
          valueField: "name",
          multi: true
        }
      }}
    />
  );
};

const ResourcesListDropDown = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          
          options: props.options,
          value: props.value,
          valueField: "name",
          multi: true,
          searchable: true,
          backspaceDelete: false
        }
      }}
    />
  );
}

const ProgramTypeSelectMulti = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: programTypeOptionsMulti,
          value: props.value,
          valueField: "value",
          multi: true
        }
      }}
    />
  );
};

const PaymentAccountTypeSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: paymentAccountTypes,
          value: paymentAccountTypes.find(o => o.value === props.value) || "",
          valueField: "value"
        }
      }}
    />
  );
};

const ChildAgeGroupSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: ageGroups,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const ChildCenterAgeGroupSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: centerAgeGroups,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};
const MemberShipSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: membershipTypes,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const LanguageInput = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: languages,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const PercentageOffDropdownSelect = props => {
  const newOpts = percentageAmounts.map(o => ({
    ...o,
    name: o.name + " (off payment)"
  }));
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: newOpts,
          value: newOpts.find(o => o.value === props.value) || 0,
          valueField: "value"
        }
      }}
    />
  );
};

const PercentageExtraDropdownSelect = props => {
  const newOpts = percentageAmounts.map(o => o.name + " (extra charge)");
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: newOpts,
          value: newOpts.find(o => o.value === props.value) || 0,
          valueField: "value"
        }
      }}
    />
  );
};

const PercentageHalvesDropdownSelect = props => {
  const newOpts = percentageHalves.map(o => ({
    ...o,
    name: o.name + " (off payment)"
  }));
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: newOpts,
          value: newOpts.find(o => o.value === props.value) || 0,
          valueField: "value"
        }
      }}
    />
  );
};

const timeRanges = [
  { name: "Please Select", value: -1 },
  { name: "5:00 AM", value: 5 },
  { name: "5:15 AM", value: 5.25 },
  { name: "5:30 AM", value: 5.5 },
  { name: "5:45 AM", value: 5.75 },
  { name: "6:00 AM", value: 6 },
  { name: "6:15 AM", value: 6.25 },
  { name: "6:30 AM", value: 6.5 },
  { name: "6:45 AM", value: 6.75 },
  { name: "7:00 AM", value: 7 },
  { name: "7:15 AM", value: 7.25 },
  { name: "7:30 AM", value: 7.5 },
  { name: "7:45 AM", value: 7.75 },
  { name: "8:00 AM", value: 8 },
  { name: "8:15 AM", value: 8.25 },
  { name: "8:30 AM", value: 8.5 },
  { name: "8:45 AM", value: 8.75 },
  { name: "9:00 AM", value: 9 },
  { name: "9:15 AM", value: 9.25 },
  { name: "9:30 AM", value: 9.5 },
  { name: "9:45 AM", value: 9.75 },
  { name: "10:00 AM", value: 10 },
  { name: "10:15 AM", value: 10.25 },
  { name: "10:30 AM", value: 10.5 },
  { name: "10:45 AM", value: 10.75 },
  { name: "11:00 AM", value: 11 },
  { name: "11:15 AM", value: 11.25 },
  { name: "11:30 AM", value: 11.5 },
  { name: "11:45 AM", value: 11.75 },
  { name: "12:00 PM", value: 12 },
  { name: "12:15 PM", value: 12.25 },
  { name: "12:30 PM", value: 12.5 },
  { name: "12:45 PM", value: 12.75 },
  { name: "1:00 PM", value: 13 },
  { name: "1:15 PM", value: 13.25 },
  { name: "1:30 PM", value: 13.5 },
  { name: "1:45 PM", value: 13.75 },
  { name: "2:00 PM", value: 14 },
  { name: "2:15 PM", value: 14.25 },
  { name: "2:30 PM", value: 14.5 },
  { name: "2:45 PM", value: 14.75 },
  { name: "3:00 PM", value: 15 },
  { name: "3:15 PM", value: 15.25 },
  { name: "3:30 PM", value: 15.5 },
  { name: "3:45 PM", value: 15.75 },
  { name: "4:00 PM", value: 16 },
  { name: "4:15 PM", value: 16.25 },
  { name: "4:30 PM", value: 16.5 },
  { name: "4:45 PM", value: 16.75 },
  { name: "5:00 PM", value: 17 },
  { name: "5:15 PM", value: 17.25 },
  { name: "5:30 PM", value: 17.5 },
  { name: "5:45 PM", value: 17.75 },
  { name: "6:00 PM", value: 18 },
  { name: "6:15 PM", value: 18.25 },
  { name: "6:30 PM", value: 18.5 },
  { name: "6:45 PM", value: 18.75 },
  { name: "7:00 PM", value: 19 },
  { name: "7:15 PM", value: 19.25 },
  { name: "7:30 PM", value: 19.5 },
  { name: "7:45 PM", value: 19.75 },
  { name: "8:00 PM", value: 20 },
  { name: "8:15 PM", value: 20.25 },
  { name: "8:30 PM", value: 20.5 },
  { name: "8:45 PM", value: 20.75 },
  { name: "9:00 PM", value: 21 },
  { name: "9:15 PM", value: 21.25 },
  { name: "9:30 PM", value: 21.5 },
  { name: "9:45 PM", value: 21.75 },
  { name: "10:00 PM", value: 22 },
  { name: "10:15 PM", value: 22.25 },
  { name: "10:30 PM", value: 22.5 },
  { name: "10:45 PM", value: 22.75 },
  { name: "11:00 PM", value: 23 }
];

const afterStartTime = (startTime) => {
  let range = timeRanges;
  return range.filter((time) => {
    return time.value >= startTime;
  })
}

const beforeEndTime = (endTime) => {
  let range = timeRanges;
  return range.filter((time) => {
    return time.value <= endTime;
  })
}

const restrictTime = (start, end) => {
  if(start){
    return afterStartTime(start)
  } else if(end){
    return beforeEndTime(end)
  } else{
    return timeRanges;
  }
}
const TimeDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: restrictTime(props.start, props.end),
          valueField: "value",
          value: timeRanges.find(o => o.value === props.value) || -1
        }
      }}
    />
  );
};

const daysOfWeekRanges = [
  { name: "Please Select", value: "" },
  { name: "Monday - Friday", value: "Monday - Friday" },
  { name: "Monday - Saturday", value: "Monday - Saturday" },
  { name: "Saturday - Sunday", value: "Saturday - Sunday" }
];

const DaysOfWeekRangeDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: daysOfWeekRanges,
          valueField: "value",
          value: daysOfWeekRanges.find(o => o.value === props.value) || ""
        }
      }}
    />
  );
};

const daysOptions = [
  { name: "Sunday", value: "Sunday" },
  { name: "Monday", value: "Monday" },
  { name: "Tuesday", value: "Tuesday" },
  { name: "Wednesday", value: "Wednesday" },
  { name: "Thursday", value: "Thursday" },
  { name: "Friday", value: "Friday" },
  { name: "Saturday", value: "Saturday" }
];

const DaysDropdownSelect = props => {
  let days = daysOptions;
  if (props.range) {
    switch (props.range) {
      case "Monday - Friday":
        days = days.slice(1, 6);
        break;
      case "Monday - Saturday":
        days = days.slice(1, 7);
        break;
      case "Monday - Sunday":
        break;
      case "Weekend Only":
        days = [daysOptions[0], daysOptions[daysOptions.length - 1]];
        break;
      default:
        days = days.slice(1, 6);
    }
  }

  if (props.toremove) {
    days = days.filter(d => {
      return (
        props.toremove.findIndex(ed => {
          return ed.value === d.value;
        }) === -1
      );
    });
  }
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: days,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const mealOptions = [
  { name: "Breakfast", value: "Breakfast", order: 1 },
  { name: "Lunch", value: "Lunch", order: 2 },
  { name: "Dinner", value: "Dinner", order: 3 }
];

const MealDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: mealOptions,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const outdoorPlayAreas = [
  { name: "N/A", value: "N/A" },
  { name: "Private Backyard", value: "Private Backyard" },
  { name: "Shared Backyard", value: "Shared Backyard" },
  { name: "Park", value: "Park" }
];

const OutdoorPlayAreaDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: outdoorPlayAreas,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const petsOptions = [
  { name: "N/A", value: "N/A" },
  { name: "Cat(s)", value: "Cat" },
  { name: "Dog(s)", value: "Dog" },
  { name: "Other", value: "Other" }
];

const PetsDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: petsOptions,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const formGroups = [
  { name: "Registration (General)", value: "Registration (General)", id: 0 },
  { name: "Application (General)", value: "Application (General)", id: 1 },
  { name: "Appointments (General)", value: "Appointments (General)", id: 2 },
  { name: "Contact Us (General)", value: "Contact Us (General)", id: 3 },
  { name: "Survey", value: "Survey", id: 4 },
  { name: "Donation", value: "Donation", id: 5 },
  { name: "Waiver (Children)", value: "Waiver (Children)", id: 6 },
  { name: "Application (Children)", value: "Application (Children)", id: 7 }
];

const FormGroupSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: formGroups,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

export {
  DropdownSelect,
  YesNoDropdownSelect,
  GenderDropdownSelect,
  ChildMultiSelect,
  ProgramTypeSelect,
  ProgramTypeSelectMulti,
  PaymentAccountTypeSelect,
  ChildAgeGroupSelect,
  ChildCenterAgeGroupSelect,
  LanguageInput,
  PercentageOffDropdownSelect,
  PercentageExtraDropdownSelect,
  PercentageHalvesDropdownSelect,
  TimeDropdownSelect,
  DaysOfWeekRangeDropdownSelect,
  DaysDropdownSelect,
  MealDropdownSelect,
  OutdoorPlayAreaDropdownSelect,
  PetsDropdownSelect,
  MemberShipSelect,
  DateMultiSelect,
  UserDropDown,
  ResourcesListDropDown,
  MultiProgramCategories,
  MultipleSelectProgramCategories,
  FormGroupSelect
};
