import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { IconButton, Switch } from "@material-ui/core";
import { formTypes } from "../UI/form";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { STRING_INPUT, SWITCH, DROPDOWN, TITLE } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class EditEventTicketModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ticket_name: this.props.selectedItem.ticket_name,
      ticket_price: parseFloat(this.props.selectedItem.ticket_price),
      ticket_quantity: this.props.selectedItem.ticket_quantity,
      ticket_status: this.props.selectedItem.ticket_status,
      isTaxEnabled: this.props.selectedItem.isTaxEnabled,
      taxInfo: this.props.selectedItem.taxInfo,
      selectedMembership: this.props.selectedItem.selectedMembership,
      published_memberships: []
    };
  }

  async componentDidMount() {
    console.log("OCCURED")
    if (this.state.ticket_status === "Membership") {
      const published_memberships = await this.getPublishedMemberships()

      this.setState({
        ...this.state,
        published_memberships: published_memberships
      })
    }
  }

  async getPublishedMemberships() {
    const ep = `${process.env.REACT_APP_API}/partners/memberships/list`;
    const result = await axios.get(ep);

    // console.log("HERE", );
    return result.data.data.filter(membership => membership.published && !membership.isArchived).map((e, i) => {
      return {
        value: e.id,
        text: e.membership_name
      }
    })
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async savePlan() {
    if (
      this.state.ticket_status !== "Free" &&
      this.state.ticket_price !== 0 &&
      !this.global.organizationInfo.stripeId
    ) {
      toast.error("Denied. Please connect your Bank Account first");
      return;
    }

    const obj = { ...this.state, id: this.props.selectedItem.id };

    console.log("GLOBAL", this.global);
    console.log("1231231231232132132131232323232323213123223121321312321");
    console.log("OBJECTTTT", obj);

    this.setGlobal({
      event: {
        ...this.global.event,
        ["tickets"]: this.global.event.tickets.map(item =>
          JSON.parse(item).id === obj.id ? JSON.stringify(obj) : item
        )
      }
    });

    await this.dispatch.saveEvent();

    this.props.onClose();
  }

  /// ================== NEW MODAL ========================== ///

  taxesOptions = () => {
    const global_organization = this.global.organizationInfo.stripeTaxId;
    console.log("global_organization", global_organization);
    const DATA = [];
    for (let i = 0; i < global_organization.length; i++) {
      DATA.push(JSON.parse(global_organization[i]));
    }
    console.log("DATA", DATA);

    return [
      ...DATA.map((e, i) => ({
        value: i,
        text: e.display_name + " " + e.jurisdiction + " " + e.percentage + "%"
      }))
    ];
  };

  getCurrTaxPlan() {
    for (let taxPlan of this.taxesOptions()) {
      if (
        this.state.taxInfo[0] &&
        taxPlan.text ===
          this.state.taxInfo[0].display_name +
            " " +
            this.state.taxInfo[0].jurisdiction +
            " " +
            this.state.taxInfo[0].percentage +
            "%"
      ) {
        return taxPlan.value;
      }
    }
  }

  getSelectedMembership() {
    // console.log("HERE########", this.state.selectedMembership)
    // if (this.state.selectedMembership && this.state.published_memberships) {
      // const temp = this.state.published_memberships.filter(membership => membership.value === parseInt(this.state.selectedMembership))[0].value
      // return this.state.published_memberships.filter(membership => membership.value === parseInt(this.state.selectedMembership))[0]
      // console.log(temp)
      // return ""
    // }
    // return ""
  }

  getBaseModalFields() {
    const ticketName = {
      type: STRING_INPUT,
      data: {
        name: "Ticket Name",
        required: true,
        handleChange: e => {
          this.setState({
            ticket_name: e.target.value
          });
        },
        value: this.state.ticket_name
      },
      validators: { validateSubmit: () => this.state.ticket_name !== "" }
    };

    const pricingTicket = {
      type: STRING_INPUT,
      data: {
        name: "Pricing for Ticket",
        required: true,
        isDisabled: false,
        type: "number",
        handleChange: e => {
          let price = parseFloat(e.target.value || 0);
          let decimalValue = e.target.value.split(".")[1];

          if (decimalValue && decimalValue.length > 2) {
            return;
          } else if (!e.target.value.endsWith("0")) {
            price = Math.round(price * 100) / 100;
          } else {
            price = e.target.value;
          }

          this.setState({
            ticket_price: price
          });
        },
        value: this.state.ticket_price
      },
      validators: {
        validateSubmit: () =>
          this.state.ticket_price >= 0 && this.state.ticket_price !== ""
      }
    };

    const ticketQuantity = {
      type: STRING_INPUT,
      data: {
        name: "Ticket Quantity",
        required: false,
        type: "number",
        handleChange: e => {
          this.setState({
            ticket_quantity: e.target.value
          });
        },
        value: this.state.ticket_quantity
      }
    };

    const status = {
      type: TITLE,
      data: { name: `Type: ${this.state.ticket_status}` }
    };

    const enableTaxes = {
      type: SWITCH,
      data: {
        name: "Enable Taxes",
        isDisabled: false,
        checked: this.state.isTaxEnabled,
        handleClick: e =>
          this.setState({
            isTaxEnabled: e.target.checked,
            taxInfo: []
          })
      }
    };

    const selectTaxes = {
      type: DROPDOWN,
      data: {
        name: "Tax Plan",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          const item = JSON.parse(
            this.global.organizationInfo.stripeTaxId[e.target.value]
          );
          this.setState({
            taxInfo: [
              {
                id: item.id,
                percentage: item.percentage,
                jurisdiction: item.jurisdiction,
                display_name: item.display_name,
                description: item.description
              }
            ]
          });
        },
        value: this.getCurrTaxPlan(),
        choices: this.taxesOptions()
      },
      validators: { validateSubmit: () => this.state.taxInfo.length > 0}
    };

    const selectMembership = {
      type: DROPDOWN,
      data: {
        name: "Select Membership",
        required: true,
        placeholder: "Please Select",
        handleChange: e => {
          this.setState({
            selectedMembership: e.target.value
          });
        },
        value: parseInt(this.state.selectedMembership),
        choices: this.state.published_memberships
      },
      validators: { validateSubmit: () => this.state.selectedMembership !== "" }
    };

    const fields = [];

    fields.push(ticketName);
    fields.push(pricingTicket);
    fields.push(ticketQuantity);
    fields.push(status);


    if (this.state.ticket_status === "Membership") {
      fields.push(selectMembership);
    }

    if (this.state.ticket_status !== "Free") {
      fields.push(enableTaxes);
    }

    if (this.state.isTaxEnabled) {
      fields.push(selectTaxes);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    console.log("STATE", this.state);
    console.log("PROPS", this.props);
    const addButton = {
      name: "Submit",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: _ => this.savePlan()
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Ticket",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    console.log("this.state.ticket_status", this.state.ticket_status);
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(EditEventTicketModal);
