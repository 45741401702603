import React, { useState } from "react";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { BLUE_BUTTON, RED_BUTTON } from "./baseModal/colours";
import { STRING_INPUT, TITLE, GET_HTML, TEXT_AREA } from "./baseModal/FieldTypes";

/**
 * Modal can be used as a marketing integration confirmation modal.
 *
 * @callback onSubmit Function executed upon user confirmation
 * @callback onClose Function executed when modal is closed.
 *
 * @param {String} header Custom modal header.
 * @param {String | JSX} description Custom modal description.
 * @param {String} docUrl Documentation URL.
 * @param {String} inputLabel Custom input label.
 * @param {String} defaultInput Default input text.
 */

const AddressBar = ({ url }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 sec
    });
  };

  return (
    <div style={styles.container}>
      <span style={styles.text}>{url}</span>
      <div style={styles.copyIcon} onClick={handleCopy}>
        📋
        {copied && <span style={styles.tooltip}>Copied!</span>}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // Center horizontally
    backgroundColor: "#2c2c2c",
    color: "#ffffff",
    padding: "10px 20px", // Equal padding on left and right
    borderRadius: "8px",
    fontFamily: "Arial, sans-serif",
    fontSize: "14px", // Smaller text size
    width: "100%", // Take full width of the modal
    boxSizing: "border-box", // Ensure padding is included in width
    position: "relative",
  },
  text: {
    flex: 1,
    textAlign: "left", // Center text within the AddressBar
    fontSize: "10px", // Smaller text size for the URL
  },
  copyIcon: {
    marginLeft: "10px",
    cursor: "pointer",
    position: "relative",
  },
  tooltip: {
    position: "absolute",
    top: "-25px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#333",
    color: "#fff",
    padding: "5px",
    borderRadius: "5px",
    fontSize: "12px",
    whiteSpace: "nowrap",
  },
};

const ConnectMarketingIntegrationModal = ({
  onSubmit,
  onClose,
  header,
  description,
  docUrl,
  vidUrl,
  inputLabel,
  defaultInput,
  orgProfile
}) => {
  const [value, setValue] = useState("");

  const getModalFields = () => {
    const titleField = {
      type: TITLE,
      data: {
        name: description
      }
    };

    const urlField = {
      type: GET_HTML,
      data: {
        gethtml: () => {
          return (
            <div style={{ display: "flex", flexDirection: "column"}}>
              <a href={docUrl} target="_blank" rel="noopener noreferrer" style={{ color: "blue", marginBottom: "10px", fontSize: "18px" }}>
                1) Documentation
              </a>
              <a href={vidUrl} target="_blank" rel="noopener noreferrer" style={{ color: "blue", fontSize: "18px" }}>
                2) Video Tutorial
              </a>
            </div>
          );
        }
      }
    };

    const confirmField = {
      type: STRING_INPUT,
      data: {
        name: inputLabel,
        required: true,
        placeholder: defaultInput,
        handleChange: e => {
          setValue(e.target.value);
        }
      }
    };

    const facebookCatalogURL = {
      type: GET_HTML,
      data: {
        gethtml: () => {
          // THIS DOES NOT WORK IN LOCAL. Cloudfront reverse proxies this url to our backend to hide our backend url
          //    Facebook catalog does not work in local anyways as it cannot get req from localhost:3001 
          return <AddressBar url={`${window.location.origin}/api/partners/facebook-catalog/${orgProfile.partnerId}`}/>;
        }
      }
    };

    let res = [titleField, urlField];
    res.push((defaultInput === "Facebook Catalog" ? facebookCatalogURL : confirmField));
    return res;
  };

  const getModalButtons = () => {
    const createButton = {
      name: "Confirm",
      buttonColour: BLUE_BUTTON,
      textColour: "#fff",
      handleClick: () => {
        if (!value) {
          toast.error("Please enter a value");
          return;
        }
        onSubmit(value);
        onClose();
      }
    };
    const closeButton = {
      name: "Close",
      buttonColour: RED_BUTTON,
      textColour: "#fff",
      handleClick: () => {
        onClose();
      }
    };
    return [(defaultInput === "Facebook Catalog" ? closeButton : createButton)];
  };

  const getModalProps = () => {
    return {
      title: header,
      fields: getModalFields(),
      buttons: getModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: onClose
    };
  };

  return <BaseModal {...getModalProps()} />;
};

export default withRouter(ConnectMarketingIntegrationModal);
