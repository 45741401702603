import React, { Component } from "reactn";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";
import BaseModal from "./baseModal/BaseModal";
import { CONTENT_BLOCK, STRING_INPUT, DROPDOWN, TITLE } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import { format } from "date-fns";

class CopyExistingEventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_event_title: "",
      copyEvent: "",
      eventList: [],
      canUserCreate: false,
      buttonPressed: false
    };
  }

  async componentDidMount() {
    
    await this.fetchInitialData();
    console.log("global", this.global);
    console.log("state", this.state);
    console.log("props", this.props);
  }

  async fetchInitialData() {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API}/partners/events/list`
      );
      if (resp.data.success) {
        const currentOrder = resp.data.data
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .sort((a, b) => a.display_order - b.display_order);
        this.setState({ eventList: currentOrder });
      } else {
        console.error("error getting event list -----");
      }

      const result = await axios.get(`${process.env.REACT_APP_API}/partners/permissions/events/can_user_create_event`);
      if (result.data.success) {
        this.setState({
          canUserCreate: true
        })
      }
      
    } catch (err) {
      console.error(err);
    }
  }

  copyExistingEvent = async () => {
    const copyFromEvent= { ...this.state.eventList.find(event => parseInt(event.id) === parseInt(this.state.copyEvent)) }

    if (copyFromEvent.event_title === this.state.new_event_title) {
      toast.error("You cannot choose the same name");
      return;
    }
    this.setGlobal({loading: true});
    this.setState({ buttonPressed: true });

    let product_stripe = null;
    const copyOfTickets = [...this.global.event.tickets];
    this.global.event.tickets = [];
    try {
      for (const ticket of copyOfTickets) {
        const parse_ticket = JSON.parse(ticket);
        console.log("parse_ticket", parse_ticket);
        if (parse_ticket.ticket_status === "Free") {
          this.setGlobal({
            event: {
              ...this.global.event,
              product_stripe: product_stripe,
              ["tickets"]: [...this.global.event.tickets, JSON.stringify({
                ...parse_ticket,
                id: this.global.event.tickets.length})]
            }
          });
        } else {
          const ep = `${process.env.REACT_APP_API}/partners/stripe/add_products`;
          const res = await axios.post(ep, {
            ticket: parse_ticket,
            parent: "Event",
            name: this.global.event.event_title,
            product: product_stripe
          });
          product_stripe = res.data.data.product;
          this.setGlobal({
            event: {
              ...this.global.event,
              product_stripe: product_stripe,
              ["tickets"]: [...this.global.event.tickets, JSON.stringify({
                ...parse_ticket,
                id: this.global.event.tickets.length,
                ticket_stripe: res.data.data.id})]
            }
          });
        }
      }
      await this.dispatch.saveEvent();
    } catch (err) {
      console.error(err)
    }
    this.setGlobal({loading: false});
    this.props.onClose();
  }

  getListChoices = () => {
    const eventChoices = this.state.eventList.map(event => {
      return {
        value: event.id,
        text: event.event_title
      };
    })
    return eventChoices;
  };

  getBaseModalFields() {
    const fieldArr = [];

    const copyFromEvent = {
      type: DROPDOWN,
      data: {
        name: "Copy From Event",
        placeholder: "Choose here",
        value: this.state.copyEvent,
        required: true,
        choices: this.getListChoices(),
        handleChange: e => {
          this.setState({ copyEvent: e.target.value })
          let copyFromEvent = { ...this.state.eventList.find(event => parseInt(event.id) === parseInt(e.target.value)) }
          copyFromEvent.id = null;
          copyFromEvent.event_title = "";
          copyFromEvent.display_order = 0;
          copyFromEvent.published = false;
          copyFromEvent.isArchived = false;

          delete copyFromEvent.timezone;
          delete copyFromEvent.createdAt;
          delete copyFromEvent.updatedAt;
          delete copyFromEvent.product_stripe;
          this.setGlobal({
            event: {
              ...copyFromEvent
            }
          });
        }
      },
      validators: { validateSubmit: () => this.state.copyEvent != "" }
    };

    const newEventName = {
      type: STRING_INPUT,
      data: {
        name: "New Event Name",
        placeholder: "Enter new name here",
        required: true,
        value: this.state.new_event_title,
        handleChange: e => {
          this.setState({
            new_event_title: e.target.value
          })
          this.setGlobal({
            event: {
              ...this.global.event,
              event_title: e.target.value
            }
          })
        },
      },
      validators: { validateSubmit: () => this.state.new_event_title != "" }
    }

    fieldArr.push(copyFromEvent);

    if (this.state.copyEvent) {
      fieldArr.push(newEventName);
    }

    return fieldArr;
  }

  getLimitReachedModal = () => {
    const props = {
      title: "Copy Event",
      fields: [
        { type: TITLE, data: { name: "You have reached your event limit." } }
      ],
      handleClose: this.props.onClose
    };
    return <BaseModal {...props} />;
  };

  getBaseModalButtons = () => {
    const copyButton = {
      name: "Copy",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      isDisabled: this.state.buttonPressed,
      handleClick: e => this.copyExistingEvent(e)
    };

    return [copyButton];
  };

  getBaseModalProps = () => {
    console.log("STATE", this.state, this.props);
    return {
      title: "Copy Event",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "49vh",
      handleClose: this.props.onClose
    };
  };

  render() {
    if (!this.state.canUserCreate) {
      return this.getLimitReachedModal()
    }

    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default CopyExistingEventModal;
