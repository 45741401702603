import axios from "axios";

import {
  Reporting1,
  Reporting2,
  Reporting3,
  Reporting4,
  Reporting5,
  Reporting6,
  Reporting7,
  Reporting8,
  Reporting9,
  Reporting10,
  Reporting11,
  Reporting12,
  Reporting13,
  Reporting14,
  Reporting15
} from "../../../assets";

const ReportingData = [
  {
    imageSource: Reporting1,
    link: "/partners/reports/revenue-summary",
    title: "Revenue Summary",
    description:
      "A detailed summary of all revenue and sources over a select period of time"
  },
  {
    imageSource: Reporting2,
    link: "/partners/reports/client-revenue",
    title: "Revenue by Client",
    description:
      "A detailed view of all revenue by select clients over a period of time"
  },
  {
    imageSource: Reporting3,
    link: "/partners/reports/listing-sales",
    title: "Sales by Listings",
    description:
      "A detailed summary of all the sales by select listings, over a period of time"
  },
  {
    imageSource: Reporting4,
    link: "/partners/reports/expense-report",
    title: "Expense Report",
    description:
      "A detailed summary of all expenses tracked over a select period of time"
  },
  {
    imageSource: Reporting5,
    link: "/partners/reports/overdue-invoices",
    title: "Overdue Invoices",
    description:
      "A summary view of all orders & invoices that are currently outstanding"
  },
  {
    imageSource: Reporting6,
    link: "/partners/reports/transactions-report",
    title: "Transactions Report",
    description:
      "A detailed view of all transaction orders over a period of time"
  },
  {
    imageSource: Reporting7,
    link: "/partners/reports/tax-summary",
    title: "Tax Summary",
    description:
      "A detailed summary of all taxes collected and paid over a period of time"
  },
  {
    imageSource: Reporting8,
    link: "/partners/reports/attendance",
    title: "Attendance",
    description:
      "A select summary of attendance of participants over a period of time"
  },
  {
    imageSource: Reporting9,
    link: "/partners/reports/participant-reports",
    title: "Participant Reports",
    description: "A detailed view of a student based on select Reporting"
  },
  {
    imageSource: Reporting10,
    link: "/partners/reports/contacts-report",
    title: "Contacts Report",
    description:
      "A detailed view of your organization's contacts and connections."
  },
  {
    imageSource: Reporting10,
    link: "/partners/reports/invoice-summary",
    title: "Invoice Summary",
    description:
      "A detailed summary of all invoices issued & paid over a select period of time."
  },
  {
    imageSource: Reporting11,
    link: "/partners/reports/discounts-report",
    title: "Discounts Report",
    description:
      "A detailed view of the discounts provided to customers."
  }, {
    imageSource: Reporting12,
    link: "/partners/reports/invites-report",
    title: "Invites Report",
    description:
      "A detailed view of the invitations sent to customers."
  }, {
    imageSource: Reporting13,
    link: "/partners/reports/locations-report",
    title: "Locations Report",
    description:
      "An overview of customers per location."
  }, {
    imageSource: Reporting14,
    link: "/partners/reports/category-report",
    title: "Category Report",
    description:
      "Analytics of programs by Category."
  }, {
    imageSource: Reporting15,
    link: "/partners/reports/program-name-report",
    title: "Program Name Report",
    description:
      "Analytics of programs by Program Name."
  }
  
  
];

export async function getReportsAndAnalyticsPermissions() {
  console.log("ENTERED");
  const feature_category = "Reports%2FAnalytics";
  const ep = new URL(
    `${process.env.REACT_APP_API}/partner/permissions/${feature_category}/`
  );
  console.log(ep);
  const resp = await axios.get(ep);

  return resp.data;
}

export function getReportingDataFromPermissions(permissions) {
  if (!permissions) {
    throw new Error(
      "Error at getReportingDataFromPermissions. permissions argument must be given"
    );
  }

  const result = permissions.map((perm, i) => {
    const reportingdata_filter = ReportingData.filter(
      rd => rd.title === perm.feature
    );
    if (reportingdata_filter.length === 1) {
      return {
        ...perm,
        ...reportingdata_filter[0],
        id: perm.id,
        title: perm.feature
      };
    }
  });

  return result;
}
