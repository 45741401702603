import React from "reactn";
import axios from "axios";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { format, addMonths, addWeeks, getDay } from "date-fns";
import "../../../assets/css/componentSpecificCss/invoiceBuilder.css";
import AddDiscountModal from "../../../components/modals/AddDiscountModal";
import EditDiscountModal from "../../../components/modals/EditDiscountModal";
import CreateDiscountModal from "../../../components/modals/CreateDiscountModal";
import AddTotalDiscountModal from "../../../components/modals/AddTotalSubscriptionDiscountModal";

import { withRouter } from "react-router-dom";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddLineSubscriptionModal from "./AddLineSubscriptionModal";
import PaidOfflineRecurringModal from "../../../components/modals/PaidOfflineRecurringModal";
import SetDraftModal from "../../../components/modals/SetDraftModal";
import SetDraftRecurringModal from "../../../components/modals/SetDraftRecurringModal";
/**
 * Despite the component name, this component is both used to:
 * 1. Edit upcoming stripe subscription invoices.
 * 2. View paid stripe subscription invoices (no editing).
 *
 * This distinction is based on URL -
 * Editing upcoming will have pathname, /recurring/subscription-edit/:subId
 * While viewing will have pathname, /recurring/invoice/:invoiceId
 *
 * Component is reused for the two scenarios because besides the action items
 * (e.g adding line items), the display of the data is still the same.
 */
class SubscriptionEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      order: null,
      invoice: null,
      lines: null,
      anchorEl: null,
      anchorE2: null,
      openCreateDiscountModal: false,
      openAddTotalDiscountModal: false,
      noEdit: true
    };

    this.headings = [
      { label: "Item Name", style: { textAlign: "left" } },
      { label: "Rate", style: { textAlign: "right" } },
      { label: "Discount", style: { textAlign: "right" } },
      { label: "Quantity", style: { textAlign: "right" } },
      { label: "Amount", style: { textAlign: "right" } },
      { label: "", style: { textAlign: "right" } }
    ];

    // Not sure if this is needed. Maybe just placeholder.
    this.body = [
      [
        { value: "Music School" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "$1000" }
      ],
      [
        { value: "Coupon: 10% Off" },
        { value: "" },
        { value: "-$100" },
        { value: "" },
        { value: "" }
      ],
      [
        { value: "Sub-Total", style: { fontWeight: "bold", color: "black" } },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "$900", style: { fontWeight: "bold", color: "black" } }
      ]
    ];

    // Not sure if this is needed. Maybe just placeholder.
    this.foot = [
      [
        { value: "Total", style: { fontWeight: "bold", color: "black" } },
        { value: "" },
        { value: "$980", style: { fontWeight: "bold", color: "black" } }
      ]
    ];
  }

  async componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    this.setGlobal({ loading: true });

    const regex = /^\/partners\/recurring\/invoice\//;
    console.log("regex", regex.test(this.props.location.pathname));

    // Use regex to check whether this is a paid invoice or upcoming invoice.
    // (see comment above this component definition for more details)
    if (regex.test(this.props.location.pathname)) {
      const invoiceId = this.props.match.params.invoiceId;
      const ep = `${process.env.REACT_APP_API}/partners/sub-invoice/invoice/${invoiceId}`;
      const results = await axios.get(ep);
      console.log("results", results);
      console.log("THE RESULTS", results);
      if (results.data.success) {
        // State has properties - "invoice", "lines", "order"
        this.setState(results.data.data);
        this.setState({ noEdit: true });
        this.setState(
          {
            referenceNumber: `po${results.data.data.order.id}, uid${results.data.data.order.dsUserId}, ${results.data.data.order.stripe_transaction_id}, ${results.data.data.order.stripe_invoice_id}`
          },
          () => console.log("reference number", this.state.referenceNumber)
        );
      }
    } else {
      const subId = this.props.match.params.subId;

      const ep = `${process.env.REACT_APP_API}/partners/sub-invoice/${subId}`;
      const results = await axios.get(ep);
      console.log("results", results);
      console.log("REULST DATA", results);
      if (results.data.success) {
        // State has properties - "invoice", "lines", "order"
        this.setState(results.data.data);
        this.setState({ noEdit: false });
        this.setState(
          {
            referenceNumber: `po${results?.data?.data?.order?.id}, uid${results?.data?.data?.order?.dsUserId}, ${results?.data?.data?.order?.stripe_transaction_id}`
          },
          () => console.log("reference number", this.state.referenceNumber)
        );
      }
    }
    this.setGlobal({
      partnerPathway: [
        ...this.global.partnerPathway.slice(0, 1),
        { label: "Recurring Payments", to: "/recurring" },
        {
          label: this.state.order
            ? this.state.order.displayName
            : "Recurring Payment",
          to: this.props.location.pathname.replace(/^\/partners/, "") // remove /partners from pathname
        }
      ]
    });

    this.setGlobal({ loading: false });
  };

  saveLineItem = async () => {
    this.fetchInitialData();
  };

  /**
   * Function to remove a line item by invoice item id.
   */
  removeItem = async id => {
    if (this.state.noEdit) {
      return;
    }
    this.setGlobal({ loading: true });
    this.setState({ anchorEl: null });
    const ep = `${process.env.REACT_APP_API}/partners/sub-invoice/line/${id}/${this.state.invoice.subscription}`;
    await axios.delete(ep);
    await this.fetchInitialData();
  };

  /**
   * Remove total discount
   */
  removeTotalDiscount = async () => {
    if (this.state.noEdit) {
      return;
    }
    this.setGlobal({ loading: true });
    const ep = `${process.env.REACT_APP_API}/partners/sub-invoice/${this.state.invoice.subscription}/discount`;
    await axios.delete(ep);
    await this.fetchInitialData();
  };

  saveAndGoBack = async () => {
    this.props.history.push("/partners/recurring");
  };

  render() {
    const { invoice, lines, order } = this.state;

    const toMoney = amountCents => {
      return `$${(amountCents / 100).toFixed(2)}`;
    };

    let foot = [];
    if (invoice) {
      // Aggregates all discounts applied individually to the line items.
      const itemDiscount = lines.reduce((a, c) => {
        return (
          a +
          c.discount_amounts.reduce((b, d) => {
            if (invoice.discounts[0] && invoice.discounts[0] === d.discount)
              return b;
            return b + d.amount;
          }, 0)
        );
      }, 0);

      // Find the amount of the overall discount applied to the entire invoice.
      const overallDiscount = lines.reduce((a, c) => {
        return (
          a +
          c.discount_amounts.reduce((b, d) => {
            if (invoice.discounts[0] && invoice.discounts[0] === d.discount)
              return b + d.amount;
            return b;
          }, 0)
        );
      }, 0);

      let amountStyle = {
        fontWeight: "bold",
        color: "black",
        textAlign: "right"
      };

      // Push subtotal, discounts, tax, and total to the table.
      foot.push([
        { value: "Sub Total" },
        { value: "" },
        { value: itemDiscount > 0 ? `-${toMoney(itemDiscount)} ` : "---" },
        { value: "" },
        { value: toMoney(invoice.subtotal), style: amountStyle }
      ]);
      if (invoice.discounts[0]) {
        foot.push([
          { value: "Overall Discount" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: `-${toMoney(overallDiscount)}` }
        ]);
      }
      if (invoice.tax) {
        foot.push([
          { value: "Tax" },
          { value: "" },
          { value: "" },
          { value: "" },
          //{ value: toMoney(invoice.tax) }
          { value: toMoney(parseInt(order.taxAmount)) }
        ]);
      }

      if (invoice.pause_collection) {
        foot.push([
          { value: "Enrollment Paused" },
          { value: "" },
          { value: "" },
          { value: "" },
          {
            value: `-${toMoney(
              invoice.subtotal - overallDiscount + invoice.tax
            )}`
          }
        ]);
      }
      console.log("INVOICE SE", invoice, order);
      foot.push([
        {
          value: "Total",
          lineText: `${
            order.transaction_cost
              ? ` (Inclusive of Credit Card ${
                  order.transaction_cost ? "1.5%" : "3%"
                })`
              : ""
          }`
        },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: toMoney(invoice.amount_due), style: amountStyle }
      ]);
    }

    return (
      console.log("this.state...", this.state),
      (
        <div className="admin">
          {this.state.openPaidOfflineRecurringModal && (
            <PaidOfflineRecurringModal
              /*             selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails} */
              orderId={this.state.order?.id}
              onClose={_ => {
                this.setState({ openPaidOfflineRecurringModal: false });
                // this.fetchInitialData();
              }}
              refreshPage={e => {
                this.fetchInitialData();
              }}
              /* organizationName={this.global.organizationInfo.organization_title}
            userEmail={customer[0].email}
            userName={customer[0].displayName}
            totalAmount={this.state.finalTotal} */
            />
          )}

          {this.state.openSetDraftRecurringModal && (
            <SetDraftRecurringModal
              /*             selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails} */
              orderId={this.state.order?.id}
              pageId={this.state.order?.stripe_transaction_id}
              onClose={_ => {
                this.setState({ openSetDraftRecurringModal: false });
                // this.fetchInitialData();
              }}
              refreshPage={e => {
                this.fetchInitialData();
              }}
            />
          )}

          {this.state.openSetDraftModal && (
            <SetDraftModal
              /*             selectedItem={this.state.selectedItem}
            selectedItemIndex={this.state.selectedItemIndex}
            invoiceDetails={this.state.invoiceDetails} */
              onClose={_ => {
                this.setState({ openSetDraftModal: false });
                // this.fetchInitialData();
              }}
              refreshPage={e => {
                this.fetchInitialData();
              }}
            />
          )}

          {this.state.openAddItemModal && (
            <AddLineSubscriptionModal
              onClose={_ => {
                this.setState({ openAddItemModal: false });
              }}
              saveItem={() => {
                this.saveLineItem();
              }}
              customerId={invoice.customer}
              subscriptionId={invoice.subscription}
            />
          )}

          {this.state.openAddDiscountModal && (
            <AddDiscountModal
              selectedItem={this.state.selectedItem}
              selectedItemIndex={this.state.selectedItemIndex}
              invoiceDetails={this.state.invoiceDetails}
              onClose={_ => {
                this.setState({ openAddDiscountModal: false });
                this.fetchInitialData();
              }}
              openCreateDiscountModal={e =>
                this.setState({
                  openCreateDiscountModal: e,
                  openAddDiscountModal: false
                })
              }
              addedDiscount={e => this.fetchInitialData()}
              isStripe={true}
            />
          )}

          {this.state.openCreateDiscountModal && (
            <CreateDiscountModal
              addedDiscount={e =>
                this.setState({
                  openAddDiscountModal: true,
                  openCreateDiscountModal: false
                })
              }
              onClose={_ => {
                this.setState({ openCreateDiscountModal: false });
              }}
            />
          )}

          {this.state.openEditDiscountModal && (
            <EditDiscountModal
              selectedItem={this.state.selectedItem}
              selectedItemIndex={this.state.selectedItemIndex}
              invoiceDetails={this.state.invoiceDetails}
              onClose={_ => {
                this.setState({ openEditDiscountModal: false });
                this.fetchInitialData();
              }}
              removeDiscount={_ => this.fetchInitialData()}
              editDiscount={e => this.fetchInitialData()}
              isStripe={true}
              discountId={this.state.discountId}
            />
          )}

          {this.state.openAddTotalDiscountModal && (
            <AddTotalDiscountModal
              addedDiscount={e => this.fetchInitialData()}
              footData={this.state.footData}
              onClose={_ => {
                this.setState({ openAddTotalDiscountModal: false });
                this.fetchInitialData();
              }}
              subTotal={this.state.subTotal}
              isStripe={true}
              invoice={invoice}
              allowForever={true}
            />
          )}

          <div className="container-fluid adminprofiles">
            <div style={{ paddingLeft: "0px" }} className="cont">
              <h1>{""}</h1>
            </div>

            <div
              className="invoice-receipt-container"
              style={{ marginBottom: "10px" }}
            >
              <div className="invoice-recipt-header-container">
                <div
                  className="order-header-background"
                  style={{
                    minHeight: 270,
                    backgroundColor: "white",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "26px",
                    borderTopRightRadius: "26px"
                  }}
                />

                <div className="order-name-section">
                  <div className="invoice-name">
                    {this.global.organizationInfo.organization_title}
                  </div>
                  <div className="invoice-email">
                    {this.global.organizationInfo.address1}
                    {this.global.organizationInfo.address2
                      ? `, ${this.global.organizationInfo.address2}`
                      : ""}
                  </div>
                  <div className="invoice-email">
                    {this.global.organizationInfo.city}
                    {this.global.organizationInfo.province
                      ? `, ${this.global.organizationInfo.province}`
                      : ""}
                    {this.global.organizationInfo.country
                      ? `, ${this.global.organizationInfo.country}`
                      : ""}
                    {this.global.organizationInfo.postalcode
                      ? "\xa0\xa0" + this.global.organizationInfo.postalcode
                      : ""}
                  </div>
                  <div className="invoice-email">
                    {this.global.dsUser.email}
                  </div>
                  <div className="invoice-email">
                    {this.global.dsUser.phone}
                  </div>
                  {this.global.organizationInfo.tax_code !== "" && (
                    <div style={{ fontSize: "14px" }}>
                      <strong>Tax Code: </strong>
                      {this.global.organizationInfo.tax_code}
                    </div>
                  )}
                </div>
                {invoice && (
                  <div
                    className="date-section"
                    // style={{ whiteSpace: "nowrap", right: "25px" }}
                  >
                    {this.state.noEdit ? (
                      <div className="date-item-list">
                        <div className="invoice-date-range">Paid on</div>
                        <DatePicker
                          selected={new Date(invoice.created * 1000)}
                          onChange={date => {}}
                          dateFormat="MMMM d, yyyy"
                          className="invoice-control"
                          disabled={true}
                        />
                      </div>
                    ) : (
                      <div className="date-item-list">
                        <div className="invoice-due-date">Issue Date</div>
                        <DatePicker
                          selected={new Date(invoice.next_payment_attempt * 1000)}
                          onChange={date => {}}
                          dateFormat="MMMM d, yyyy"
                          className="invoice-control"
                          disabled={true}
                        />
                      </div>
                    )}
                    <div className="date-item-list">
                      <div className="invoice-date-range">
                        Service Start Date
                      </div>
                        <DatePicker
                          selected={new Date(invoice.period_start * 1000)}
                          onChange={date => {}}
                          dateFormat="MMMM d, yyyy"
                          className="invoice-control"
                          disabled={true}
                        />
                    </div>

                    <div className="date-item-list">
                      <div className="invoice-date-range">Service End Date</div>
                        <DatePicker
                          selected={new Date(invoice.period_end * 1000)}
                          onChange={date => {}}
                          dateFormat="MMMM d, yyyy"
                          className="invoice-control"
                          disabled={true}
                        />
                    </div>
                  </div>
                )}

                {order && (
                  <div className="invoice-billing-info-section">
                    <div className="invoice-bill-title">Bill To:</div>
                    <div className="invoice-bill-name">{order.displayName}</div>
                    <div className="invoice-bill-address1">{order.address}</div>
                    <div className="invoice-bill-address2">
                      {order.city}
                      {order.province ? `, ${order.province}` : ""}
                      {order.postalCode ? `, ${order.postalCode}` : ""}
                    </div>
                    <div className="invoice-bill-address1">{order.email}</div>
                    {/* <div className="invoice-bill-address1">{order.address}</div>
                  <div className="invoice-bill-address2">
                    {order.city}, {order.province}, {order.postalCode}
                  </div> */}
                  </div>
                )}
              </div>

              <div className="invoice-receipt-table-container">
                <table className="order-receipt-table">
                  <thead>
                    <tr className="order-receipt-header-section">
                      {this.headings?.map(heading => {
                        return (
                          <th
                            key={heading.label}
                            style={heading.style}
                            className="invoice-receipt-table-header"
                          >
                            {heading.label}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {lines?.map((row, rowIndex) => {
                      const unitAmount =
                        row.unit_amount || row.price?.unit_amount;
                      const quantity = row.quantity || 1;
                      const discountAmount = row.discount_amounts.reduce(
                        (a, c) => {
                          if (
                            invoice.discounts[0] &&
                            c.discount === invoice.discounts[0]
                          )
                            return a;
                          return a + c.amount;
                        },
                        0
                      );
                      return (
                        <tr
                          key={rowIndex}
                          className="order-receipt-item-section"
                        >
                          <td className={`invoice-receipt-item-content`}>
                            <span>{row.description}</span>
                          </td>
                          <td
                            className={`invoice-receipt-item-content align-right`}
                          >
                            <span>{toMoney(unitAmount)}</span>
                          </td>
                          <td
                            className={`invoice-receipt-item-content align-right`}
                          >
                            <span>
                              {discountAmount > 0
                                ? `-${toMoney(discountAmount)}`
                                : "---"}
                            </span>
                          </td>
                          <td
                            className={`invoice-receipt-item-content align-right`}
                          >
                            <span>{quantity}</span>
                          </td>
                          <td
                            className={`invoice-receipt-item-content align-right`}
                          >
                            <span>
                              {toMoney(quantity * unitAmount - discountAmount)}
                            </span>
                          </td>
                          {rowIndex !== 0 && !this.state.noEdit && (
                            <td className={`invoice-receipt-item-menu`}>
                              <i
                                className="fas fa-ellipsis-v"
                                style={{
                                  color: "#2639d2",
                                  marginRight: "20px",
                                  cursor: "pointer",
                                  position: "relative"
                                }}
                                onClick={event => {
                                  if (
                                    this.state.invoice_status === "Cancelled" ||
                                    row.description.includes("Extended") ||
                                    row.description.includes("Reduced")
                                  )
                                    return;
                                  this.setState({
                                    anchorEl: event.currentTarget,
                                    selectedItem: row.id
                                  });
                                }}
                                aria-label="more"
                                aria-controls="add-menu"
                                aria-haspopup="true"
                              ></i>
                              <Menu
                                id="add-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={_ => this.setState({ anchorEl: null })}
                                PaperProps={{
                                  style: {
                                    // maxHeight: "45px",
                                    // width: '100%',
                                    minHeight: "30px",
                                    margin: 0,
                                    display: "flex",
                                    justifyContent: "center"
                                  }
                                }}
                              >
                                {row.discounts.length === 0 ? (
                                  <MenuItem
                                    key="add-discount"
                                    onClick={_ => {
                                      this.setState({
                                        openAddDiscountModal: true,
                                        anchorEl: null
                                      });
                                    }}
                                  >
                                    Add Discount
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    key="edit-discount"
                                    onClick={_ => {
                                      this.setState({
                                        openEditDiscountModal: true,
                                        anchorEl: null,
                                        discountId: row.metadata.discountId
                                      });
                                    }}
                                  >
                                    Edit Discount
                                  </MenuItem>
                                )}

                                <MenuItem
                                  key="remove-item"
                                  onClick={_ => {
                                    console.log(row.id);
                                    this.removeItem(this.state.selectedItem);
                                  }}
                                >
                                  Remove Item
                                </MenuItem>
                              </Menu>

                              <Menu
                                id="edit-menu"
                                anchorEl={this.state.anchorE2}
                                keepMounted
                                open={Boolean(this.state.anchorE2)}
                                onClose={_ => this.setState({ anchorE2: null })}
                                PaperProps={{
                                  style: {
                                    // maxHeight: "45px",
                                    // width: '100%',
                                    minHeight: "30px",
                                    margin: 0,
                                    display: "flex",
                                    justifyContent: "center"
                                  }
                                }}
                              >
                                <MenuItem
                                  key="edit-discount"
                                  onClick={_ => {
                                    this.setState({
                                      openEditDiscountModal: true,
                                      selectedItem: row,
                                      anchorE2: null
                                    });
                                  }}
                                >
                                  Edit Discount
                                </MenuItem>
                                <MenuItem
                                  key="remove-item"
                                  onClick={_ => {
                                    console.log("remove item");
                                    this.removeItem(row);
                                  }}
                                >
                                  Remove Item
                                </MenuItem>
                              </Menu>
                            </td>
                          )}
                        </tr>
                      );
                    })}

                    {this.state.order?.invoice_status !== "Cancelled" &&
                      this.state.order?.invoice_status !== "Issued" &&
                      this.state.order?.invoice_status !== "Paid Offline" &&
                      !this.state.noEdit && (
                        <tr className="add-item-btn-section">
                          <td
                            className="add-item-btn"
                            onClick={_ => {
                              if (this.state.invoice_status === "Cancelled")
                                return;
                              this.setState({ openAddItemModal: true });
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              <span>Add Item</span>
                            </div>
                          </td>
                        </tr>
                      )}
                  </tbody>

                  <tfoot style={{ fontWeight: "bold", color: "black" }}>
                    {foot?.map((row, rowIndex) => {
                      return (
                        <tr
                          key={rowIndex}
                          className="invoice-receipt-footer-section"
                        >
                          {row.map((cell, cellIndex) => {
                            return (
                              <td
                                key={cellIndex}
                                style={this.headings?.[cellIndex].style}
                                className={`invoice-receipt-item-content ${
                                  this.headings?.[cellIndex].className || ""
                                }`}
                              >
                                <span style={cell.style}>{cell.value}</span>
                                {cell.lineText ? (
                                  <span
                                    style={{
                                      color: "#696969",
                                      fontStyle: "italic",
                                      fontWeight: "400"
                                    }}
                                  >
                                    {" "}
                                    {cell.lineText}
                                  </span>
                                ) : null}
                                {console.log(
                                  "FOOT DATA",
                                  cell,
                                  foot,
                                  this.state.order
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tfoot>
                </table>

                {/* Quick check to see if there is an applied coupon/promotion
              If so, disable the overall discount process. */}
                <div>
                  {this.state.order?.invoice_status !== "Cancelled" &&
                    this.state.order?.invoice_status !== "Issued" &&
                    this.state.order?.invoice_status !== "Paid Offline" && (
                      <div
                        className="discount-total"
                        onClick={_ => {
                          if (this.state.order?.invoice_status === "Cancelled")
                            return;
                          this.setState({
                            openPaidOfflineRecurringModal: true
                          });
                        }}
                      >
                        <i
                          className="fas fa-money-bill-wave"
                          style={{
                            fontSize: "12px",
                            marginRight: "5px"
                          }}
                        ></i>{" "}
                        Paid Offline
                      </div>
                    )}
                  {this.state.order?.invoice_status === "Paid Offline" && (
                    <div
                      className="discount-total"
                      onClick={_ => {
                        if (this.state.order.invoice_status === "Cancelled")
                          return;
                        this.setState({ openSetDraftRecurringModal: true });
                      }}
                    >
                      <i
                        className="fas fa-money-bill-wave"
                        style={{
                          fontSize: "12px",
                          marginRight: "5px"
                        }}
                      ></i>{" "}
                      Back to Draft
                    </div>
                  )}
                  {this.state.order?.invoice_status === "Paid Offline"
                    ? null
                    : invoice &&
                      // order.couponId == null &&
                      // order.promotionId == null &&
                      !this.state.noEdit && (
                        <div className="total-discount-container">
                          {invoice.discounts.length === 0 ? (
                            <div
                              className="discount-total"
                              onClick={_ => {
                                if (this.state.invoice_status === "Cancelled")
                                  return;
                                this.setState({
                                  openAddTotalDiscountModal: true
                                });
                              }}
                            >
                              <i
                                className="fas fa-tag"
                                style={{
                                  transform: "rotate(90deg)",
                                  fontSize: "12px",
                                  marginRight: "5px"
                                }}
                              ></i>{" "}
                              Add Discount or Coupon
                            </div>
                          ) : (
                            <div
                              className="discount-total"
                              onClick={_ => {
                                if (this.state.invoice_status === "Cancelled")
                                  return;
                                this.removeTotalDiscount();
                              }}
                            >
                              <i
                                className="fas fa-trash"
                                style={{ fontSize: "12px", marginRight: "5px" }}
                              ></i>{" "}
                              Remove Discount or Coupon
                            </div>
                          )}
                        </div>
                      )}
                </div>
              </div>
              <div className="order-receipt-footer"></div>
            </div>

            <p
              style={{
                fontSize: "8px",
                textAlign: "center",
                fontFamily: "monospace",
                color: "transparent"
              }}
            >
              {this.state.referenceNumber}
            </p>

            <div
              style={{
                display: "flex",
                width: "80%",
                margin: "auto",
                marginBottom: "30px",
                justifyContent: "center"
              }}
            >
              <div
                className="add-item-btn"
                style={{
                  background: "#b2b8c7",
                  border: "1px solid #b2b8c7"
                }}
                onClick={_ => this.saveAndGoBack()}
              >
                Back & Save
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default withRouter(SubscriptionEditor);
