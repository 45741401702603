import Input from "./Input";
import Select from "./Select";
import DateInputs from "./DateInput";
import MultiInput from "./MultiInput";
import {
  DropdownSelect,
  YesNoDropdownSelect,
  GenderDropdownSelect,
  ChildMultiSelect,
  ProgramTypeSelect,
  PaymentAccountTypeSelect,
  ChildAgeGroupSelect,
  ChildCenterAgeGroupSelect,
  LanguageInput,
  PercentageOffDropdownSelect,
  PercentageExtraDropdownSelect,
  PercentageHalvesDropdownSelect,
  TimeDropdownSelect,
  DaysOfWeekRangeDropdownSelect,
  PetsDropdownSelect,
  OutdoorPlayAreaDropdownSelect,
  DaysDropdownSelect,
  MemberShipSelect,
  MealDropdownSelect,
  ProgramTypeSelectMulti,
  DateMultiSelect,
  UserDropDown,
  ResourcesListDropDown,
  MultiProgramCategories,
  MultipleSelectProgramCategories,
  FormGroupSelect
} from "./DropdownSelect";
import BoxFormTabbed from "./BoxFormTabbed";
import BoxForm from "./BoxForm";
import Form from "./Form";
import TextArea from "./TextArea";
import SelectOptions from "./SelectOptions";

const formTypes = {
  Date: DateInputs.DateInput,
  DateOnly: DateInputs.DateOnlyInput,
  DateInputStart: DateInputs.DateInputStart,
  Datewith: DateInputs.DateWithCheckMark,
  MonthYearInput: DateInputs.MonthYearInput,
  Select,
  Input,
  LanguageSelect: LanguageInput,
  MultiInput,
  DropdownSelect,
  YesNoDropdownSelect,
  GenderDropdownSelect,
  ChildMultiSelect,
  ProgramTypeSelect,
  PaymentAccountTypeSelect,
  ChildAgeGroupSelect,
  ChildCenterAgeGroupSelect,
  PercentageOffDropdownSelect,
  PercentageExtraDropdownSelect,
  MemberShipSelect,
  PercentageHalvesDropdownSelect,
  TimeDropdownSelect,
  DaysOfWeekRangeDropdownSelect,
  DaysDropdownSelect,
  TextArea,
  MealDropdownSelect,
  PetsDropdownSelect,
  OutdoorPlayAreaDropdownSelect,
  SelectOptions,
  ProgramTypeSelectMulti,
  DateMultiSelect,
  UserDropDown,
  ResourcesListDropDown,
  MultiProgramCategories,
  MultipleSelectProgramCategories,
  FormGroupSelect
};

export { Input, Select, formTypes, BoxFormTabbed, BoxForm, Form };
