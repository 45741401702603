import React from "reactn";
import PropTypes from "prop-types";
import "react-activity-feed/dist/index.css";
import { withRouter } from "react-router-dom";
import { MsgIcon, CalendarIcon } from "../../assets";
import { Link } from "react-router-dom";
import Avatar from "../../assets/img/msgicon.png";
import EduAvatar from "../../assets/img/educator.png";
import "../../assets/css/componentSpecificCss/topBar.css";
import axios from "axios";
import Notifications from "./notifications/Notifications";
import Breadcrumbs from "./Breadcrumbs";
import { SearchBar } from "../partner/SearchBar";
import { SearchResultsList } from "../partner/SearchResultsList";

class TopBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showTeam: true,
      results: [],
      isFocused: false,
      timeoutId: null,
      search: false,
      searchValue: "",
      showResultsList: true,
      fetchOrganizerData: false,
      showIntegrations: true,
      showReports: true
    };
  }

  async componentDidMount() {
    // window.onclick = e => {
    //   console.log("HAPPENING!!!!")
    //   if (
    //     e.target !== document.getElementById("popupcontainer") &&
    //     !document.getElementById("popupcontainer").contains(e.target)
    //   )
    //   this.setState({ showMenu: false });
    // };

    if (this.global.dsUser.sub_user === true) {
      this.setState({ showTeam: false });
    }

    if (this.global.dsUser.sub_user && this.global.dsUser.role) {
      const ep = `${process.env.REACT_APP_API}/partners/roles/${this.global.dsUser.role}`;
      const cb = await axios.get(ep);

      if (cb.data.success) {
        this.setState({
          showTeam: cb.data.data[0].role === "Administrator" ? true : false,
          role: cb.data.data
        });
      }
    }


  }

  fetchPermissions = async () => {
    const integrationsEp = `${process.env.REACT_APP_API}/partner/permissions/Marketing%20Integrations`;
    const integrationsRes = await axios.get(integrationsEp);
    const reportsEp = `${process.env.REACT_APP_API}/partner/permissions/Reports%2FAnalytics`;
    const reportsRes = await axios.get(reportsEp);
    this.setState({
      showIntegrations: Boolean(integrationsRes.data.data.filter(e => e.has_access).length),
      showReports: Boolean(reportsRes.data.data.filter(e => e.has_access).length)
    })
    
    console.log("LOOOK GERE", integrationsRes.data.data.filter(e => e.has_access).length);
  }

  setIsFocused = isFocused => {
    this.setState({ isFocused });
  };

  setSearch = (results, searchValue) => {
    clearTimeout(this.state.timeoutId);
    this.setState({
      results: [],
      fetchOrganizerData: false,
      showResultsList: true,
      searchValue: searchValue
    });
    this.state.timeoutId = setTimeout(() => {
      this.fetchData(results, searchValue);
    }, 2000);
  };

  fetchData = async (allData, value) => {
    this.setState({ fetchOrganizerData: true });

    const resultsArr = [];
    for (const user of allData) {
      if (user && value && value.length > 2 && value !== "") {
        const nameSearch =
          user.displayName &&
          user.displayName.toLowerCase().replace(/\s/g, "").includes(value);
        const emailSearch =
          user.email &&
          user.email.toLowerCase().replace(/\s/g, "").includes(value);
        const childSearch =
          user.childrens !== null &&
          user.childrens.some(child => {
            return (
              child.fullName &&
              child.fullName.toLowerCase().replace(/\s/g, "").includes(value)
            );
          });
        let searchedChild = null;
        if (nameSearch || emailSearch) {
          resultsArr.push({ ...user, isSearchByChild: searchedChild });
        } else if (childSearch) {
          user.childrens.some(child => {
            if (
              child.fullName &&
              child.fullName.toLowerCase().replace(/\s/g, "").includes(value)
            ) {
              searchedChild = child.fullName;
            }
          });
          resultsArr.push({ ...user, isSearchByChild: searchedChild });
        }
      }
    }

    this.setState({ results: resultsArr });
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
    this.fetchPermissions();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = event => {
    const componentNode = document.getElementById("user-search-bar");
    const userDropdown = document.getElementById("userDropdown");
    const dropDownMenu = document.getElementById("popupcontainer");

    if (componentNode && !componentNode.contains(event.target)) {
      this.setState({ showResultsList: false });
    }

    if (dropDownMenu && !userDropdown.contains(event.target) && !dropDownMenu.contains(event.target)) {
      this.setState({ showMenu: false });
    }
  };

  render() {
    const { ConsoleRoute } = this.props;
    const access = this.global.dsUser.accesspoints;
    return (
      <nav
        id="user-search-bar"
        className="navbar navbar-expand navbar-light bg-white topbar static-top shadow"
      >
        <div>
          {!this.global.noShow && (
            // Breadcrumbs for partner layout.
            <Breadcrumbs />
          )}
        </div>
        {/* <!-- Topbar Navbar --> */}
        <div className="search-bar-container">
          <SearchBar
            setIsFocused={this.setIsFocused}
            setSearch={this.setSearch}
            partnerId={this.global.dsUser.id}
          />
          {(this.state.showResultsList || this.state.isFocused) && (
            <SearchResultsList
              results={this.state.results}
              searchValue={this.state.searchValue}
              fetchOrganizerData={this.state.fetchOrganizerData}
            />
          )}
        </div>
        <ul className="navbar-nav top-navbar ml-auto">
          {this.global.dsUser.accountType !== "Partner" && (
            <>
              <li className="w-100">
                <div className="v-center">
                  <Link to="/">DS Home</Link>
                </div>
              </li>
              <div className="topbar-divider d-none d-sm-block" />
            </>
          )}
          {(!this.global.dsUser.sub_user ||
            access.Account?.Notifications?.read) && <Notifications />}
          {/* <!-- Nav Item - Alerts --> */}
          <div className="topbar-divider d-none d-sm-block" />

          <li className="nav-item dropdown no-arrow">
            <Link
              className="nav-link nameofc"
              to={`${ConsoleRoute}/communications`}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img src={MsgIcon} alt="" />
              {this.global.unread && <div className="bluecircletop"></div>}
            </Link>
          </li>

          <div className="topbar-divider d-none d-sm-block" />

          <li
            className="nav-item dropdown no-arrow"
            style={{ display: ConsoleRoute === "/admin" ? "none" : "flex" }}
          >
            <Link
              className="nav-link nameofc"
              to={`${ConsoleRoute}/calendar`}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <img src={CalendarIcon} alt="" />
              {/* <div className="bluecircletop" /> */}
            </Link>
          </li>
          {ConsoleRoute === "/admin" || (
            <div className="topbar-divider d-none d-sm-block" />
          )}
          <li
            className="nav-item dropdown no-arrow"
            style={{ position: "relative", cursor: "pointer"}}
            onClick={e => {
              if (this.global.dsUser.accountType === "Partner") {
                this.setState({ showMenu: !this.state.showMenu });
              }
            }}
          >
            <a
              className="nav-link dropdown-toggle nameofc"
              // href="#/"
              id="userDropdown"
              role="button"
            >
              <div
                className="divforimg user-img-top-bar"
                style={{
                  backgroundImage: `url(${
                    this.global.dsUser.photo
                      ? `${process.env.REACT_APP_DS_FILES_S3}/${this.global.dsUser.photo}`
                      : this.global.dsUser.accountType === "Educator"
                      ? EduAvatar
                      : Avatar
                  })`,
                  backgroundPosition: "center",
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  backgroundSize: "cover"
                }}
              ></div>
              <span
                className="mr-2 d-none d-lg-inline text-gray-600 small"
                style={{ maxWidth: "80px" }}
              >
                {this.global.dsUser.sub_user
                  ? this.global.dsUser.displayName
                  : this.global.dsUser.avatar_name
                  ? this.global.dsUser.avatar_name.substring(0, 15)
                  : ""}
              </span>
            </a>
            {this.state.showMenu && (
              <div id="popupcontainer" className="popup-container">
                <ul className="inner-popup-container">
                  {(!this.global.dsUser.sub_user ||
                    (access["Account"] &&
                      access["Account"]["Profile Setup"] &&
                      !access["Account"]["Profile Setup"].read)) && (
                    <li className="popup-item">
                      <a href="/partners/profile" className="popup-link">
                        Organization Profile
                      </a>
                    </li>
                  )}
                  {!this.global.dsUser.sub_user && (
                    <li className="popup-item">
                      <a href="/partners/settings" className="popup-link">
                        Account Settings
                      </a>
                    </li>
                  )}
                  {!this.global.dsUser.sub_user && (
                    <li className="popup-item">
                      <a href="/partners/customization" className="popup-link">
                        Customization
                      </a>
                    </li>
                  )}
                  {this.state.showTeam && (
                    <li className="popup-item">
                      <a
                        href="/partners/team-management"
                        className="popup-link"
                      >
                        Team Management
                      </a>
                    </li>
                  )}
                  {!this.global.dsUser.sub_user && this.state.showTeam && (
                    <li className="popup-item">
                      <a href="/partners/schedules" className="popup-link">
                        Team Schedules
                      </a>
                    </li>
                  )}
                  {(!this.global.dsUser.sub_user ||
                    (access["Upcoming"] &&
                      access["Upcoming"]["Tasks & Reminders"] &&
                      access["Upcoming"]["Tasks & Reminders"].read)) && (
                    <li className="popup-item">
                      <a href="/partners/tasks" className="popup-link">
                        Tasks & Reminders
                      </a>
                    </li>
                  )}
                  {(!this.global.dsUser.sub_user ||
                    (access["Account"] &&
                      access["Account"]["Reporting"] &&
                      access["Account"]["Reporting"].read)) && this.state.showReports &&(
                    <li className="popup-item">
                      <a href="/partners/reporting" className="popup-link">
                        Reports & Analytics
                      </a>
                    </li>
                  )}
                  {(!this.global.dsUser.sub_user) && this.state.showIntegrations && (
                    <li className="popup-item">
                      <a href="/partners/integrations" className="popup-link">
                        Marketing Integrations
                      </a>
                    </li>
                  )}

                  <li className="popup-item">
                    <a
                      href="#/"
                      onClick={async () => {
                        this.props.history.push("/");
                        await this.dispatch.logout();
                      }}
                      className="popup-link"
                    >
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </li>
        </ul>
      </nav>
    );
  }
}
TopBar.propTypes = {
  ConsoleRoute: PropTypes.string
};

TopBar.defaultProps = {
  ConsoleRoute: "/"
};

export default withRouter(TopBar);
