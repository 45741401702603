import React, { useGlobal, useState, useEffect, useRef } from "reactn";
import { format } from "date-fns";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Select from "react-select";

import {
  ReportContainer,
  ReportTable
} from "../../../components/UI/reportsAnalytics";
import { formatMoney } from "../../../utilities/reportsAnalytics";
import { CENTS_IN_DOLLAR } from "../../../providers/reports";
import FilterButton from "../../../assets/img/FilterButton.png";
import Doughnut from "../../../components/charts/Doughnut";
import "./ProgramNameReport.css";
import "../../../assets/css/componentSpecificCss/reportAnalysisPages.css";

const NameReport = () => {
  const [selectedNames, setSelectedNames] = useState([]);
  const [names, setNames] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [data, setData] = useState({
    programs: [],
    programCustomers: [],
    orders: []
  });
  const [nameData, setNameData] = useState({
    programs: [],
    programCustomers: [],
    orders: []
  });

  const [displayOrders, setDisplayOrders] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalRefunds, setTotalRefunds] = useState(0);
  const [totalDiscounts, setTotalDiscounts] = useState(0);

  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");
  const [loading, setLoading] = useGlobal("loading");
  const orgData = useGlobal("organizationInfo")[0];
  const filterMenu = useRef(null);
  const [enrollmentData, setEnrollmentData] = useState({
    labels: [],
    data: []
  });
  const [customerTypeData, setCustomerTypeData] = useState({
    labels: ["New Customer", "Repeating Customer"],
    data: [0, 0]
  });
  const [locationData, setLocationData] = useState({
    labels: [],
    data: []
  });
  const [demographics, setDemographics] = useState({
    averageAge: 0,
    totalChildren: 0,
    totalNonChildren: 0
  });

  const fetchInitialData = async () => {
    const programsEp = `${process.env.REACT_APP_API}/partners/programs/list`;
    const programsRes = await axios.get(programsEp);
    let programs = [];
    if (programsRes.data.success) {
      programs = programsRes.data.data;
      let programNames = programs.map(
        program => program.program_name || "No Name"
      );
      // Ensure unique names
      programNames = [...new Set(programNames)].map(name => ({
        value: name,
        label: name
      }));
      setNames(programNames);

      let programCategories = programs.map(
        program => program.category_description || "No Category"
      );
      // Ensure unique categories
      programCategories = [...new Set(programCategories)].map(category => ({
        value: category,
        label: category
      }));
      setCategories(programCategories);

      let programYears = programs.map(
        program => new Date(program.program_start).getFullYear().toString()
      );
      // Ensure unique years
      programYears = [...new Set(programYears)].map(year => ({
        value: year,
        label: year
      }));
      setYears(programYears);
    }
    const programsCustomersEp = `${process.env.REACT_APP_API}/partners/programs/customers/active/-1`;
    const programsCustomersRes = await axios.get(programsCustomersEp);
    let programCustomers = [];
    if (programsCustomersRes.data.success) {
      programCustomers = programsCustomersRes.data.data;
    }
    const ordersEp = `${process.env.REACT_APP_API}/partners/order-tracking`;
    const ordersRes = await axios.get(ordersEp);
    let orders = [];
    if (ordersRes.data.success) {
      orders = [
        ...ordersRes.data.data.Once.payments,
        ...ordersRes.data.data.Recurring.payments
      ];
    }
    setData({
      programs,
      programCustomers,
      orders
    });
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (selectedNames.length > 0) {
      console.log("data", data);
      console.log("data order", data.orders);
      let selectedNameValues = selectedNames.map(name => name.value);
      let curPrograms = data.programs.filter(program => {
        return selectedNameValues.includes(program.program_name || "No Name");
      });
      let curProgramCustomers = data.programCustomers.filter(
        programCustomer => {
          return curPrograms.some(
            program =>
              program.isArchived != true &&
              program.id === programCustomer.programId
          );
        }
      );
      let curOrders = data.orders.filter(order => {
        return curPrograms.some(
          program =>
            order.listingType === "program" && program.id === order.listingId
        );
      });
      setNameData({
        programs: curPrograms,
        programCustomers: curProgramCustomers,
        orders: curOrders
      });
      setDisplayOrders(getDisplayableData(curOrders));
      setTotalRevenue(getTotalRevenue(curOrders));
      setTotalRefunds(
        formatMoney(
          curOrders.reduce(
            (prev, curr) => prev + parseInt(curr.refundedAmount),
            0
          ) / 100
        )
      );
      setTotalDiscounts(
        formatMoney(
          curOrders.reduce(
            (prev, curr) =>
              prev +
              parseInt(curr.modifiers.coupon) +
              parseInt(curr.modifiers.promotion),
            0
          ) / 100
        )
      );
      setCustomerTypeData(getCustomerTypeData(curOrders, data.orders));
      setLocationData(getLocationData(curPrograms));
      const curDemo = fetchDemographics(curOrders).then(curDemo => {
        setDemographics({
          averageAge: curDemo.averageAge,
          totalChildren: curDemo.totalChildren,
          totalNonChildren: curDemo.totalNonChildren
        });
      });
    } else {
      setNameData({
        programs: [],
        programCustomers: [],
        orders: []
      });
      setDisplayOrders([]);
      setTotalRevenue(0);
      setTotalRefunds(0);
      setTotalDiscounts(0);
      setCustomerTypeData({
        labels: ["New Customer", "Repeating Customer"],
        data: [0, 0]
      });
      setLocationData({
        labels: [],
        data: []
      });
      setDemographics({
        averageAge: 0,
        totalChildren: 0,
        totalNonChildren: 0
      });
    }
  }, [selectedNames, data]);

  useEffect(() => {
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Program Name Report", to: "/reports/name-report" }
    ]);
  }, []);
  useEffect(() => {
    const doughnutCenter = document.querySelector(".doughnut-center");
    if (doughnutCenter) {
      const length = doughnutCenter.textContent.length;
      if (length > 4) {
        doughnutCenter.style.fontSize = "0.7rem";
      } else {
        doughnutCenter.style.fontSize = "1.5rem";
      }
    }
  }, [nameData]);

  const fetchDemographics = async orders => {
    let children = 0;
    let parent = 0;
    let childrenIds = [];
    orders.map(order => {
      if (order.childId && order.childId.length > 0) {
        order.childId.map(child => {
          childrenIds.push(child);
        });
        children++;
      } else {
        parent++;
      }
    });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/par/orders/childrenDemographics`,
        {
          params: { childrenIds: JSON.stringify(childrenIds) } // Ensure orders are passed as a JSON string
        }
      );
      console.log("Demographics response", response.data.data);
      return {
        averageAge: response.data.data.averageAge,
        totalChildren: children,
        totalNonChildren: parent
      };
    } catch (error) {
      console.error("Error fetching demographics:", error);
    }
  };

  const handleFilterChange = (items, set, type) => {
    if (!items || items.length <= 2) {
      set(items || []);
      updateSelectedNames(items, type);
    } else {
      alert(`You can only select up to 2 filters in this dropdown.`);
    }
  };
  const handleNameChange = (names) => {
    
    if (!names || names.length <= 5) {
        setSelectedNames(names || []);
    } else {
      alert(`You can only select up to 5 Program Names.`);
    }
  };
  const updateSelectedNames = (items, type) => {
    const selectedCat = type === "categories" ? items || [] : selectedCategories;
    const selectedYrs = type === "years" ? items || []: selectedYears;
    const filteredNames = selectedNames.filter(name =>
      data.programs.some(
        program =>
          program.program_name === name.value &&
          (selectedCat.length === 0 ||
            selectedCat.some(
              category =>
                program.category_description === category.value ||
                (!program.category_description && category.value === "No Category")
            )) &&
          (selectedYrs.length === 0 ||
            selectedYrs.some(
              year => new Date(program.program_start).getFullYear().toString() === year.value
            ))
      )
    );
    setSelectedNames(filteredNames);
  };
  const getDisplayableData = orders => {
    const sortedData = orders.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    const cleanedData = cleanData(sortedData);
    const formatDateTotal = cleanedData.map(o => ({
      ...o,
      date: format(new Date(o.date), "MMM d, yyyy"),
      total: formatMoney(o.total / 100)
    }));
    return formatData(formatDateTotal);
  };

  const cleanData = orders => {
    return orders.map(o => ({
      customer: o.purchaser,
      date: o.date,
      listing: o.item,
      modifiers: getModifierString(o),
      total: o.total
    }));
  };
  const formatData = orders =>
    orders.map(o => [o.customer, o.date, o.listing, o.modifiers, o.total]);

  const getModifierString = order => {
    // modifiers are: tax, credit card fee, promotion, coupon, addOnSemesterItemAmount, gift discount, discount amount, refund
    return Object.keys(order.modifiers)
      .map(modifier =>
        parseInt(order.modifiers[modifier])
          ? `${getModifierName(modifier)} (${formatMoney(
              order.modifiers[modifier] / 100
            )})`
          : ""
      )
      .filter(e => e !== "")
      .join(" / ");
  };

  // tax -> Tax
  // creditCardFee -> Credit Card Fee
  // promotion -> Promotion
  // coupon -> Coupon
  // addOnSemesterItem -> Add On Semester Item
  // giftDiscount -> Gift Discount
  // discount -> Discount
  // refunded -> Refunded
  const getModifierName = modifier => {
    switch (modifier) {
      case "tax":
        return "Tax";
      case "creditCardFee":
        return "Credit Card Fee";
      case "promotion":
        return "Promotion";
      case "coupon":
        return "Coupon";
      case "addOnSemesterItem":
        return "Add On Semester Item";
      case "giftDiscount":
        return "Gift Discount";
      case "discount":
        return "Discount";
      case "refunded":
        return "Refunded";
      default:
        return "";
    }
  };

  /**
   * Gets total revenue by aggregating all order data.
   *
   * @returns {Number} Total revenue.
   */
  const getTotalRevenue = orders => {
    const totalRevenue = orders.reduce((a, c) => a + parseInt(c.total), 0);

    return formatMoney(totalRevenue / CENTS_IN_DOLLAR);
  };

  const downloadPDF = async () => {
    setLoading(true);
    const report = document.getElementById("name-report");
    const exportButton = report.querySelector("#name-export-button");
    const buttonParent = exportButton.parentElement;
    exportButton.remove();
    const reportCanvas = await html2canvas(report);
    buttonParent.appendChild(exportButton);

    const pdf = new jsPDF("p", "mm", "letter", false);
    let img = reportCanvas.toDataURL("image/png");
    const imgProps = pdf.getImageProperties(img);
    let width = pdf.internal.pageSize.getWidth() - 40;
    let height = (imgProps.height * width) / imgProps.width;
    pdf.addImage(img, "PNG", 20, 20, width, height);

    pdf.save("ProgramNameReport.pdf");
    setLoading(false);
  };

  const reportSubtitles = [
    orgData.organization_title,
    `Name: ${selectedNames.map(name => name.label).join(", ")}`
  ];
  const mapStatusStage = status_stage => {
    let key = "Unknown";
    switch (status_stage) {
      case "Accepted_Paid":
        key = "Enrolled";
        break;
      case "Accepted_Unpaid":
        key = "Accepted";
        break;
      case "New_Pending":
        key = "New";
        break;
      case "Waitlisted_Unpaid":
        key = "Waitlisted";
        break;
    }
    return key;
  };

  const getStatusCounts = items => {
    let statusCounts = {};
    items.map(item => {
      item.map(i => {
        let status = mapStatusStage(i.status_stage);
        if (statusCounts[status]) {
          statusCounts[status]++;
        } else {
          statusCounts[status] = 1;
        }
      });
    });
    return {
      labels: Object.keys(statusCounts),
      data: Object.values(statusCounts)
    };
  };

  const getStatusStageData = () => {
    console.log("nameData", nameData);
    let statusStageCounts = getStatusCounts([nameData.programCustomers]);
    console.log("statusStageCounts", statusStageCounts);

    return statusStageCounts;
  };

  const getCustomerTypeData = (nameOrders, allOrders) => {
    const orderMap = new Map();
    allOrders.forEach(order => {
      if (!orderMap.has(order.dsUserId)) {
        orderMap.set(order.dsUserId, []);
      }
      orderMap.get(order.dsUserId).push(order);
    });

    let newCustomers = 0;
    let repeatingCustomers = 0;

    nameOrders.forEach(order => {
      const userOrders = orderMap.get(order.dsUserId) || [];
      const firstOrder = userOrders.reduce((earliest, current) => {
        return new Date(current.date) < new Date(earliest.date)
          ? current
          : earliest;
      }, userOrders[0]);

      if (firstOrder.id === order.id) {
        newCustomers++;
      } else {
        repeatingCustomers++;
      }
    });

    return {
      labels: ["New Customer", "Repeating Customer"],
      data: [newCustomers, repeatingCustomers]
    };
  };

  const getLocationData = programs => {
    const locationCounts = programs.reduce((acc, program) => {
      const location = program.address1.split(",")[0] || "Unknown";
      if (!acc[location]) {
        acc[location] = 0;
      }
      acc[location]++;
      return acc;
    }, {});

    const sortedLocations = Object.entries(locationCounts).sort((a, b) => b[1] - a[1]);
    const topLocations = sortedLocations.slice(0, 5);
    const otherLocations = sortedLocations.slice(5);

    const otherCount = otherLocations.reduce((acc, loc) => acc + loc[1], 0);

    const labels = topLocations.map(loc => loc[0]);
    const data = topLocations.map(loc => loc[1]);

    if (otherCount > 0) {
      labels.push("Other");
      data.push(otherCount);
    }

    return {
      labels,
      data
    };
  };

  const formatAverageAge = age => {
    if (age < 2) {
      return `${Math.round(age * 12)} months`;
    }
    return `${Math.round(age * 10) / 10} years`;
  };

  useEffect(() => {
    setEnrollmentData(getStatusStageData());
  }, [nameData]);

  const formatTableData = () => {
    let customers = [...nameData.programCustomers];
    let sortedCustomers = customers.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
    return sortedCustomers.map(customer => [
      customer.displayName,
      customer.email,
      customer.title,
      mapStatusStage(customer.status_stage),
      format(new Date(customer.updatedAt), "MMM d, yyyy")
    ]);
  };

  const getCSVData = () => {
    let customers = [...nameData.programCustomers];
    return customers.map(customer => ({
      customerName: customer.displayName,
      email: customer.email,
      listing: customer.title,
      status: mapStatusStage(customer.status_stage),
      date: format(new Date(customer.updatedAt), "MMM d, yyyy")
    }));
  };

  const csvHeaders = [
    { label: "Customer Name", key: "customerName" },
    { label: "Email", key: "email" },
    { label: "Program", key: "program" },
    { label: "Status", key: "status" },
    { label: "Date", key: "date" }
  ];

  const filteredNames = names.filter(name =>
    data.programs.some(
      program =>
        program.program_name === name.value &&
        (selectedCategories.length === 0 ||
          selectedCategories.some(
            category =>
              program.category_description === category.value ||
              (!program.category_description && category.value === "No Category")
          )) &&
        (selectedYears.length === 0 ||
          selectedYears.some(
            year => new Date(program.program_start).getFullYear().toString() === year.value
          ))
    )
  );

  return (
    <>
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>Program Name Report</h1>
            </div>
          </div>

          <div className="filter-container">
            <div className="filter-section">
              <div className="filter-item-prestacked">
                <label
                  className="filter-label-prestacked"
                  style={{ fontSize: "1.2rem" }}
                >
                  Filter By:
                </label>
              </div>
              <div className="filter-item-stacked">
              <div className="filter-item-inline">
                  <label
                    htmlFor="year-select"
                    className="filter-label-inline"
                  >
                    Year:
                  </label>
                  <Select
                    id="year-select"
                    className="year-select"
                    value={selectedYears}
                    onChange={years => handleFilterChange(years, setSelectedYears, "years")}
                    options={years}
                    isMulti
                    isSearchable
                  />
                </div>
                <div className="filter-item-inline">
                  <label
                    htmlFor="category-select"
                    className="filter-label-inline"
                  >
                    Category:
                  </label>
                  <Select
                    id="category-select"
                    className="category-select"
                    value={selectedCategories}
                    onChange={categories =>
                      handleFilterChange(
                        categories,
                        setSelectedCategories,
                        "categories")
                    }
                    options={categories}
                    isMulti
                    isSearchable
                  />
                </div>
              </div>
              <div className="filter-item">
                <label htmlFor="name-select" className="filter-label">
                  Select Program Name:
                </label>
                <Select
                  id="name-select"
                  className="name-select"
                  value={selectedNames}
                  onChange={names => handleNameChange(names)}
                  options={filteredNames}
                  isMulti
                  isSearchable
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="container-fluid my_earning"
        >
          <div className="cont report-card">
            <div id="name-report">
              <div className="report-heading">
                <h1 id="report-title">Program Name Report</h1>
                <button
                  id="name-export-button"
                  className="name-export filter-button"
                  onClick={downloadPDF}
                >
                  Export Report
                </button>
              </div>
              <div>
                <div className="boxes">
                  <div className="name-box box name-graphs">
                    <h2>Active Enrollment Data</h2>
                    <div className="name-doughnut-container">
                      <Doughnut
                        labels={enrollmentData.labels}
                        data={enrollmentData.data}
                        cutoutPercentage={63}
                        legendPositions={"bottom"}
                      />
                      <div className="doughnut-center">
                        {nameData.programCustomers.length}
                      </div>
                    </div>
                  </div>
                  <div className="name-box box">
                    <h2>Total Revenue in this Name</h2>
                    <div
                      className="info"
                      style={{ paddingTop: "20px", paddingBottom: "20px" }}
                    >
                      <h3>{totalRevenue}</h3>
                      <p
                        style={{
                          color: "#a1a1a1",
                          fontStyle: "italic",
                          fontSize: "0.8rem"
                        }}
                        className="info-components"
                      >
                        Total Discounts : {totalDiscounts}
                      </p>
                      <p
                        style={{
                          color: "#a1a1a1",
                          fontStyle: "italic",
                          fontSize: "0.8rem",
                          paddingBottom: "40px"
                        }}
                        className="info-components"
                      >
                        Total Refunds : {totalRefunds}
                      </p>
                      <p>
                        Name: {selectedNames.map(name => name.label).join(", ")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="boxes">
                  <div className="name-box box name-graphs">
                    <h2>Orders Purchased By</h2>
                    <div className="name-doughnut-container">
                      <Doughnut
                        labels={customerTypeData.labels}
                        data={customerTypeData.data}
                        cutoutPercentage={63}
                        legendPositions={"bottom"}
                      />
                      <div className="doughnut-center">
                        {customerTypeData.data.reduce((a, b) => a + b, 0)}
                      </div>
                    </div>
                  </div>
                  <div className="name-box box">
                    <h2>Demographics Information</h2>
                    <div
                      className="info"
                      style={{ paddingTop: "20px", paddingBottom: "20px" }}
                    >
                      <h3>
                        Avg age: {formatAverageAge(demographics.averageAge)}
                      </h3>
                      <p
                        style={{
                          color: "#a1a1a1",
                          fontStyle: "italic",
                          fontSize: "0.8rem"
                        }}
                        className="info-components"
                      >
                        Regular Enrollments:{" "}
                        {demographics.totalNonChildren}
                        
                      </p>
                      <p
                        style={{
                          color: "#a1a1a1",
                          fontStyle: "italic",
                          fontSize: "0.8rem",
                          paddingBottom: "40px"
                        }}
                        className="info-components"
                      >
                        Target-Children Enrollments : {demographics.totalChildren}
                      </p>
                      <p>
                        Name: {selectedNames.map(name => name.label).join(", ")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="boxes">
                  <div className="name-box box name-graphs">
                    <h2># of Programs (by Location)</h2>
                    <div className="name-doughnut-container">
                      <Doughnut
                        labels={locationData.labels}
                        data={locationData.data}
                        cutoutPercentage={63}
                        legendPositions={"bottom"}
                      />
                      <div className="doughnut-center">
                        {locationData.data.reduce((a, b) => a + b, 0)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ReportContainer
              title="Enrollments"
              subtitles={reportSubtitles}
              csvHeaders={csvHeaders}
              csvData={getCSVData()}
              fileName={`Enrollments_${selectedNames
                .map(name => name.label)
                .join("_")}.csv`}
              buttonText="Export Table"
              isLarge
            >
              <div className="report-table-large">
                <ReportTable
                  headings={[
                    { label: "Customer Name", className: "table-item-bold" },
                    { label: "Email", className: "table-item-bold" },
                    { label: "Listing", className: "table-item-bold" },
                    { label: "Status", className: "table-item-bold" },
                    { label: "Date", className: "table-item-bold" }
                  ]}
                  body={formatTableData()}
                />
              </div>
            </ReportContainer>
            <ReportContainer
              title="Orders"
              subtitles={reportSubtitles}
              csvHeaders={[
                { label: "Customer", key: "customer" },
                { label: "Date", key: "date" },
                { label: "Listing", key: "listing" },
                { label: "Modifiers", key: "modifiers" },
                { label: "Total", key: "total" }
              ]}
              csvData={displayOrders.map(order => ({
                customer: order[0],
                date: order[1],
                listing: order[2],
                modifiers: order[3],
                total: order[4]
              }))}
              fileName={`Orders_${selectedNames
                .map(name => name.label)
                .join("_")}.csv`}
              buttonText="Export Table"
              isLarge
            >
              <div className="report-table-large">
                <ReportTable
                  headings={[
                    { label: "Customer" },
                    { label: "Date" },
                    { label: "Listing" },
                    { label: "Modifiers" },
                    {
                      label: "Total",
                      className: "table-item-money",
                      style: { textAlign: "right" }
                    }
                  ]}
                  body={displayOrders}
                />
              </div>
            </ReportContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default NameReport;
