import React, { useGlobal, useState, useEffect, useRef } from "reactn";
import { format } from "date-fns";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import {
  ReportContainer,
  ReportTable
} from "../../../components/UI/reportsAnalytics";
import { formatMoney } from "../../../utilities/reportsAnalytics";
import { CENTS_IN_DOLLAR } from "../../../providers/reports";
import FilterButton from "../../../assets/img/FilterButton.png";
import Doughnut from "../../../components/charts/Doughnut";
import "./LocationsReport.css";
import "../../../assets/css/componentSpecificCss/reportAnalysisPages.css";

const LocationsReport = () => {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [data, setData] = useState({
    programs: [],
    programCustomers: [],
    orders: [],
    parties: [],
    partiesCustomers: [],
    events: [],
    eventsCustomers: []
  });
  const [locationData, setLocationData] = useState({
    programs: [],
    programCustomers: [],
    orders: [],
    parties: [],
    partiesCustomers: [],
    events: [],
    eventsCustomers: []
  });

  const [displayOrders, setDisplayOrders] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalRefunds, setTotalRefunds] = useState(0);
  const [totalDiscounts, setTotalDiscounts] = useState(0);

  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");
  const [loading, setLoading] = useGlobal("loading");
  const orgData = useGlobal("organizationInfo")[0];
  const locations = [
    {
      address:
        orgData.address1 +
        ", " +
        orgData.city +
        ", " +
        orgData.province +
        " " +
        orgData.postalcode +
        ", " +
        orgData.country
    },
    ...orgData.other_locations.map(e => ({
      address: e.address
    }))
  ];
  const filterMenu = useRef(null);
  const [enrollmentData, setEnrollmentData] = useState({
    labels: [],
    data: []
  });
  const [customerTypeData, setCustomerTypeData] = useState({
    labels: ["New Customer", "Repeating Customer"],
    data: [0, 0]
  });
  const [demographics, setDemographics] = useState({
    averageAge: 0,
    totalChildren: 0,
    totalNonChildren: 0
  });

  const fetchInitialData = async () => {
    const programsEp = `${process.env.REACT_APP_API}/partners/programs/list`;
    const programsRes = await axios.get(programsEp);
    let programs = [];
    if (programsRes.data.success) {
      programs = programsRes.data.data;
    }
    const programsCustomersEp = `${process.env.REACT_APP_API}/partners/programs/customers/active/-1`;
    const programsCustomersRes = await axios.get(programsCustomersEp);
    let programCustomers = [];
    if (programsCustomersRes.data.success) {
      programCustomers = programsCustomersRes.data.data;
    }
    const partiesEp = `${process.env.REACT_APP_API}/partners/parties/list`;
    const partiesRes = await axios.get(partiesEp);
    let parties = [];
    if (partiesRes.data.success) {
      parties = partiesRes.data.data;
    }
    const partiesCustomersEp = `${process.env.REACT_APP_API}/partners/parties/customers/active/-1`;
    const partiesCustomersRes = await axios.get(partiesCustomersEp);
    let partiesCustomers = [];
    if (partiesCustomersRes.data.success) {
      partiesCustomers = partiesCustomersRes.data.data;
    }

    const eventsEp = `${process.env.REACT_APP_API}/partners/events/list`;
    const eventsRes = await axios.get(eventsEp);
    let events = [];
    if (eventsRes.data.success) {
      events = eventsRes.data.data;
    }
    const eventsCustomersEp = `${process.env.REACT_APP_API}/partners/events/customers/active/-1`;
    const eventsCustomersRes = await axios.get(eventsCustomersEp);
    let eventsCustomers = [];
    if (eventsCustomersRes.data.success) {
      eventsCustomers = eventsCustomersRes.data.data;
    }

    const ordersEp = `${process.env.REACT_APP_API}/partners/order-tracking`;
    const ordersRes = await axios.get(ordersEp);
    let orders = [];
    if (ordersRes.data.success) {
      orders = [
        ...ordersRes.data.data.Once.payments,
        ...ordersRes.data.data.Recurring.payments
      ];
    }
    setData({
      programs,
      programCustomers,
      orders,
      parties,
      partiesCustomers,
      events,
      eventsCustomers
    });
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (selectedLocation) {
      console.log("data", data);
      console.log("data order", data.orders);
      let curPrograms = data.programs.filter(program => {
        return (
          program.address1 == selectedLocation ||
          program.address2 == selectedLocation
        );
      });
      let curParties = data.parties.filter(party => {
        return (
          party.address1 == selectedLocation ||
          party.address2 == selectedLocation
        );
      });
      let curEvents = data.events.filter(event => {
        return (
          event.address1 == selectedLocation ||
          event.address2 == selectedLocation
        );
      });
      let curProgramCustomers = data.programCustomers.filter(
        programCustomer => {
          return curPrograms.some(
            program =>
              program.isArchived != true &&
              program.id === programCustomer.programId
          );
        }
      );
      let curPartiesCustomers = data.partiesCustomers.filter(partyCustomer => {
        return curParties.some(
          party =>
            party.isArchived != true && party.id === partyCustomer.partyId
        );
      });
      let curEventsCustomers = data.eventsCustomers.filter(eventCustomer => {
        return curEvents.some(
          event =>
            event.isArchived != true && event.id === eventCustomer.eventId
        );
      });
      let curOrders = data.orders.filter(order => {
        switch (order.listingType) {
          case "program":
            return curPrograms.some(program => program.id === order.listingId);
          case "party":
            return curParties.some(party => party.id === order.listingId);
          case "event":
            return curEvents.some(event => event.id === order.listingId);
          case "invoice":
            return false;
        }
        return false;
      });
      setLocationData({
        programs: curPrograms,
        programCustomers: curProgramCustomers,
        parties: curParties,
        partiesCustomers: curPartiesCustomers,
        events: curEvents,
        eventsCustomers: curEventsCustomers,
        orders: curOrders
      });
      setDisplayOrders(getDisplayableData(curOrders));
      setTotalRevenue(getTotalRevenue(curOrders));
      setTotalRefunds(
        formatMoney(
          curOrders.reduce(
            (prev, curr) => prev + parseInt(curr.refundedAmount),
            0
          ) / 100
        )
      );
      setTotalDiscounts(
        formatMoney(
          curOrders.reduce(
            (prev, curr) =>
              prev +
              parseInt(curr.modifiers.coupon) +
              parseInt(curr.modifiers.promotion),
            0
          ) / 100
        )
      );
      setCustomerTypeData(getCustomerTypeData(curOrders, data.orders));
      const curDemo = fetchDemographics(curOrders).then(curDemo => {
        setDemographics({
          averageAge: curDemo.averageAge,
          totalChildren: curDemo.totalChildren,
          totalNonChildren: curDemo.totalNonChildren
        });
      });
    }
  }, [selectedLocation, data]);

  useEffect(() => {
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Reports & Analytics", to: "/reporting" },
      { label: "Locations Report", to: "/reports/locations-report" }
    ]);
  }, []);
  useEffect(() => {
    const doughnutCenter = document.querySelector('.doughnut-center');
    if (doughnutCenter) {
      const length = doughnutCenter.textContent.length;
      if (length > 4) {
        doughnutCenter.style.fontSize = '0.7rem';
      } else {
        doughnutCenter.style.fontSize = '1.5rem';
      }
    }
  }, [locationData]);

  const fetchDemographics = async orders => {
    let children = 0;
    let parent = 0;
    let childrenIds = [];
    orders.map(order => {
      if (order.childId && order.childId.length > 0) {
        order.childId.map(child => {
          childrenIds.push(child);
        });
        children++;
      } else {
        parent++;
      }
    });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/par/orders/childrenDemographics`,
        {
          params: { childrenIds: JSON.stringify(childrenIds) } // Ensure orders are passed as a JSON string
        }
      );
      console.log("Demographics response", response.data.data);
      return {
        averageAge: response.data.data.averageAge,
        totalChildren: children,
        totalNonChildren: parent
      };
    } catch (error) {
      console.error("Error fetching demographics:", error);
    }
  };

  const getDisplayableData = orders => {
    const sortedData = orders.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    const cleanedData = cleanData(sortedData);
    const formatDateTotal = cleanedData.map(o => ({
      ...o,
      date: format(new Date(o.date), "MMM d, yyyy"),
      total: formatMoney(o.total / 100)
    }));
    return formatData(formatDateTotal);
  };

  const cleanData = orders => {
    return orders.map(o => ({
      customer: o.purchaser,
      date: o.date,
      listing: o.item,
      modifiers: getModifierString(o),
      total: o.total
    }));
  };
  const formatData = orders =>
    orders.map(o => [o.customer, o.date, o.listing, o.modifiers, o.total]);

  const getModifierString = order => {
    // modifiers are: tax, credit card fee, promotion, coupon, addOnSemesterItemAmount, gift discount, discount amount, refund
    return Object.keys(order.modifiers)
      .map(modifier =>
        parseInt(order.modifiers[modifier])
          ? `${getModifierName(modifier)} (${formatMoney(
              order.modifiers[modifier] / 100
            )})`
          : ""
      )
      .filter(e => e !== "")
      .join(" / ");
  };

  // tax -> Tax
  // creditCardFee -> Credit Card Fee
  // promotion -> Promotion
  // coupon -> Coupon
  // addOnSemesterItem -> Add On Semester Item
  // giftDiscount -> Gift Discount
  // discount -> Discount
  // refunded -> Refunded
  const getModifierName = modifier => {
    switch (modifier) {
      case "tax":
        return "Tax";
      case "creditCardFee":
        return "Credit Card Fee";
      case "promotion":
        return "Promotion";
      case "coupon":
        return "Coupon";
      case "addOnSemesterItem":
        return "Add On Semester Item";
      case "giftDiscount":
        return "Gift Discount";
      case "discount":
        return "Discount";
      case "refunded":
        return "Refunded";
      default:
        return "";
    }
  };

  const formatAverageAge = age => {
    if (age < 2) {
      return `${Math.round(age * 12)} months`;
    }
    return `${Math.round(age * 10) / 10} years`;
  };

  /**
   * Gets total revenue by aggregating all order data.
   *
   * @returns {Number} Total revenue.
   */
  const getTotalRevenue = orders => {
    const totalRevenue = orders.reduce((a, c) => a + parseInt(c.total), 0);

    return formatMoney(totalRevenue / CENTS_IN_DOLLAR);
  };

  const downloadPDF = async () => {
    setLoading(true);
    const report = document.getElementById("locations-report");
    const exportButton = report.querySelector("#locations-export-button");
    const buttonParent = exportButton.parentElement;
    exportButton.remove();
    const reportCanvas = await html2canvas(report);
    buttonParent.appendChild(exportButton);

    const pdf = new jsPDF("p", "mm", "letter", false);
    let img = reportCanvas.toDataURL("image/png");
    const imgProps = pdf.getImageProperties(img);
    let width = pdf.internal.pageSize.getWidth() - 40;
    let height = (imgProps.height * width) / imgProps.width;
    pdf.addImage(img, "PNG", 20, 20, width, height);

    pdf.save("LocationsReport.pdf");
    setLoading(false);
  };

  const reportSubtitles = [
    orgData.organization_title,
    `Location: ${selectedLocation}`
  ];
  const mapStatusStage = status_stage => {
    let key = "Unknown";
    switch (status_stage) {
      case "Accepted_Paid":
        key = "Enrolled";
        break;
      case "Accepted_Unpaid":
        key = "Accepted";
        break;
      case "New_Pending":
        key = "New";
        break;
      case "Waitlisted_Unpaid":
        key = "Waitlisted";
        break;
    }
    return key;
  };

  const getStatusCounts = (items) => {
    let statusCounts = {};
    items.map(item => {
        item.map(i => {
            let status = mapStatusStage(i.status_stage);
            if (statusCounts[status]) {
                statusCounts[status]++;
            } else {
                statusCounts[status] = 1;
            }
        }
    )});
    return {
      labels: Object.keys(statusCounts),
      data: Object.values(statusCounts)
    };
  }

  const getStatusStageData = () => {
    console.log("locationData", locationData);
    let statusStageCounts = getStatusCounts([locationData.programCustomers, locationData.partiesCustomers, locationData.eventsCustomers]);
    console.log("statusStageCounts", statusStageCounts);

    return statusStageCounts;
  };

  const getCustomerTypeData = (locationOrders, allOrders) => {
    const orderMap = new Map();
    allOrders.forEach(order => {
      if (!orderMap.has(order.dsUserId)) {
        orderMap.set(order.dsUserId, []);
      }
      orderMap.get(order.dsUserId).push(order);
    });

    let newCustomers = 0;
    let repeatingCustomers = 0;

    locationOrders.forEach(order => {
      const userOrders = orderMap.get(order.dsUserId) || [];
      const firstOrder = userOrders.reduce((earliest, current) => {
        return new Date(current.date) < new Date(earliest.date) ? current : earliest;
      }, userOrders[0]);

      if (firstOrder.id === order.id) {
        newCustomers++;
      } else {
        repeatingCustomers++;
      }
    });

    return {
      labels: ["New Customer", "Repeating Customer"],
      data: [newCustomers, repeatingCustomers]
    };
  };

  useEffect(() => {
    setEnrollmentData(getStatusStageData());
  }, [locationData]);

  const formatTableData = () => {
    let customers = [...locationData.programCustomers, ...locationData.partiesCustomers, ...locationData.eventsCustomers];
    let sortedCustomers = customers.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
    return sortedCustomers.map(customer => [
      customer.displayName,
      customer.email,
      customer.title,
      mapStatusStage(customer.status_stage),
      format(new Date(customer.updatedAt), "MMM d, yyyy")
    ]);
  };

  const getCSVData = () => {
    let customers = [...locationData.programCustomers, ...locationData.partiesCustomers, ...locationData.eventsCustomers];
    return customers.map(customer => ({
      customerName: customer.displayName,
      email: customer.email,
      listing: customer.title,
      status: mapStatusStage(customer.status_stage),
      date: format(new Date(customer.updatedAt), "MMM d, yyyy")
    }));
  };

  const csvHeaders = [
    { label: "Customer Name", key: "customerName" },
    { label: "Email", key: "email" },
    { label: "Program", key: "program" },
    { label: "Status", key: "status" },
    { label: "Date", key: "date" }
  ];

  return (
    <>
      <div className="admin">
        <div className="container-fluid adminprofiles">
          <div className="row cont">
            <div className="col-md-6">
              <h1>Locations Report</h1>
            </div>
          </div>

          <div className="filter-container">
            <div className="filter-section">
              <div className="filter-item">
                <label htmlFor="location-select" className="filter-label">
                  Select Location:
                </label>
                <select
                  name="location-select"
                  id="location-select"
                  className="form-control filter-input"
                  value={selectedLocation}
                  onChange={e => setSelectedLocation(e.target.value)}
                >
                  <option value="" selected disabled hidden>
                    Please Select...
                  </option>
                  {locations.map((location, index) => (
                    <option key={index} value={location.address}>
                      {location.address}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="container-fluid my_earning"
        >
          <div className="cont report-card">
            <div id="locations-report">
              <div className="report-heading">
                <h1 id="report-title">Locations Report</h1>
                <button
                  id="locations-export-button"
                  className="locations-export filter-button"
                  onClick={downloadPDF}
                >
                  Export Report
                </button>
              </div>
              <div>
                <div className="boxes">
                  <div className="location-box box location-graphs">
                    <h2>Active Enrollment Data</h2>
                    <div className="location-doughnut-container">
                      <Doughnut
                        labels={enrollmentData.labels}
                        data={enrollmentData.data}
                        cutoutPercentage={63}
                        legendPositions={"bottom"}
                      />
                      <div className="doughnut-center">
                        {locationData.programCustomers.length + locationData.partiesCustomers.length + locationData.eventsCustomers.length}
                      </div>
                    </div>
                  </div>
                  <div className="location-box box">
                    <h2>Total Revenue at this Location</h2>
                    <div
                      className="info"
                      style={{ paddingTop: "20px", paddingBottom: "20px" }}
                    >
                      <h3>{totalRevenue}</h3>
                      <p
                        style={{
                          color: "#a1a1a1",
                          fontStyle: "italic",
                          fontSize: "0.8rem"
                        }}
                        className="info-components"
                      >
                        Discounts : {totalDiscounts}
                      </p>
                      <p
                        style={{
                          color: "#a1a1a1",
                          fontStyle: "italic",
                          fontSize: "0.8rem",
                          paddingBottom: "40px"
                        }}
                        className="info-components"
                      >
                        Refunds : {totalRefunds}
                      </p>
                      <p>Location: {selectedLocation}</p>
                    </div>
                  </div>
                </div>
                <div className="boxes">
                  <div className="location-box box location-graphs">
                    <h2>Orders Purchased By</h2>
                    <div className="location-doughnut-container">
                      <Doughnut
                        labels={customerTypeData.labels}
                        data={customerTypeData.data}
                        cutoutPercentage={63}
                        legendPositions={"bottom"}
                      />
                      <div className="doughnut-center">
                        {customerTypeData.data.reduce((a, b) => a + b, 0)}
                      </div>
                    </div>
                  </div>
                  <div className="location-box box">
                    <h2>Demographics Information</h2>
                    <div
                      className="info"
                      style={{ paddingTop: "20px", paddingBottom: "20px" }}
                    >
                      <h3>Avg age: {formatAverageAge(demographics.averageAge)}</h3>
                      <p
                        style={{
                          color: "#a1a1a1",
                          fontStyle: "italic",
                          fontSize: "0.8rem"
                        }}
                        className="info-components"
                      >
                        Children Orders : {demographics.totalChildren}
                      </p>
                      <p
                        style={{
                          color: "#a1a1a1",
                          fontStyle: "italic",
                          fontSize: "0.8rem",
                          paddingBottom: "40px"
                        }}
                        className="info-components"
                      >
                        Parent Orders (Non-Children) :{" "}
                        {demographics.totalNonChildren}
                      </p>
                      <p>Location: {selectedLocation}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ReportContainer
              title="Enrollments"
              subtitles={reportSubtitles}
              csvHeaders={csvHeaders}
              csvData={getCSVData()}
              fileName={`Enrollments_${selectedLocation}.csv`}
              buttonText="Export Table"
              isLarge
            >
              <div className="report-table-large">
                <ReportTable
                  headings={[
                    { label: "Customer Name", className: "table-item-bold" },
                    { label: "Email", className: "table-item-bold" },
                    { label: "Listing", className: "table-item-bold" },
                    { label: "Status", className: "table-item-bold" },
                    { label: "Date", className: "table-item-bold" }
                  ]}
                  body={formatTableData()}
                />
              </div>
            </ReportContainer>
            <ReportContainer
              title="Orders"
              subtitles={reportSubtitles}
              csvHeaders={[
                { label: "Customer", key: "customer" },
                { label: "Date", key: "date" },
                { label: "Listing", key: "listing" },
                { label: "Modifiers", key: "modifiers" },
                { label: "Total", key: "total" }
              ]}
              csvData={displayOrders.map(order => ({
                customer: order[0],
                date: order[1],
                listing: order[2],
                modifiers: order[3],
                total: order[4]
              }))}
              fileName={`Orders_${selectedLocation}.csv`}
              buttonText="Export Table"
              isLarge
            >
              <div className="report-table-large">
                <ReportTable
                  headings={[
                    { label: "Customer" },
                    { label: "Date" },
                    { label: "Listing" },
                    { label: "Modifiers" },
                    {
                      label: "Total",
                      className: "table-item-money",
                      style: { textAlign: "right" }
                    }
                  ]}
                  body={displayOrders}
                />
              </div>
            </ReportContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationsReport;
