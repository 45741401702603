import { useState, useEffect, useGlobal, React } from "reactn";
import axios from "axios";
import { format, addWeeks } from "date-fns";

import _ from "lodash";

const formatMoney = amount => {
  return (parseFloat(amount) / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
};

/**
 * Structures the order info into the table format for use of the orders receipt
 * @param {Object} - Order data from the backend, can be program, online, event or membership
 * @returns {Object} {bodyData, footData}
 */
export const useOrderData = (order, isDeposit, couponName) => {
  // isDeposit is not being passed in correctly
  isDeposit = order.justDeposit ? "true" : "false";
  console.log("isDeposit", isDeposit);
  let item = null;
  let deposit = null;
  let promo = null;
  let coupon = null;
  let discount = null;
  let subTotal = null;
  let tax = null;
  let creditCardFee = null;
  let giftCard = null;
  let total = null;
  let paused = null;
  let userPaidTotal = null;
  let refunded = null;
  const bodyData = [];
  const footData = [];

  console.log("order", order);
  // if (parseFloat(order.depositAmount) !== 0) {
  //   deposit = [
  //     { value: `Deposit - ${order?.product?.program_name || ""}` },
  //     { value: "" },
  //     { value: "" },
  //     { value: "1" },
  //     { value: `${formatMoney(order.depositAmount)}` }
  //   ];
  // }
  if (parseFloat(order.couponAmount) !== 0) {
    const isPercentage = order.coupon.type === "Percentage";
    const type = order.coupon.type === "Percentage" ? "%" : "$";

    if (couponName) {
      coupon = [
        {
          value: `Coupon: ${couponName}`
        },
        { value: "" },
        { value: `- $${(parseFloat(order.couponAmount) / 100).toFixed(2)}` },
        { value: "" },
        { value: `` }
      ];
    } else {
      coupon = [
        {
          value: `Coupon: ${
            isPercentage
              ? `${order.coupon.amount_off}${type} off`
              : `$${order.coupon.amount_off} off`
          }`
        },
        { value: "" },
        { value: `- $${(parseFloat(order.couponAmount) / 100).toFixed(2)}` },
        { value: "" },
        { value: `` }
      ];
    }
  }
  if (order.discountAmount && parseFloat(order.discountAmount) !== 0) {
    discount = [
      { value: "Discount" },
      { value: "" },
      { value: `- $${(parseFloat(order.discountAmount) / 100).toFixed(2)}` },
      { value: "" },
      { value: `` }
    ];
  }
  if (parseFloat(order.promotionAmount) !== 0) {
    promo = [
      { value: "Promotion Applied" },
      { value: "" },
      { value: `- $${parseFloat(order.promotionAmount) / 100}` },
      { value: "1" },
      { value: "" }
    ];
  }
  // if (parseFloat(order.creditCardFeeAmount) !== 0) {
  //   creditCardFee = [
  //     { value: "Credit Card Fees: 1.5%" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "1" },
  //     { value: `+ $${parseFloat(order.creditCardFeeAmount) / 100}` }
  //   ];
  // }
  // if (parseFloat(order.itemAmount) !== 0 && order.type !== "Invoice") {
  //   let productName;
  //   if (
  //     order.product_table === "partner_programs" ||
  //     order.product_table === "partner_online"
  //   ) {
  //     let programStart, programEnd;
  //     const start = new Date(order.product.program_start) || new Date();
  //     if (order.product.number_of_weeks === 0) {
  //       programStart = format(start, "LLL dd, yyyy");
  //     } else {
  //       programStart = format(start, "LLL dd, yyyy");
  //       programEnd = format(
  //         addWeeks(new Date(start), order.product.number_of_weeks),
  //         "LLL dd, yyyy"
  //       );
  //     }
  //     productName = `${order.product.program_name} (${programStart}${
  //       order.product.number_of_weeks === 0 ? "" : ` to ${programEnd}`
  //     })`;
  //   }
  //   if (order.product_table === "partner_event") {
  //     const start = format(
  //       new Date(order.product.event_start),
  //       "LLLL dd, yyyy"
  //     );
  //     const end = format(new Date(order.product.event_end), "LLLL dd, yyyy");
  //     productName = `${order.product.event_title} (${start} to ${enuseORderDatad})`;
  //   }
  //   if (order.product_table === "partner_memberships") {
  //     productName = `${order.product.membership_name}`;
  //   }
  //   const pItemName = JSON.parse(order?.invoice_details?.[0] || null)?.itemName;
  //   if (pItemName) {
  //     productName = pItemName;
  //   }

  //   console.log("line 105");
  //   item = [
  //     {
  //       value: `${productName} ${
  //         order.session_quantity ? `x(${order.session_quantity})` : ""
  //       }`
  //     },
  //     { value: "" },
  //     { value: "" },
  //     { value: "1" },
  //     {
  //       value: `$${
  //         (parseFloat(order.itemAmount) / 100) *
  //         (order.session_quantity ? order.session_quantity : 1)
  //       }`
  //     }
  //   ];
  // }

  // if (order.type === "Invoice") {
  order.invoice_details.forEach(inv => {
    const invoice = JSON.parse(inv);
    console.log("invoice dets", invoice);

    let discountPrice = 0;
    if (invoice?.discount?.type) {
      if (invoice?.discount?.type === "Percentage") {
        console.log(
          "parseFloat(invoice.amount_off / 100)",
          parseFloat(invoice?.discount?.amount_off) / 100
        );
        console.log("parseFloat(invoice.rate)", parseFloat(invoice.rate));

        const price =
          (parseFloat(invoice.rate / 100) *
            parseInt(invoice.quantity) *
            parseFloat(invoice?.discount?.amount_off)) /
          100;
        discountPrice = price;
      }
      if (invoice?.discount?.type === "Amount") {
        discountPrice = parseFloat(invoice?.discount?.amount_off);
      }
    } else if (invoice?.discount?.discount_type) {
      if (invoice?.discount?.discount_type === "Percentage") {
        const price =
          (parseFloat(invoice.rate / 100) *
            parseInt(invoice.quantity) *
            parseFloat(invoice?.discount?.discount_percent)) /
          100;
        discountPrice = price;
      } else if (invoice?.discount?.discount_type === "Fixed Amount") {
        discountPrice = parseFloat(invoice?.discount?.discount_fixed) / 100;
      }
    }
    bodyData.push([
      { value: invoice.itemName },
      { value: `${formatMoney(invoice.rate)}` },
      { value: `-${formatMoney(discountPrice * 100)}` },
      { value: invoice.quantity },
      {
        value: `${formatMoney(
          Math.max(0, invoice.amount - discountPrice * 100)
        )}`
      }
    ]);
  });
  // }

  if (order.giftDiscount && parseFloat(order?.giftDiscount) !== 0) {
    giftCard = [
      { value: "Gift Card" },
      { value: "" },
      { value: "" },
      { value: "1" },
      { value: `+ $${parseFloat(order?.giftDiscount) / 100}` }
    ];
  }

  if (parseFloat(order.taxAmount) !== 0 && order.taxAmount !== null) {
    let taxPercentage;
    if (order.product?.plans) {
      taxPercentage = JSON.parse(order.product.plans[order.planId]).isTaxEnabled
        ? JSON.parse(order.product.plans[order.planId]).taxInfo[0].percentage
        : null;
    } else if (order.product?.prices) {
      taxPercentage = JSON.parse(order.product.prices[order.planId])
        .isTaxEnabled
        ? JSON.parse(order.product.prices[order.planId]).taxInfo[0].percentage
        : null;
    } else if (order.product?.tickets) {
      taxPercentage = JSON.parse(order.product.tickets[order.planId])
        .isTaxEnabled
        ? JSON.parse(order.product.tickets[order.planId]).taxInfo[0].percentage
        : null;
    }
    tax = [
      { value: `${taxPercentage ? `Taxes @ ${taxPercentage}%` : `Taxes`}` },
      { value: "" },
      { value: "" },
      { value: "1" },
      { value: `+ $${(parseFloat(order.taxAmount) / 100).toFixed(2)}` }
    ];
  }

  if (parseFloat(order.itemAmount) !== 0) {
    let amount = parseFloat(order.itemAmount);
    let addOnAmount =
      order && order.addOnSemesterItemAmount
        ? parseFloat(order.addOnSemesterItemAmount)
        : 0;
    if (parseFloat(order.couponAmount) !== 0) {
      amount -= parseFloat(order.couponAmount);
    }
    if (parseFloat(order.promotionAmount) !== 0) {
      amount -= parseFloat(order.promotionAmount);
    }
    subTotal = [
      { value: "Subtotal", style: { fontWeight: "bold", color: "black" } },
      { value: "" },
      { value: "" },
      { value: "1" },
      {
        value: `${formatMoney(
          Math.max(
            0,
            (parseFloat(amount) + parseFloat(addOnAmount)) *
              (order.session_quantity ? order.session_quantity : 1)
          )
        )}`,
        style: { fontWeight: "bold", color: "black" }
      }
    ];
  }

  if (!total) {
    let discount = "";
    if (
      parseInt(order.discountAmount) !== 0 &&
      !isNaN(parseInt(order.discountAmount))
    ) {
      discount = `-$${(parseInt(order.discountAmount) / 100).toFixed(2)}`;
    }

    total = [
      { value: "Total", style: { fontWeight: "bold", color: "black" } },
      { value: "" },
      { value: discount },
      { value: "1" },
      {
        value: `${
          order && order.type === "Installment"
            ? formatMoney(
                parseFloat(
                  parseFloat(order.amount) + parseFloat(order.taxAmount)
                )
              )
            : formatMoney(order.amount)
        }`,
        style: { fontWeight: "bold", color: "black" }
      }
    ];
  }

  let additionalText = "";
  let additionalColor = "#696969";
  console.log("MY ORDER", order);
  if (
    order.product_table === "partner_gift_cards" ||
    order.stripe_transaction_id?.includes("sub_")
  ) {
    additionalText = "";
  } else if (order.userPaymentMethod === "Credit Card") {
    let creditCardFee = 0;
    if (order.cartOrder) {
      creditCardFee = (
        ((parseInt(order.userPaidAmount) - parseInt(order.original_amount)) /
          parseInt(order.original_amount)) *
        100
      ).toFixed(2);

      additionalText = `(Inclusive of Credit Card Fee ${parseFloat(
        creditCardFee
      )}%)`;
    } else {
      additionalText = `(Inclusive of Credit Card Fee ${
        order?.product?.transaction_cost ? "1.5" : "3"
      }%)`;
    }
    console.log("CREDIT CARD FEE", creditCardFee);
  } else if (order.userPaymentMethod === "EFT Pre-Auth") {
    additionalText = `(paid via EFT / Pre-Authorization)`;
  }

  if (order.status === "Failed") {
    additionalText += " (Failed)";
    additionalColor = "rgb(223 14 14)";
  }

  if (order.is_paused) {
    let amount = parseFloat(order.itemAmount);
    if (parseFloat(order.couponAmount) !== 0) {
      amount -= parseFloat(order.couponAmount);
    }
    if (parseFloat(order.promotionAmount) !== 0) {
      amount -= parseFloat(order.promotionAmount);
    }

    paused = [
      {
        value: "Enrollment Paused",
        style: { fontWeight: "bold", color: "black" }
      },
      { value: "" },
      { value: "" },
      { value: "1" },
      {
        value: `-${formatMoney(
          parseFloat(amount) *
            (order.session_quantity ? order.session_quantity : 1)
        )}`,
        style: { fontWeight: "bold", color: "black" }
      }
    ];
  }

  userPaidTotal = [
    {
      value:
        order.invoice_status === "Paid Offline"
          ? `User Paid Total (Offline)`
          : `User Paid Total`,
      style: { fontWeight: "bold", color: "black" },
      additionalText: additionalText,
      additionalStyle: {
        color: additionalColor,
        fontStyle: "italic",
        fontWeight: 400
      }
    },
    { value: "" },
    { value: "" },
    { value: "" },
    {
      value: `${
        order.type === "Invoice"
          ? formatMoney(order.userPaidAmount)
          : order.invoice_status === "Paid Offline"
          ? formatMoney(order.amount)
          : order.product?.transaction_cost
          ? order && order.type === "Installment"
            ? formatMoney(
                parseFloat(order.amount) * parseFloat(order.taxAmount) * 1.015
              )
            : formatMoney(order.amount * 1.015)
          : order.product?.auto_withdraw
          ? formatMoney(order.amount)
          : order && order.type === "Installment"
          ? formatMoney(
              (parseFloat(order.amount) + parseFloat(order.taxAmount)) * 1.03
            )
          : formatMoney(order.amount * 1.03)
      }`,
      style: { fontWeight: "bold", color: "black" }
    }
  ];

  if (parseFloat(order.refundedAmount) > 0) {
    const lineText = `Refunded $${(order.refundedAmount / 100).toFixed(2)}`;
    refunded = [
      {
        value: lineText,
        style: {
          fontWeight: "bold",
          fontStyle: "italic",
          color: "rgb(27, 200, 138)"
        }
      },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" }
    ];
  }

  // if (order.userPaymentMethod === "Credit Card") {
  //   userPaidTotal = [
  //     {
  //       value: `User Paid Total`,
  //       style: { fontWeight: "bold", color: "black" },
  //       additionalText: `(Inclusive of Credit Card Fee ${
  //         order?.product?.transaction_cost ? "1.5" : "3"
  //       }%)`,
  //       additionalStyle: {
  //         color: "#696969",
  //         fontStyle: "italic",
  //         fontWeight: 400
  //       }
  //     },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     {
  //       value: `$${parseFloat(order.userPaidAmount / 100).toFixed(2)}`,
  //       style: { fontWeight: "bold", color: "black" }
  //     }
  //   ];
  // }
  // if (order.userPaymentMethod === "EFT Pre-Auth") {
  //   userPaidTotal = [
  //     {
  //       value: `User Paid Total`,
  //       style: { fontWeight: "bold", color: "black" },
  //       additionalText: `(paid via EFT / Pre-Authorization)`,
  //       additionalStyle: {
  //         color: "#696969",
  //         fontStyle: "italic",
  //         fontWeight: 400
  //       }
  //     },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     {
  //       value: `$${parseFloat(order.userPaidAmount / 100).toFixed(2)}`,
  //       style: { fontWeight: "bold", color: "black" }
  //     }
  //   ];
  // }

  if (isDeposit === "true") {
    deposit && bodyData.push(deposit);
    let depositTotal;
    if (parseFloat(order.depositAmount) !== 0) {
      depositTotal = [
        { value: "Total", style: { fontWeight: "bold", color: "black" } },
        { value: "" },
        { value: "" },
        { value: "1" },
        {
          value: `$${parseFloat(order.depositAmount) / 100}`,
          style: { fontWeight: "bold", color: "black" }
        }
      ];
    }
    depositTotal && footData.push(depositTotal);
  } else {
    item && bodyData.push(item);
    promo && bodyData.push(promo);
    coupon && bodyData.push(coupon);
    discount && bodyData.push(discount);
    subTotal && bodyData.push(subTotal);
    tax && bodyData.push(tax);
    creditCardFee && bodyData.push(creditCardFee);
    giftCard && bodyData.push(giftCard);
    paused && footData.push(paused);
    total && footData.push(total);
    userPaidTotal && footData.push(userPaidTotal);
    refunded && footData.push(refunded);
  }

  return {
    bodyData,
    footData
  };
};

/**
 * Structures the order info into the table format for use of the orders receipt
 * Does not take direct amounts
 * @param {Object} - Order data from the backend, can be program, online, event or membership
 * @returns {Object} {bodyData, footData}
 */
export const useOrderCalculatedData = (order, isDeposit) => {
  console.log("isDeposit", isDeposit);
  console.log("order", order);

  let item = null;
  let deposit = null;
  let promo = null;
  let coupon = null;
  let discount = null;
  let subTotalArray = null;
  let tax = null;
  let creditCardFee = null;
  let giftCard = null;
  let total = null;
  let paused = null;
  let userPaidTotal = null;
  let refunded = null;

  const bodyData = [];
  const footData = [];

  let stripeTotal;
  let otherTotal;
  if (order.stripe && order.stripe.total) {
    stripeTotal = parseFloat(order.stripe.total / 100).toFixed(2);
  }
  if (order.stripe && order.stripe.amount) {
    stripeTotal = parseFloat(
      order.stripe.amount / 100 - order.stripe.amount_refunded / 100
    ).toFixed(2);
  }
  if (!order.stripe && order.type !== "Installment") {
    otherTotal = parseFloat(getProductName(order).price).toFixed(2);
  }
  if (order.type === "Installment") {
    otherTotal = parseFloat(
      getProductName(order).price + getProductName(order).taxAmount
    ).toFixed(2);
  }
  if (order.product?.transaction_cost) {
    const tranCost = parseFloat(getProductName(order).price * 0.015);

    otherTotal = parseFloat(
      parseInt(getProductName(order).price) + tranCost
    ).toFixed(2);
  }

  let subTotal;
  if (!order.coupon) {
    subTotal = parseFloat(getProductName(order).price);
  }

  if (!order.promotionId) {
    subTotal = parseFloat(getProductName(order).price);
  }

  let promoCreditFee;
  if (order.promotionId) {
    if (order.promotion.discount_percent) {
      const discount = parseInt(
        getProductName(order).price * (order.promotion.discount_percent / 100)
      );
      subTotal = parseFloat(getProductName(order).price - discount);
      const creditAmount =
        parseFloat(getProductName(order).price - discount) * 0.015;

      promoCreditFee = parseFloat(
        (getProductName(order).price - discount) * 0.015
      ).toFixed(2);
    } else {
      subTotal = parseFloat(
        parseInt(getProductName(order).price - order.promotion.discount_fixed)
      ).toFixed(2);
    }
  }

  // Coupon Applied Manual Recurring
  if (order.manual && order.coupon && order.type === "Recurring") {
    otherTotal = parseFloat(parseFloat(order.amount) / 100).toFixed(2);
  }

  // Coupon Applied Manual Semester
  if (order.manual && order.coupon && order.type !== "Recurring") {
    otherTotal = parseFloat(parseFloat(order.amount) / 100).toFixed(2);

    const couponDiscount =
      order.coupon.type === "Percentage"
        ? parseFloat(
            getProductName(order).price *
              (parseFloat(order.coupon.amount_off) / 100)
          )
        : parseFloat(parseFloat(order.coupon.amount_off));

    subTotal = parseFloat(parseFloat(subTotal) - couponDiscount).toFixed(2);
  }

  if (order.coupon && !order.manual) {
    const couponDiscount =
      order.coupon.type === "Percentage"
        ? parseFloat(
            getProductName(order).price *
              (parseFloat(order.coupon.amount_off) / 100)
          )
        : parseFloat(parseFloat(order.coupon.amount_off));

    subTotal = parseFloat(parseFloat(subTotal) - couponDiscount).toFixed(2);
  }

  console.log("order", order);
  if (getProductName(order).deposit) {
    console.log("line 384");
    deposit = [
      { value: "Deposit" },
      { value: "" },
      { value: "" },
      { value: "1" },
      { value: `$${getProductName(order).deposit}` }
    ];
  }
  if (order.coupon) {
    const calculatedCouponAmount =
      order.coupon.type === "Percentage"
        ? parseFloat(
            getProductName(order).price *
              (parseFloat(order.coupon.amount_off) / 100)
          ).toFixed(2)
        : parseFloat(parseFloat(order.coupon.amount_off)).toFixed(2);

    const isPercentage = order.coupon.type === "Percentage" ? true : false;
    const type = order.coupon.type === "Percentage" ? "%" : "$";
    console.log("line 404");
    coupon = [
      {
        value: `Coupon: ${
          isPercentage
            ? `${order.coupon.amount_off}${type} off`
            : `$${order.coupon.amount_off} off`
        }`
      },
      { value: "" },
      { value: `- $${calculatedCouponAmount}` },
      { value: "1" },
      { value: `` }
    ];
  }
  if (order.discountAmount && parseInt(order.discountAmount) !== 0) {
    discount = [
      { value: "Discount" },
      { value: "" },
      { value: `- $${parseFloat(order.discountAmount).toFixed(2)}` },
      { value: "" },
      { value: `` }
    ];
  }
  if (order.promotionId) {
    const promoAmount = order.promotion.discount_percent
      ? parseFloat(
          getProductName(order).price * (order.promotion.discount_percent / 100)
        ).toFixed(2)
      : parseFloat(order.discount_fixed).toFixed(2);
    console.log("line 425");
    promo = [
      { value: "Promotion Applied" },
      { value: "" },
      { value: "" },
      { value: "1" },
      { value: `- $${promoAmount}` }
    ];
  }
  // if (getProductName(order).isCreditCareFee) {
  //   const creditAmount = !order.promotionId
  //     ? parseFloat(getProductName(order).creditCardFee).toFixed(2)
  //     : promoCreditFee;
  //   console.log("line 438");
  //   creditCardFee = [
  //     { value: "Credit Card Fees: 1.5%" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "1" },
  //     { value: `+ $${creditAmount}` }
  //   ];
  // }

  let productName;
  if (
    order.product_table === "partner_programs" ||
    order.product_table === "partner_online"
  ) {
    let programStart, programEnd;
    const start = new Date(order.product.program_start) || new Date();
    if (order.product.number_of_weeks === 0) {
      programStart = format(start, "LLLL dd, yyyy");
    } else {
      programStart = format(start, "LLLL dd, yyyy");
      programEnd = format(
        addWeeks(new Date(start), order.product.number_of_weeks),
        "LLLL dd, yyyy"
      );
    }
    productName = `${order.product.program_name} (${programStart}${
      order.product.number_of_weeks === 0 ? "" : ` to ${programEnd}`
    })`;
  }
  if (order.product_table === "partner_event") {
    const start = format(new Date(order.product.event_start), "LLLL dd, yyyy");
    const end = format(new Date(order.product.event_end), "LLLL dd, yyyy");
    productName = `${order.product.event_title} (${start} to ${end})`;
  }
  if (order.product_table === "partner_memberships") {
    productName = `${order.product.membership_name}`;
  }

  console.log("OrderReceiptUtil.jsx 478: productName", productName);
  const productAmount = parseFloat(getProductName(order).price).toFixed(2);
  console.log("line 483");
  item = [
    { value: `${productName}` },
    { value: "" },
    { value: "" },
    { value: "1" },
    { value: `$${productAmount}` }
  ];

  if (order.giftDiscount && parseFloat(order?.giftDiscount) !== 0) {
    console.log("line 494");
    giftCard = [
      { value: "Gift Card" },
      { value: "" },
      { value: "" },
      { value: "1" },
      { value: `+ $${parseFloat(order?.giftDiscount) / 100}` }
    ];
  }

  console.log("IS TAX ENABLED INVOICE", getProductName(order).isTaxEnabled);
  if (getProductName(order).isTaxEnabled) {
    const calcTaxAmount = parseFloat(getProductName(order).taxAmount).toFixed(
      2
    );

    let taxPercentage;
    if (order.product.plans) {
      taxPercentage = JSON.parse(order.product.plans[order.planId]).isTaxEnabled
        ? JSON.parse(order.product.plans[order.planId]).taxInfo[0].percentage
        : null;
    }
    if (order.product.prices) {
      taxPercentage = JSON.parse(order.product.prices[order.planId])
        .isTaxEnabled
        ? JSON.parse(order.product.prices[order.planId]).taxInfo[0].percentage
        : null;
    }
    if (order.product.tickets) {
      taxPercentage = JSON.parse(order.product.tickets[order.planId])
        .isTaxEnabled
        ? JSON.parse(order.product.tickets[order.planId]).taxInfo[0].percentage
        : null;
    }
    console.log("line 527");
    tax = [
      { value: `Taxes @ ${taxPercentage}%` },
      { value: "" },
      { value: "" },
      { value: "1" },
      { value: `+ $${calcTaxAmount}` }
    ];
  }

  if (isDeposit === "false") {
    console.log("line 538");
    subTotalArray = [
      { value: "Subtotal", style: { fontWeight: "bold", color: "black" } },
      { value: "" },
      { value: "" },
      { value: "1" },
      {
        value: `${formatMoney(subTotal)}`,
        style: { fontWeight: "bold", color: "black" }
      }
    ];
  }

  console.log("line 550");
  total = [
    { value: "Total", style: { fontWeight: "bold", color: "black" } },
    { value: "" },
    { value: "" },
    { value: "1" },
    {
      value: `${formatMoney(order.amount)}`,
      style: { fontWeight: "bold", color: "black" }
    }
  ];

  if (order.is_paused) {
    paused = [
      {
        value: "Enrollment Paused",
        style: { fontWeight: "bold", color: "black" }
      },
      { value: "" },
      { value: "" },
      { value: "1" },
      {
        value: `-${formatMoney(subTotal)}`,
        style: { fontWeight: "bold", color: "black" }
      }
    ];
  }
  let additionalText = "";
  let additionalColor = "#696969";

  if (
    order.product_table === "partner_event" ||
    order.product_table === "partner_gift_cards" ||
    order.stripe_transaction_id?.includes("sub_")
  ) {
    additionalText = "";
  } else if (order.userPaymentMethod === "Credit Card") {
    additionalText = `(Inclusive of Credit Card Fee ${
      order?.product?.transaction_cost ? "1.5" : "3"
    }%)`;
  } else if (order.userPaymentMethod === "EFT Pre-Auth") {
    additionalText = `(paid via EFT / Pre-Authorization)`;
  }

  if (order.status === "Failed") {
    additionalText += " (Failed)";
    additionalColor = "red";
  }

  userPaidTotal = [
    {
      value:
        order.invoice_status === "Paid Offline"
          ? `User Paid Total (Offline)`
          : `User Paid Total`,
      style: { fontWeight: "bold", color: "black" },
      additionalText: additionalText,
      additionalStyle: {
        color: additionalColor,
        fontStyle: "italic",
        fontWeight: 400
      }
    },
    { value: "" },
    { value: "" },
    { value: "" },
    {
      value: `${
        order.invoice_status === "Paid Offline"
          ? formatMoney(order.amount)
          : formatMoney(order.userPaidAmount)
      }`,
      style: { fontWeight: "bold", color: "black" }
    }
  ];

  if (parseFloat(order.refundedAmount) > 0) {
    const lineText = `Refunded $${(order.refundedAmount / 100).toFixed(2)}`;
    refunded = [
      {
        value: lineText,
        style: {
          fontWeight: "bold",
          fontStyle: "italic",
          color: "rgb(27, 200, 138)"
        }
      },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" }
    ];
  }

  // if (order.userPaymentMethod === "Credit Card") {
  //   console.log("line 563");
  //   userPaidTotal = [
  //     {
  //       value: `User Paid Total`,
  //       style: { fontWeight: "bold", color: "black" },
  //       additionalText: `(Inclusive of Credit Card Fee ${
  //         order?.product?.transaction_cost ? "1.5" : "3"
  //       }%)`,
  //       additionalStyle: {
  //         color: "#696969",
  //         fontStyle: "italic",
  //         fontWeight: 400
  //       }
  //     },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     {
  //       value: `$${parseFloat(order.userPaidAmount / 100).toFixed(2)}`,
  //       style: { fontWeight: "bold", color: "black" }
  //     }
  //   ];
  // }
  // if (order.userPaymentMethod === "EFT Pre-Auth") {
  //   console.log("line 587");
  //   userPaidTotal = [
  //     {
  //       value: `User Paid Total`,
  //       additionalText: `(paid via EFT / Pre-Authorization)`,
  //       style: { fontWeight: "bold", color: "black" },
  //       additionalStyle: {
  //         color: "#696969",
  //         fontStyle: "italic",
  //         fontWeight: 400
  //       }
  //     },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     {
  //       value: `$${parseFloat(order.userPaidAmount / 100).toFixed(2)}`,
  //       style: { fontWeight: "bold", color: "black" }
  //     }
  //   ];
  // }

  if (isDeposit === "true") {
    deposit && bodyData.push(deposit);
    let depositTotal;
    if (getProductName(order).deposit) {
      console.log("line 604");
      depositTotal = [
        { value: "Total", style: { fontWeight: "bold", color: "black" } },
        { value: "" },
        { value: "" },
        { value: "1" },
        {
          value: `$${getProductName(order).deposit}`,
          style: { fontWeight: "bold", color: "black" }
        }
      ];
    }
    depositTotal && footData.push(depositTotal);
  } else {
    console.log(
      "Item Block",
      item,
      promo,
      coupon,
      subTotalArray,
      tax,
      creditCardFee
    );
    item && bodyData.push(item);
    promo && bodyData.push(promo);
    coupon && bodyData.push(coupon);
    discount && bodyData.push(discount);
    subTotalArray && bodyData.push(subTotalArray);
    tax && bodyData.push(tax);
    creditCardFee && bodyData.push(creditCardFee);
    giftCard && bodyData.push(giftCard);
    paused && footData.push(paused);
    total && footData.push(total);
    userPaidTotal && footData.push(userPaidTotal);
    refunded && footData.push(refunded);
  }

  return {
    bodyData,
    footData
  };
};

/**
 * This function takes in an object and perform individual calculations to give the calculated amounts
 * @param {Object} - has details about the order
 * @returns
 */
export const getProductName = r => {
  if (
    r.product_table === "partner_programs" ||
    r.product_table === "partner_online"
  ) {
    let couponDiscount = 0;
    if (r.type === "Drop-in" && r.coupon) {
      const plan = JSON.parse(r.product.plans[r.planId]);

      couponDiscount =
        r.coupon.type === "Percentage"
          ? parseFloat(
              parseFloat(parseFloat(plan.total_price) / plan.dayCount) *
                (1 + parseInt(plan.dropin_interest) / 100) *
                (parseFloat(r.coupon.amount_off) / 100)
            )
          : parseFloat(r.coupon.amount_off);
    }

    return {
      name: r.product.program_name,
      type: "Program",
      price:
        r.type === "Recurring"
          ? JSON.parse(r.product.plans[r.planId]).tuition_rate
          : r.type === "Drop-in"
          ? (JSON.parse(r.product.plans[r.planId]).total_price /
              JSON.parse(r.product.plans[r.planId]).dayCount) *
            r.selectedDays.length *
            (1 +
              parseInt(JSON.parse(r.product.plans[r.planId]).dropin_interest) /
                100)
          : r.type === "Installment"
          ? JSON.parse(r.product.plans[r.planId]).total_price *
            (1 +
              parseInt(
                JSON.parse(r.product.plans[r.planId]).installments_interest
              ) /
                100)
          : JSON.parse(r.product.plans[r.planId]).total_price,
      deposit:
        r.type === "Recurring"
          ? JSON.parse(r.product.plans[r.planId]).deposit
          : null,
      isTaxEnabled: !!JSON.parse(r.product.plans[r.planId]).isTaxEnabled,
      taxAmount: JSON.parse(r.product.plans[r.planId]).isTaxEnabled
        ? r.type === "Recurring"
          ? (JSON.parse(r.product.plans[r.planId]).tuition_rate -
              parseFloat(
                !r.coupon
                  ? 0
                  : r.coupon.type === "Percentage"
                  ? parseFloat(
                      JSON.parse(r.product.plans[r.planId]).tuition_rate *
                        (parseFloat(r.coupon.amount_off) / 100)
                    )
                  : parseFloat(parseFloat(r.coupon.amount_off) / 100)
              )) *
            (JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage / 100)
          : r.type === "Drop-in"
          ? (parseFloat(
              parseFloat(
                JSON.parse(r.product.plans[r.planId]).total_price /
                  JSON.parse(r.product.plans[r.planId]).dayCount
              ) * r.selectedDays.length
            ) *
              parseFloat(
                1 +
                  parseInt(
                    JSON.parse(r.product.plans[r.planId]).dropin_interest
                  ) /
                    100
              ) -
              couponDiscount) *
            (JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage / 100)
          : r.type === "Installment"
          ? JSON.parse(r.product.plans[r.planId]).total_price *
            (1 +
              parseInt(
                JSON.parse(r.product.plans[r.planId]).installments_interest
              ) /
                100) *
            (JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage / 100)
          : (parseFloat(JSON.parse(r.product.plans[r.planId]).total_price) -
              parseFloat(
                !r.coupon
                  ? 0
                  : r.coupon.type === "Percentage"
                  ? parseFloat(
                      parseFloat(
                        JSON.parse(r.product.plans[r.planId]).total_price
                      ) *
                        (parseFloat(r.coupon.amount_off) / 100)
                    )
                  : parseFloat(parseFloat(r.coupon.amount_off) / 100)
              )) *
            (JSON.parse(r.product.plans[r.planId]).taxInfo[0].percentage / 100)
        : 0,
      isCreditCareFee: r.product?.transaction_cost,
      creditCardFee: r.product?.transaction_cost
        ? r.type === "Recurring"
          ? JSON.parse(r.product.plans[r.planId]).tuition_rate * 0.015 // 1.5 percent
          : r.type === "Drop-in"
          ? (JSON.parse(r.product.plans[r.planId]).total_price /
              JSON.parse(r.product.plans[r.planId]).dayCount) *
            r.selectedDays.length *
            (1 +
              parseInt(JSON.parse(r.product.plans[r.planId]).dropin_interest) /
                100) *
            0.015
          : r.type === "Installment"
          ? JSON.parse(r.product.plans[r.planId]).total_price *
            (1 +
              parseInt(
                JSON.parse(r.product.plans[r.planId]).installments_interest
              ) /
                100) *
            0.015
          : JSON.parse(r.product.plans[r.planId]).total_price * 0.015
        : 0
    };
  }
  if (r.product_table === "partner_event") {
    let couponDiscount = 0;
    if (r.coupon) {
      const plan = JSON.parse(r.product.tickets[r.planId]);

      couponDiscount =
        r.coupon.type === "Percentage"
          ? parseFloat(
              parseFloat(
                parseFloat(plan.ticket_price) *
                  (parseFloat(r.coupon.amount_off) / 100)
              )
            )
          : parseFloat(r.coupon.amount_off);
    }
    return {
      name: r.product.event_title,
      type: "Event",
      price:
        JSON.parse(r.product.tickets[r.planId]).ticket_status === "Free"
          ? 0
          : JSON.parse(r.product.tickets[r.planId]).ticket_price,
      isTaxEnabled: !!JSON.parse(r.product.tickets[r.planId]).isTaxEnabled,
      taxAmount: JSON.parse(r.product.tickets[r.planId]).isTaxEnabled
        ? (JSON.parse(r.product.tickets[r.planId]).ticket_price -
            couponDiscount) *
          (JSON.parse(r.product.tickets[r.planId]).taxInfo[0].percentage / 100)
        : 0,
      isCreditCareFee: false
    };
  }
  if (r.product_table === "partner_memberships") {
    let couponDiscount = 0;
    if (r.coupon) {
      const plan = JSON.parse(r.product.prices[r.planId]);

      couponDiscount =
        r.coupon.type === "Percentage"
          ? parseFloat(
              parseFloat(
                parseFloat(plan.price_per_term) *
                  (parseFloat(r.coupon.amount_off) / 100)
              )
            )
          : parseFloat(r.coupon.amount_off);
    }
    return {
      name: r.product.membership_name,
      type: "Membership",
      price: JSON.parse(r.product.prices[r.planId]).price_per_term,
      isTaxEnabled: !!JSON.parse(r.product.prices[r.planId]).isTaxEnabled,
      taxAmount: JSON.parse(r.product.prices[r.planId]).isTaxEnabled
        ? (JSON.parse(r.product.prices[r.planId]).price_per_term -
            couponDiscount) *
          (JSON.parse(r.product.prices[r.planId]).taxInfo[0].percentage / 100)
        : 0,
      isCreditCareFee: false
    };
  }
};
