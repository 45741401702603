import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import ConfirmWaitlistModal from "../../../../components/modals/ConfirmWaitlistModal";
import axios from "axios";
import { Dropdown } from "semantic-ui-react";
import Switch from "@material-ui/core/Switch";
import { format } from "date-fns";
import ReactTooltip from "react-tooltip";

class SectionThree extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      days: {},
      options: [
        { label: "Please Select", value: -1 },
        { label: "Ongoing (Recurring)", value: 0 },
        ...new Array(52).fill(1).map((_, k) => ({
          label: `${k + 1} Week(s)`,
          value: k + 1
        }))
      ],
      leftIndex: [0, 2, 4, 6, 8, 10, 12, 14, 16],
      rightIndex: [1, 3, 5, 7, 9, 11, 13, 15, 17],
      openWaitlistModal: false
    };

    this.program_frequency = ["Once a week", "Twice a week"];
    this.program_type = [];

    if (this.global.online.isRecurring) {
      this.program_type.push("Ongoing");
    } else {
      this.program_type.push("Single Day", "Semester");
    }
    this.setGlobal({
      online: {
        ...this.global.online
        // program_type: -1
      }
    });
  }

  handleChange = async (e, v) => {
    if (e === "total_spots" && v === "") {
      v = null;
    }
    console.log("Global", this.global);
    let saveGlobal = true;
    if (e === "enable_waitlist" && v === true) {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/partners/virtual/enrollments/count/${this.global.online.id}`
      );
      // console.log(res);
      if (
        res.data.success &&
        res.data.data.count >= this.global.online.total_spots
      ) {
        saveGlobal = false;
        this.setState({ openWaitlistModal: v });
      }
    }
    if (saveGlobal === true) {
      this.setGlobal({
        online: {
          ...this.global.online,
          [e]: v
        }
      });
    }

    if (
      e === "program_start" &&
      parseInt(this.global.online.program_type) === 0
    ) {
      //this does not modify the field that just changed above
      this.setDaysOfWeekAndClosure(v);
    }
  };
  waitlistFullVirtual = async () => {
    await axios.post(
      `${process.env.REACT_APP_API}/partners/virtual/waitlist-rollover/${this.global.online.id}`
    );
    this.setGlobal({
      online: {
        ...this.global.online,
        enable_waitlist: true
      }
    });
    this.dispatch.saveVirtual();
  };

  setDaysOfWeekAndClosure(day) {
    const days = {
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      0: "Sunday"
    };
    this.setGlobal(
      {
        online: {
          ...this.global.online,
          days_of_week: { [days[new Date(day + "T00:00").getDay()]]: true },
          closure_dates: [],
          closure_timeframe: [null, null]
        }
      },
      () => {
        console.log("Days of week", days[new Date(day + "T00:00").getDay()]);
      }
    );
  }

  setDay(day) {
    const days = { ...this.global.online.days_of_week };
    if (days[day]) delete days[day];
    else days[day] = true;
    this.handleChange("days_of_week", days);
  }

  setCustomTime = day => {
    const days = { ...this.global.online.customTimes };
    if (days[day]) delete days[day];
    else days[day] = [-1, -1];
    this.handleChange("customTimes", days);
  };

  insertTime = (day, index) => {
    const days = { ...this.global.online.customTimes };
    const editedTimes = days[day].splice(index + 2, 0, -1, -1);
    this.handleChange("customTimes", days);
  };

  deleteTime = (day, index) => {
    const days = { ...this.global.online.customTimes };
    const editedTimes = days[day].splice(index, 2);
    this.handleChange("customTimes", days);
  };

  customForm = () => {
    const toNatural = num => {
      const int = Math.round(num);
      return int > 0 ? int : 1;
    };

    const renderCustomTimeRange = dayOfWeek => {
      return new Array(this.global.online.customTimes[dayOfWeek].length / 2)
        .fill(1)
        .map((_, index) => {
          return (
            <div className="row">
              <div className="col-5">
                <h3 className="label-names" style={{ marginLeft: "25px" }}>
                  {dayOfWeek}
                </h3>
              </div>
              <div
                className="col-7 d-flex padding-zero"
                style={{ alignItems: "center" }}
              >
                <div style={{ width: "40%" }}>
                  <formTypes.TimeDropdownSelect
                    end={parseFloat(
                      (this.global.online.customTimes &&
                        parseInt(
                          this.global.online.customTimes[dayOfWeek][
                            index * 2 + 1
                          ]
                        ) !== -1 &&
                        this.global.online.customTimes[dayOfWeek][
                          index * 2 + 1
                        ]) ||
                        null
                    )}
                    name="customTimes"
                    value={parseFloat(
                      this.global.online.customTimes[dayOfWeek][index * 2] || -1
                    )}
                    onChange={(e, v) => {
                      const times = this.global.online.customTimes;
                      times[dayOfWeek][index * 2] = v;
                      this.handleChange(e, times);
                    }}
                    searchable={false}
                  ></formTypes.TimeDropdownSelect>
                </div>
                To
                <div style={{ width: "40%" }}>
                  <formTypes.TimeDropdownSelect
                    start={parseFloat(
                      (this.global.online.customTimes &&
                        parseInt(
                          this.global.online.customTimes[dayOfWeek][index * 2]
                        ) !== -1 &&
                        this.global.online.customTimes[dayOfWeek][index * 2]) ||
                        null
                    )}
                    name="customTimes"
                    value={parseFloat(
                      this.global.online.customTimes[dayOfWeek][
                        index * 2 + 1
                      ] || -1
                    )}
                    onChange={(e, v) => {
                      const times = this.global.online.customTimes;
                      times[dayOfWeek][index * 2 + 1] = v;
                      this.handleChange(e, times);
                    }}
                    searchable={false}
                  ></formTypes.TimeDropdownSelect>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <button
                    type="button"
                    onClick={e => {
                      this.insertTime(dayOfWeek, index * 2);
                    }}
                    style={{
                      backgroundColor: "#568ae3",
                      border: "none",
                      color: "white",
                      borderRadius: "3px",
                      marginLeft: "5px"
                    }}
                  >
                    +
                  </button>
                  {this.global.online.customTimes[dayOfWeek].length !== 2 && (
                    <button
                      type="button"
                      onClick={e => {
                        this.deleteTime(dayOfWeek, index * 2);
                      }}
                      style={{
                        backgroundColor: "grey",
                        border: "none",
                        color: "white",
                        borderRadius: "3px",
                        marginLeft: "5px"
                      }}
                    >
                      -
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        });
    };

    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Unlimited Spots</h3>
            </div>
            <div className="col-7 d-flex">
              <div className="w-100">
                <Switch
                  name="unlimited_spots"
                  checked={this.global.online.unlimited_spots}
                  onClick={_ => {
                    this.handleChange(
                      "unlimited_spots",
                      !this.global.online.unlimited_spots
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {!this.global.online.unlimited_spots && (
            <>
              {this.state.openWaitlistModal && (
                <ConfirmWaitlistModal
                  onSubmit={() => {
                    this.waitlistFullVirtual();
                  }}
                  onClose={_ => this.setState({ openWaitlistModal: false })}
                  modalBody={
                    "Since this Online Virtual is currently full, all pending applications will be moved to the waitlist regardless of their status"
                  }
                />
              )}
              {" "}
              <div className="row">
                <div className="col-5">
                  {this.global.online.program_category === "Single Sessions" ? (
                    <h3 className="label-names">
                      Spots per Timeslot {""}
                      <i
                        data-tip
                        data-for="Category"
                        className="fas fa-info-circle"
                        style={{ color: "#DCDCDC" }}
                      ></i>
                      <ReactTooltip
                        id="Category"
                        place="right"
                        effect="solid"
                        type="info"
                      >
                        The max number of spots available for each timeslot
                        specified below
                      </ReactTooltip>
                    </h3>
                  ) : (
                    <h3 className="label-names">Total Spots</h3>
                  )}
                </div>
                <div className="col-7 d-flex">
                  <input
                    className="input-fields"
                    style={{ width: "100%" }}
                    type="number"
                    name="total_spots"
                    value={this.global.online.total_spots}
                    onChange={e => {
                      if (e.target.value === "") {
                        this.handleChange(e.target.name, "");
                        return;
                      }

                      this.handleChange(
                        e.target.name,
                        parseInt(parseFloat(e.target.value)).toString()
                      );
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <h3 className="label-names">Show Total Spots</h3>
                </div>
                <div className="col-7 d-flex">
                  <div className="w-100">
                    <Switch
                      name="show_total_spots"
                      checked={this.global.online.show_total_spots}
                      onClick={_ => {
                        this.handleChange(
                          "show_total_spots",
                          !this.global.online.show_total_spots
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <h3 className="label-names">
                    Enable Waitlist{" "}
                    <i
                      data-tip
                      data-for="enable_waitlist"
                      className="fas fa-info-circle"
                      style={{ color: "#DCDCDC" }}
                    ></i>
                    <ReactTooltip
                      id="enable_waitlist"
                      place="right"
                      effect="solid"
                      type="info"
                    >
                      Customers will be waitlisted once all total spots get
                      filled
                    </ReactTooltip>
                  </h3>
                </div>
                <div className="col-7 d-flex">
                  <div className="w-100">
                    <Switch
                      disabled={!this.global.online.total_spots}
                      name="enable_waitlist"
                      checked={this.global.online.enable_waitlist}
                      onClick={_ => {
                        this.handleChange(
                          "enable_waitlist",
                          !this.global.online.enable_waitlist
                        );
                        this.handleChange("unlimited_spots", false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Enable Continuous Registration{" "}
                <i
                  data-tip
                  data-for="enable_continuous_registration"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                ></i>
                <ReactTooltip
                  id="enable_continuous_registration"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  Ability for parents to enroll / register after the program's
                  start date up until the program's end date
                </ReactTooltip>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className="w-100">
                <Switch
                  name="continuous_registration"
                  isDisabled={this.global.online.isRecurring}
                  checked={
                    this.global.online.continuous_registration ||
                    this.global.online.isRecurring
                  }
                  onClick={_ => {
                    this.handleChange(
                      "continuous_registration",
                      !this.global.online.continuous_registration
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Program Type <span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <Dropdown
                placeholder="Pick a Type"
                fluid
                selection
                onChange={(_, { value }) => {
                  // const list = [{ label: "Please Select", value: -1 }];
                  // if (value == 0) {
                  //   list.pop();
                  //   list.push({ label: "Ongoing (Recurring)", value: 0 });
                  // }
                  // if (value == 1) {
                  //   list.pop();
                  //   list.push({ label: "Ongoing (Recurring)", value: 0 });
                  // }
                  // if (value == 2) {
                  //   list.pop();
                  //   list.push(
                  //     ...new Array(14).fill(1).map((_, k) => ({
                  //       label: `${k + 1} Week(s)`,
                  //       value: k + 1
                  //     }))
                  //   );
                  // }
                  // this.setState({ options: list });
                  if (value == 0) {
                    this.setDaysOfWeekAndClosure(
                      this.global.online.program_start
                    );
                  }
                  this.setGlobal({
                    online: {
                      ...this.global.online,
                      program_type: parseInt(value)
                    }
                  });
                }}
                value={parseInt(this.global.online.program_type)}
                options={
                  // this.global.online.program_category !== "Single Sessions"
                  //   ? this.program_type.map((e, i) => ({
                  //       text: `${e}`,
                  //       value: i,
                  //       key: i
                  //     }))
                  //   : [{ text: "Semester", value: 1, key: 1 }]
                  this.program_type.map((e, i) => ({
                    text: `${e}`,
                    value: i,
                    key: i
                  }))
                }
              />
            </div>
          </div>
          {parseInt(this.global.online.program_type) !== 0 &&
            !this.global.online.isRecurring && (
              <div className="row">
                <div className="col-5">
                  <h3 className="label-names">
                    Program Duration{" "}
                    {this.global.online.program_category !==
                    "Single Sessions" ? (
                      <span className="requiredField">*</span>
                    ) : (
                      ""
                    )}
                  </h3>
                </div>
                <div className="col-7 d-flex">
                  <formTypes.Select
                    name="ProgramDuration"
                    options={this.state.options.filter(f => {
                      if (
                        parseInt(this.global.online.program_type) == 0 &&
                        (f.value == -1 || f.value == 0)
                      ) {
                        return f;
                      }
                      // if (
                      //   parseInt(this.global.online.program_type) == 1 &&
                      //   (f.value == -1 || f.value == 0)
                      // ) {
                      //   return f;
                      // }
                      if (
                        parseInt(this.global.online.program_type) == 1 &&
                        f.value != 0
                      ) {
                        return f;
                      }
                    })}
                    // [...[{ label: "Please Select", value: -1 }], ...[{ label: "Ongoing (Recurring)", value: 0 }], parseInt(this.global.online.program_type) === 2 && ...new Array(13).fill(1).map((_, k) => ({
                    //   label: `${k + 1} Week(s)`,
                    //   value: k + 1
                    // }))]
                    onChange={e =>
                      this.handleChange("number_of_weeks", e.target.value)
                    }
                    value={this.global.online.number_of_weeks}
                  />
                </div>
              </div>
            )}
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Program Start Date{" "}
                <i
                  data-tip
                  data-for="program-start"
                  className="fas fa-info-circle"
                  style={{ color: "#DCDCDC" }}
                ></i>
                <ReactTooltip
                  id="program-start"
                  place="right"
                  effect="solid"
                  type="info"
                >
                  This option cannot be changed once an enrollment occurs.
                </ReactTooltip>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.DateOnly
                value={this.global.online.program_start}
                minDate={new Date()}
                onChange={(e, v) => {
                  const days = {
                    1: "Monday",
                    2: "Tuesday",
                    3: "Wednesday",
                    4: "Thursday",
                    5: "Friday",
                    6: "Saturday",
                    0: "Sunday"
                  };
                  // if (this.global.online.program_type != 0) {
                  this.handleChange(e, v);
                  // } else {
                  //   this.setGlobal({
                  //     online: {
                  //       ...this.global.online,
                  //       days_of_week: { [days[new Date(v).getDay()]]: true },
                  //       // number_of_weeks: 1,
                  //       [e]: v
                  //     }
                  //   });
                  // }
                }}
                name="program_start"
                isDisabled={
                  !this.global.enrollments || this.global.enrollments.length > 0
                }
              ></formTypes.DateOnly>
            </div>
          </div>
          {parseInt(this.global.online.program_type) !== 0 &&
            !this.global.online.isRecurring && (
              <div className="row">
                <div
                  className="col-5"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h3 className="label-names">
                    Days of Week <span className="requiredField">*</span>
                  </h3>
                </div>
                <div className="col-7 d-flex">
                  <div
                    name="days"
                    className="row days"
                    validation={"validDays"}
                    style={{ width: "100%", margin: 0, padding: 0 }}
                  >
                    <div
                      data-day="Monday"
                      className={`day-monday day ${
                        this.global.online.days_of_week &&
                        this.global.online.days_of_week["Monday"]
                          ? "selecta"
                          : ""
                      }`}
                      onClick={e => {
                        this.setDay(e.target.getAttribute("data-day"));
                        this.setCustomTime(e.target.getAttribute("data-day"));
                      }}
                    >
                      M
                    </div>
                    <div
                      data-day="Tuesday"
                      className={`day-tuesday day ${
                        this.global.online.days_of_week &&
                        this.global.online.days_of_week["Tuesday"]
                          ? "selecta"
                          : ""
                      }`}
                      onClick={e => {
                        this.setDay(e.target.getAttribute("data-day"));
                        this.setCustomTime(e.target.getAttribute("data-day"));
                      }}
                    >
                      Tu
                    </div>
                    <div
                      data-day="Wednesday"
                      className={`day-wednesday day ${
                        this.global.online.days_of_week &&
                        this.global.online.days_of_week["Wednesday"]
                          ? "selecta"
                          : ""
                      }`}
                      onClick={e => {
                        this.setDay(e.target.getAttribute("data-day"));
                        this.setCustomTime(e.target.getAttribute("data-day"));
                      }}
                    >
                      W
                    </div>
                    <div
                      data-day="Thursday"
                      className={`day-thursday day ${
                        this.global.online.days_of_week &&
                        this.global.online.days_of_week["Thursday"]
                          ? "selecta"
                          : ""
                      }`}
                      onClick={e => {
                        this.setDay(e.target.getAttribute("data-day"));
                        this.setCustomTime(e.target.getAttribute("data-day"));
                      }}
                    >
                      Th
                    </div>
                    <div
                      data-day="Friday"
                      className={`day-friday day ${
                        this.global.online.days_of_week &&
                        this.global.online.days_of_week["Friday"]
                          ? "selecta"
                          : ""
                      }`}
                      onClick={e => {
                        this.setDay(e.target.getAttribute("data-day"));
                        this.setCustomTime(e.target.getAttribute("data-day"));
                      }}
                    >
                      F
                    </div>
                    <div
                      data-day="Saturday"
                      className={`day-saturday day ${
                        this.global.online.days_of_week &&
                        this.global.online.days_of_week["Saturday"]
                          ? "selecta"
                          : ""
                      }`}
                      onClick={e => {
                        this.setDay(e.target.getAttribute("data-day"));
                        this.setCustomTime(e.target.getAttribute("data-day"));
                      }}
                    >
                      Sa
                    </div>
                    <div
                      data-day="Sunday"
                      className={`day-sunday day ${
                        this.global.online.days_of_week &&
                        this.global.online.days_of_week["Sunday"]
                          ? "selecta"
                          : ""
                      }`}
                      onClick={e => {
                        this.setDay(e.target.getAttribute("data-day"));
                        this.setCustomTime(e.target.getAttribute("data-day"));
                      }}
                    >
                      Su
                    </div>
                  </div>
                </div>
              </div>
            )}
          {this.global.online.program_category !== "Single Sessions" ? (
            <div className="row">
              <div className="col-5">
                <h3 className="label-names">
                  Times <span className="requiredField">*</span>
                </h3>
              </div>
              <div
                className="col-7 d-flex padding-zero"
                style={{ alignItems: "center" }}
              >
                <div style={{ width: "40%" }}>
                  <formTypes.TimeDropdownSelect
                    end={parseFloat(
                      (this.global.online.timing &&
                        parseInt(this.global.online.timing[1]) !== -1 &&
                        this.global.online.timing[1]) ||
                        null
                    )}
                    value={parseFloat(
                      (this.global.online.timing &&
                        this.global.online.timing[0]) ||
                        -1
                    )}
                    name="timing"
                    onChange={(e, v) =>
                      this.handleChange(e, [
                        v,
                        this.global.online.timing &&
                          this.global.online.timing[1]
                      ])
                    }
                  ></formTypes.TimeDropdownSelect>
                </div>
                To
                <div style={{ width: "40%" }}>
                  <formTypes.TimeDropdownSelect
                    start={parseFloat(
                      (this.global.online.timing &&
                        parseInt(this.global.online.timing[0]) !== -1 &&
                        this.global.online.timing[0]) ||
                        null
                    )}
                    name="timing"
                    value={parseFloat(
                      (this.global.online.timing &&
                        this.global.online.timing[1]) ||
                        -1
                    )}
                    onChange={(e, v) =>
                      this.handleChange(e, [this.global.online.timing[0], v])
                    }
                  ></formTypes.TimeDropdownSelect>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              {!this.global.online.enableCustomTimes ? (
                this.global.online.timing.length >= 2 &&
                new Array(this.global.online.timing.length / 2)
                  .fill(1)
                  .map((_, index) => {
                    return (
                      <div className="row">
                        <div className="col-5">
                          <h3 className="label-names">
                            Timing {index + 1}
                            <span className="requiredField">*</span>
                          </h3>
                        </div>
                        <div
                          className="col-7 d-flex padding-zero"
                          style={{ alignItems: "center" }}
                        >
                          <div style={{ width: "40%" }}>
                            <formTypes.TimeDropdownSelect
                              end={parseFloat(
                                (this.global.online.timing &&
                                  parseInt(
                                    this.global.online.timing[index * 2 + 1]
                                  ) !== -1 &&
                                  this.global.online.timing[index * 2 + 1]) ||
                                  null
                              )}
                              value={parseFloat(
                                this.global.online.timing[
                                  this.state.leftIndex[index]
                                ] || -1
                              )}
                              name="timing"
                              onChange={(e, v) => {
                                const times = this.global.online.timing;
                                times[this.state.leftIndex[index]] = v;
                                this.handleChange(e, times);
                              }}
                            ></formTypes.TimeDropdownSelect>
                          </div>
                          To
                          <div style={{ width: "40%" }}>
                            <formTypes.TimeDropdownSelect
                              start={parseFloat(
                                (this.global.online.timing &&
                                  parseInt(
                                    this.global.online.timing[index * 2]
                                  ) !== -1 &&
                                  this.global.online.timing[index * 2]) ||
                                  null
                              )}
                              name="timing"
                              value={parseFloat(
                                this.global.online.timing[
                                  this.state.rightIndex[index]
                                ] || -1
                              )}
                              onChange={(e, v) => {
                                const times = this.global.online.timing;
                                times[this.state.rightIndex[index]] = v;
                                this.handleChange(e, times);
                              }}
                              op2
                            ></formTypes.TimeDropdownSelect>
                          </div>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <>
                  {this.global.online.days_of_week["Monday"] &&
                    renderCustomTimeRange("Monday")}
                  {this.global.online.days_of_week["Tuesday"] &&
                    renderCustomTimeRange("Tuesday")}
                  {this.global.online.days_of_week["Wednesday"] &&
                    renderCustomTimeRange("Wednesday")}
                  {this.global.online.days_of_week["Thursday"] &&
                    renderCustomTimeRange("Thursday")}
                  {this.global.online.days_of_week["Friday"] &&
                    renderCustomTimeRange("Friday")}
                  {this.global.online.days_of_week["Saturday"] &&
                    renderCustomTimeRange("Saturday")}
                  {this.global.online.days_of_week["Sunday"] &&
                    renderCustomTimeRange("Sunday")}
                </>
              )}

              {this.global.online.program_category === "Single Sessions" &&
                !this.global.online.enableCustomTimes && (
                  <div
                    className="col-12 d-flex"
                    style={{ justifyContent: "flex-end", marginTop: "10px" }}
                  >
                    <button
                      type="button"
                      disabled={this.global.online.timing.length === 16}
                      className="btn addNew-btn" //btn-primary
                      onClick={_ =>
                        this.setGlobal({
                          online: {
                            ...this.global.online,
                            timing: [...this.global.online.timing, -1, -1]
                          }
                        })
                      }
                    >
                      Add Time Range
                    </button>
                  </div>
                )}
            </div>
          )}
          {this.global.online.program_category === "Single Sessions" && (
            <div className="row">
              <div className="col-5">
                <h3 className="label-names">Custom Times</h3>
              </div>
              <div className="col-7 d-flex">
                <div className="w-100">
                  <Switch
                    name="enableCustomTimes"
                    checked={
                      this.global.online.enableCustomTimes ? true : false
                    }
                    onClick={_ => {
                      this.handleChange(
                        "enableCustomTimes",
                        !this.global.online.enableCustomTimes
                      );
                      if (this.global.online.enableCustomTimes) {
                        const object = {};
                        if (this.global.online.days_of_week["Monday"]) {
                          object["Monday"] = [-1, -1];
                        }
                        if (this.global.online.days_of_week["Tuesday"]) {
                          object["Tuesday"] = [-1, -1];
                        }
                        if (this.global.online.days_of_week["Wednesday"]) {
                          object["Wednesday"] = [-1, -1];
                        }
                        if (this.global.online.days_of_week["Thursday"]) {
                          object["Thursday"] = [-1, -1];
                        }
                        if (this.global.online.days_of_week["Friday"]) {
                          object["Friday"] = [-1, -1];
                        }
                        if (this.global.online.days_of_week["Saturday"]) {
                          object["Saturday"] = [-1, -1];
                        }
                        if (this.global.online.days_of_week["Sunday"]) {
                          object["Sunday"] = [-1, -1];
                        }
                        this.handleChange("timing", [-1, -1]);
                        this.handleChange("customTimes", object);
                      } else {
                        this.handleChange("customTimes", {});
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {parseInt(this.global.online.program_type) !== 0 &&
            !this.global.online.isRecurring && (
              <div className="row">
                <div className="col-5">
                  <h3 className="label-names">Closure Dates</h3>
                </div>
                <div className="col-7 d-flex">
                  {/* This is a two part multi-date-picker object. First part is "formTypes.Date" and remains invisible.
                  Second part is "formTypes.DateMultiSelect" and it's open property just triggers the first part.
              */}
                  <div style={{ width: 0 }}>
                    {/* Part 1  - invisible date picker component that is triggered by "formTypes.DateMultiSelect" below. */}
                    <formTypes.Date
                      style={{ display: "none" }}
                      id="datenumber2"
                      value={null}
                      onChange={(e, v) => {
                        if (
                          !this.global.online.closure_dates.includes(
                            format(new Date(v), "LLLL dd, yyyy")
                          )
                        ) {
                          this.handleChange(e, [
                            ...this.global.online.closure_dates,
                            v
                          ]);
                        }
                      }}
                      name="closure_dates"
                    ></formTypes.Date>
                  </div>

                  {/* Part 2  - uses "react-dropdown-select", and just displays the array of dates as tiles. */}
                  <formTypes.DateMultiSelect
                    className="no-border"
                    value={this.global.online.closure_dates.map((e, k) => ({
                      name: format(new Date(e), "LLLL dd, yyyy"),
                      value: format(new Date(e), "LLLL dd, yyyy")
                    }))}
                    open={_ => document.getElementById("datenumber2").click()}
                    name="closure_dates"
                    onChange={(e, v) =>
                      this.handleChange(
                        e,
                        v.map(e => e.name)
                      )
                    }
                  ></formTypes.DateMultiSelect>
                </div>
              </div>
            )}
          {parseInt(this.global.online.program_type) !== 0 &&
            !this.global.online.isRecurring && (
              <div className="row">
                <div className="col-5">
                  <h3 className="label-names">Closure Timeframe</h3>
                </div>
                <div className="col-7 d-flex">
                  <div className={`w-100`}>
                    <formTypes.Date
                      name="closure_timeframe"
                      value={this.global.online.closure_timeframe[0]}
                      maxDate={
                        this.global.online.closure_timeframe[1]
                          ? new Date(this.global.online.closure_timeframe[1])
                          : null
                      }
                      onChange={(e, v) =>
                        this.handleChange(e, [
                          v,
                          this.global.online.closure_timeframe[1]
                        ])
                      }
                    ></formTypes.Date>
                  </div>
                  <span style={{ marginRight: "5px", marginLeft: "5px" }}>
                    To
                  </span>
                  <div className={`w-100`}>
                    <formTypes.Date
                      name="closure_timeframe"
                      value={this.global.online.closure_timeframe[1]}
                      isDisabled={!this.global.online.closure_timeframe[0]}
                      minDate={
                        this.global.online.closure_timeframe[0]
                          ? new Date(this.global.online.closure_timeframe[0])
                          : null
                      }
                      onChange={(e, v) =>
                        this.handleChange(e, [
                          this.global.online.closure_timeframe[0],
                          v
                        ])
                      }
                    ></formTypes.Date>
                  </div>
                </div>
              </div>
            )}
        </form>
      </div>
    );
  };

  render() {
    console.log("this.global", this.global);

    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Program Schedule"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionThree;
