import React, { useState, useEffect, useGlobal } from "reactn";
import axios from "axios";
import { toast } from "react-toastify";
import ConnectMarketingIntegrationModal from "../../../components/modals/ConnectMarketingIntegrationModal";
import "./integrations.css";
import googleAnalyticsIcon from "./img/googleAnalytics.png";
import metaIcon from "./img/meta.jpg";
import tiktokIcon from "./img/tiktok.png";
import googleTagIcon from "./img/googleTag.png";

const integrations = [
  {
    name: "Google Analytics",
    description:
      "Discover where visitors come from and what they do on your site.",
    buttonText: "Connect",
    modalDescription: "For instructions on how to find your Google Analytics ID, please click:",
    modalInputLabel: "Enter your Google Analytics ID",
    vidUrl: "https://www.youtube.com/watch?v=C8QN-Ie49e0",
    docUrl: "https://support.google.com/analytics/answer/12270356?hl=en",
    modalDefaultInput: "G-XXXXXXXXXX",
    icon: googleAnalyticsIcon,
    idKey: "googleAnalyticsId",
    viewUrl: "https://analytics.google.com"
  },
  {
    name: "Meta Pixel",
    description:
      "Track conversions and measure the success of Facebook campaigns.",
    buttonText: "Connect",
    modalDescription: "For instructions on how to find your Meta Pixel ID, please click:",
    modalInputLabel: "Enter your Meta Pixel ID",
    vidUrl: "https://www.youtube.com/watch?v=_svxyfFriYQ",
    docUrl: "https://www.facebook.com/business/help/952192354843755",
    modalDefaultInput: "XXXXXXXXXX",
    icon: metaIcon,
    idKey: "metaPixelId",
    viewUrl: "https://business.facebook.com/events_manager2"
  },
  {
    name: "Google Tag Manager",
    description:
      "Use Google Tag Manager to add and update tags and code on your website. ",
    buttonText: "Connect",
    modalDescription: "For instructions on how to find your Google Tag Manager ID, please click:",
    modalInputLabel: "Enter your Google Tag Manager ID",
    vidUrl: "https://www.youtube.com/watch?v=F8_sbW63sH0",
    docUrl: "https://www.analyticsmania.com/post/google-tag-manager-id/",
    modalDefaultInput: "GTM-XXXXXXX",
    icon: googleTagIcon,
    idKey: "googleTagManagerId",
    viewUrl: "https://tagmanager.google.com"
  },
  {
    name: "Tiktok Pixel",
    description:
      "Track and measure the effectiveness of your TikTok ad campaigns.",
    buttonText: "Connect",
    modalDescription: "For instructions on how to find your Tiktok Pixel ID, please click:",
    modalInputLabel: "Enter your Tiktok Pixel ID",
    vidUrl: "https://www.youtube.com/watch?v=R_3CO4Wwzgw",
    docUrl: "https://ads.tiktok.com/help/article/get-started-pixel?lang=en",
    modalDefaultInput: "XXXXXXXXXX",
    icon: tiktokIcon,
    idKey: "tiktokPixelId",
    viewUrl: "https://ads.tiktok.com"
  },
  {
    name: "Facebook Catalog",
    description:
      "Sync your products to Meta Commerce Manager to advertise a catalog of products together",
    buttonText: "Connect",
    modalDescription: "For instructions on how to upload your Data Stream, please click:",
    modalInputLabel: "Copy your Data Stream URL",
    vidUrl: "https://www.youtube.com/watch?v=PbJIA2QSb7E",
    docUrl: "https://www.facebook.com/business/help/125074381480892?id=725943027795860",
    modalDefaultInput: "Facebook Catalog",
    icon: metaIcon,
    idKey: "facebookCatalog",
    viewUrl: "https://business.facebook.com/commerce/"
  },
];

async function getMarketingIntegrationsPermissions() {
  const feature_category = "Marketing%20Integrations";
  const ep = new URL(
    `${process.env.REACT_APP_API}/partner/permissions/${feature_category}/`
  );
  console.log(ep);
  const resp = await axios.get(ep);
  return resp.data;
}

function getIntegrationsDataFromPermissions(permissions) {
  if (!permissions) {
    throw new Error(
      "Error at getIntegrationsDataFromPermissions. permissions argument must be given"
    );
  }

  let result = [];
  permissions.map((perm) => {
    const integrations_filter = integrations.filter(
      i => i.name === perm.feature && perm.has_access
    );
    if (integrations_filter.length === 1) {
      result.push({
        ...integrations_filter[0]
      });
    }
  });
  

  return result
}

const Integrations = () => {
  const [partnerPathway, setPartnerPathway] = useGlobal("partnerPathway");
  const [organizationInfo, setOrganizationInfo] = useGlobal("organizationInfo");
  const [connected, setConnected] = useState({});
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [allowedIntegrations, setAllowedIntegrations] = useState([]);

    const initalPermissions = async () => {
      const thePermissions = await getMarketingIntegrationsPermissions();
      if (thePermissions.success) {
        const setData = thePermissions.data;
  
        const integrationcardsdata = getIntegrationsDataFromPermissions(setData);
        setAllowedIntegrations(integrationcardsdata);
      } else {
        toast.error(thePermissions.message);
      }
    };

  const fetchConnectedIntegrations = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API}/partners/search/marketingIntegrations/${organizationInfo.slug}`);
    if (res.data.success) {
      setConnected(res.data.data);
      console.log("Connected", res.data.data);
    } else {
      toast.error(res.data.message);
    }
  };

  useEffect(() => {
    fetchConnectedIntegrations();
    initalPermissions();
  }, []);

  useEffect(() => {
    setPartnerPathway([
      ...partnerPathway.slice(0, 1),
      { label: "Marketing Integrations", to: "/integrations" }
    ]);
  }, []);

  const openConnectModal = (index) => {
    setCurrentIndex(index);
    setShowConnectModal(true);
  };

  const connectIntegration = async (integration, value) => {
    console.log(`Connecting to ${integration}`);
    let ep;
    switch (integration) {
      case "Google Analytics":
        ep = `${process.env.REACT_APP_API}/partners/organization_profile/googleAnalytics`;
        break;
      case "Meta Pixel":
        ep = `${process.env.REACT_APP_API}/partners/organization_profile/metaPixel`;
        break;
      case "Google Tag Manager":
        ep = `${process.env.REACT_APP_API}/partners/organization_profile/googleTagManager`;
        break;
      case "Tiktok Pixel":
        ep = `${process.env.REACT_APP_API}/partners/organization_profile/tiktokPixel`;
        break;
      default:
        return;
    }
    const res = await axios.post(ep, { value });
    if (res.data.success) {
      toast.success(`Connected to ${integration}`);
      fetchConnectedIntegrations(); // Refresh the connected state
    } else {
      toast.error(res.data.message);
    }
  };

  const viewIntegration = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="admin">
      {showConnectModal && (
        <ConnectMarketingIntegrationModal
          onSubmit={(value) => connectIntegration(integrations[currentIndex].name, value)}
          onClose={() => setShowConnectModal(false)}
          header={"Connect " + integrations[currentIndex].name}
          description={integrations[currentIndex].modalDescription}
          docUrl={integrations[currentIndex].docUrl}
          vidUrl={integrations[currentIndex].vidUrl}
          inputLabel={integrations[currentIndex].modalInputLabel}
          defaultInput={integrations[currentIndex].modalDefaultInput}
          orgProfile={organizationInfo}
        />
      )}
      <div className="container-fluid adminprofiles">
        <div className="row cont">
          <div className="col-md-6">
            <h1>Marketing Integrations</h1>
            <p>Use these tools to make your marketing more effective.</p>
          </div>
        </div>
        <div className="cont">
          <div className="integrations-grid-cards">
            {allowedIntegrations.map((integration, index) => (
              <div key={index} className="integration-card">
                <div className="integration-icon">
                  <img src={integration.icon} alt={integration.name} />
                </div>
                <h3>{integration.name}</h3>
                <p>{integration.description}</p>
                {!connected[integration.idKey] ? (
                  <button
                    className="connect-button"
                    onClick={() => openConnectModal(index)}
                  >
                    {integration.buttonText}
                  </button>
                ) : (
                  <div className="connected-container">
                    <button className="view-button" onClick={() => viewIntegration(integration.viewUrl)}>View</button>
                    <div className="connected-status">
                      <span className="checkmark">&#10003;</span>
                      <span className="connected-text">Connected</span>
                    </div>
                    <div className="disconnect-icon" title="Disconnect" onClick={() => connectIntegration(integration.name, null)}>
                      &#128465;
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
